<template>
  <div class="orders">
    <el-form
      v-if="isPrimaryAcc"
      label-position="top"
      class="orders-from"
      :inline="true"
      :v-loading="loading"
    >
      <el-form-item
        label="Publisher"
      >
        <el-autocomplete
          v-model="publisher"
          class="inline-input"
          placeholder="Change publisher"
          :fetch-suggestions="querySearchPublisher"
          @select="(item) => handleSelect(item, 'publisher')"
        />
      </el-form-item>
      <el-form-item
        label="Operation Type"
      >
        <el-autocomplete
          v-model="query.status"
          class="inline-input"
          placeholder="Change status"
          :fetch-suggestions="querySearchStatus"
          @select="(item) => handleSelect(item)"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="submitForm"
        >
          Search
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="resetForm"
        >
          Reset
        </el-button>
      </el-form-item>
    </el-form>
    <el-button
      v-if="hasAccess"
      type="primary"
      @click="openModal=true"
    >
      Create
    </el-button>
    <Table
      :table-data="ordersData"
      :column="tableTitle"
      :cellstyle="cellStyle"
      :loading="loading"
      @cellStatus="cellStatus"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          v-if="scope.row.status !== 'done'"
          :ref="'dropdown' + scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="isAdmin && (scope.row.status === 'new' || scope.row.status === 'rejected')"
              @click.native="changeToRejected('accepted', scope.row.id)"
            >
              Accept
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isAdmin && scope.row.status === 'accepted'"
              @click.native="changeToRejected('done', scope.row.id)"
            >
              Completed
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isAdmin && (scope.row.status === 'accepted' || scope.row.status === 'new')"
              @click.native="changeToRejected('rejected', scope.row.id)"
            >
              Reject
            </el-dropdown-item>
            <el-dropdown-item
              v-if="hasAccess"
              icon="el-icon-delete"
              :disabled="scope.row.status !== 'new'"
              :divided="scope.row.status !== 'done'"
              @click.native="handleDelete(scope.row.id)"
            >
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <el-dialog
      title="Creating order"
      :visible="openModal"
      :close-on-click-modal="false"
      @close="openModal=false"
    >
      <el-form
        ref="orderForm"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          prop="amount"
          label="Amount"
        >
          <el-input
            v-model.number="form.amount"
            type="number"
          />
        </el-form-item>
        <el-form-item
          class="create-button-item"
        >
          <el-button
            type="primary"
            @click="createNewOrder"
          >
            Create
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible="openDescriptionModal"
      title="Rejection reason"
      @close="openDescriptionModal=false"
    >
      <el-input
        v-model="textarea"
        type="textarea"
        :rows="2"
        placeholder="Please input"
      />
      <span slot="footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="orderRejected"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GETTER_BILLING_ORDERS_DATA,
  GETTER_BILLING_ORDERS_COLUMN,
  ACTION_BILLING_ORDERS_GET,
  ACTION_BILLING_ORDER_CREATE,
  GETTER_AUTH_USER_ROLE,
  GETTER_AUTH_CURRENT_USER,
  ACTION_BILLING_ORDER_DELETE,
  ACTION_BILLING_ORDER_ACCEPT,
  ACTION_BILLING_ORDER_REJECT,
  ACTION_BILLING_ORDER_DONE,
  ACTION_PUBLISHERS_GET,
  GETTER_PUBLISHERS_DATA,
} from '@/const';
import Table from '../../components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  components: {
    Table,
  },

  data () {
    return {
      openModal: false,
      column: null,
      page: null,
      openDescriptionModal: false,
      form: {
        amount: null,
      },
      rules: {
        amount: [ { required: true, message: 'Fill in the field' } ],
      },
      publisher: '',
      query: {
        publisherId: null,
        status: null,
      },
      statuses: [
        { value: 'new' },
        { value: 'accepted' },
        { value: 'rejected' },
        { value: 'done' },
      ],
      textarea: '',
      rejectId: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      ordersData: GETTER_BILLING_ORDERS_DATA,
      ordersColumn: GETTER_BILLING_ORDERS_COLUMN,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
      getPublishersData: GETTER_PUBLISHERS_DATA,
    }),

    tableTitle () {
      if (this.userRole === 'publisher') {
        return this.ordersColumn.filter(col => col.label !== 'Publisher');
      }
      else return this.ordersColumn;
    },

    publishers () {
      return this.getPublishersData.rows.map(item => {
        return {
          id: item.id,
          value: item.name,
        };
      });
    },

    isAdmin () {
      return ['administrator', 'manager'].includes(this.userRole);
    },

    hasAccess () {
      return this.userRole === 'publisher';
    },

    isPrimaryAcc () {
      return this.userRole !== 'publisher';
    },
    
    cellStyle () {
      const all = 'cursor: pointer;';
      return {
        columnProperty: 'status',
        styles: {
          new: all + 'color: grey',
          accepted: all + 'color: rgb(191, 206, 108)',
          rejected: all + 'color: red',
          done: all + 'color: green',
        },
      };
    },
  },

  async created () {
    this.loading = true;
    const res = await this.getOrders({
      query: { page: 1 },
    });
    this.page = res.page || 1;

    await this.fetchAllPublishers({
      query: { limit: 0 },
    });
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getOrders: ACTION_BILLING_ORDERS_GET,
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
      createOrder: ACTION_BILLING_ORDER_CREATE,
      delete: ACTION_BILLING_ORDER_DELETE,
      ordersAccept: ACTION_BILLING_ORDER_ACCEPT,
      ordersReject: ACTION_BILLING_ORDER_REJECT,
      ordersDone: ACTION_BILLING_ORDER_DONE,
    }),

    querySearchPublisher (queryString, cb) {
      const publishers = this.publishers;
      const results = queryString ? publishers.filter(this.createFilter(queryString)) : publishers;
      cb(results);
    },
    
    querySearchStatus (queryString, cb) {
      const status = this.statuses;
      const results = queryString ? status.filter(this.createFilter(queryString)) : status;
      cb(results);
    },

    createFilter (queryString) {
      return (item) => {
        return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    async submitForm () {

      let query = {
        ...this.query,
      };

      if (query.publisherId && !query.status) {
        delete query.status;
      }

      if (!query.publisherId && query.status) {
        delete query.publisherId;
      }

      await this.getOrders({
        query,
      });

      this.query.publisherId = this.query.status = this.publisher = null;
    },

    resetForm () {
      this.query.publisherId = this.query.status = this.publisher = null;
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,
    
    cellClick (row) {
      if (row.status !== 'done') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },
    
    async createNewOrder () {
      this.$refs['orderForm'].validate(async (valid) => {
        if (!valid) this.$messageBox.alert('Fill in all required fields');
        else {
          const page = this.page;
          const res = await this.createOrder({
            data: {
              ...this.form,
              publisherId: this.currentUser.publishers[0].id,
            },
          });
          if (res.data.errors.length) {
            const message = res.data.errors.map(err => err.msg).join(' ');
            this.$messageBox.alert(message, 'Error', { confirmButtonText: 'Ok' });
          }

          await this.getOrders({ query: { page } });
          this.openModal = false;
        }
      });
    },

    handleSelect (item, type) {
      if (type === 'publisher') {
        this.query.publisherId = item.id;
      } else {
        this.query.status = item.value;
      }
    },

    async handleDelete (id) {
      const page = this.page;
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        const res = await this.delete({
          params: { id },
        });
        if (res) {
          await this.getOrders({
            query: { page },
          });
        }
       
      });
    },

    cellStatus (row, column) {
      if (column.property === 'status') {
        switch (row.status) {
        case 'new':
          this.changeToRejected('accepted');
          break;
        case 'accepted':
          this.changeToRejected('done');
          break;
        default:
          break;
        }
      }
      if (column.property === 'options') {
        this.cellClick(row);
      }
    },

    async changeToRejected (status, id) {
      switch (status) {
      case 'rejected':
        this.openDescriptionModal = true;
        this.rejectId = id;
        break;
      case 'accepted':
        await this.ordersAccept({
          params: { id },
        });
        await this.getOrders({
          query: { page: this.page },
        });
        break;
      case 'done':
        await this.ordersDone({
          params: { id },
        });
        await this.getOrders({
          query: { page: this.page },
        });
        break;
      default:
        break;
      }
    },

    async orderRejected () {
      await this.ordersReject({
        params: { id: this.rejectId },
        data: { description: this.textarea },
      });
      this.rejectId = null;
      this.textarea = '';
      this.openDescriptionModal = false;
      await this.getOrders({
        query: { page: this.page },
      });
    },
  },
};
</script>

<style lang="scss">
.orders {
  width: 100%;
  position: relative;
  padding: 15px;
  padding-top: 50px;
  text-align: right;

  .orders-from {
    position: absolute;
    top: 30px;
    right: 120px;
    text-align: left;

    & .el-form-item__label {
      line-height: 0;
    }

    .btns {
      margin-top: 20px;
    }
  }

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>
