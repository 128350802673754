<template>
  <div class="register-block">
    <h3 class="register-title">
      Sign Up
    </h3>
    <el-form
      ref="form"
      v-loading="loading"
      autocomplete="off"
    >
      <el-form-item>
        <FormInput 
          :label="'Name'"
          :type="'text'"
          :name="'name'"
          :placeholder="'Enter your Name'"
          :is-error="this.$v.form.name.$error"
          :error-msg="validationErrors.name"
          @onBlur="onBlur"
        />
      </el-form-item>
      <el-form-item>
        <FormInput 
          :label="'Email'"
          :type="'email'"
          :name="'email'"
          :placeholder="'Enter your Email'"
          :is-error="this.$v.form.email.$error || isErrorMsg"
          :error-msg="validationErrors.email"
          @onBlur="onBlur"
        />
      </el-form-item>
      <el-form-item>
        <el-form-item>
          <FormInput 
            :label="'Password'"
            :type="'password'"
            :name="'pass'"
            :placeholder="'Enter your Password'"
            :is-error="this.$v.form.password.$error"
            :is-password="true"
            :error-msg="validationErrors.password"
            @onBlur="onBlur"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item class="register-btn-block">
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Create
        </el-button>
        <el-button
          @click="() => $router.push('/login')"
        >
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_AUTH_REGISTER } from '@/const';
import { validationMixin } from 'vuelidate';
import { minLength, required, email } from 'vuelidate/lib/validators';
import FormInput from '@/components/inputs/formInput.vue';

export default {
  components: {
    FormInput,
  },
  mixins: [validationMixin],
  data () {
    return {
      loading: false,
      form: {
        name: '',
        email: '',
        password: '',
      },
      validationErrors: {
        name: 'Name at least 2 characters',
        email: '',
      },
      isErrorMsg: false,
      errorMsg: '',
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    ...mapActions({
      register: ACTION_AUTH_REGISTER,
    }),

    async onSubmit () {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.form.email.$error) {
        this.validationErrors.email = 'Enter valid Email';
      }
      if (!this.$v.$invalid) {
        const error = await this.register({
          data: { name: this.form.name, email: this.form.email, password: this.form.password },
          notify: this.openModal,
        });
        if (error) {
          error.forEach(item => {
            if (item.field === 'email') {
              this.loading = false;
              this.validationErrors.email = item.msg;
              this.isErrorMsg = true;
            }
          });
        }
        this.loading = false;
      }
      this.loading = false;
    },

    onBlur (input) {
      if (input.name === 'name') {
        this.form.name = input.val;
      } else if (input.name === 'email') {
        this.form.email = input.val;
      } else this.form.password = input.val;
    },

    openModal (type) {
      this.$messageBox.alert(
        type === 'success'
          ? 'An email has been sent to your email address with instructions to verify your email address'
          : 'Sign up failed. try again!', '', {
          type,
          confirmButtonText: 'OK',
          callback: () => {
            this.form.name = this.form.email = this.form.password = '';
            this.$v.$reset();
            this.$router.push('/login');
          },
        });
    },
  },
};
</script>

<style lang="scss">
.register-block {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(28, 24, 54, 0.12), 0 0 6px rgba(26, 34, 59, 0.04);

  .register-title {
    text-align: center;
    letter-spacing: 2px;
    color: #606266;
    text-transform: uppercase;
  }
  
  .register-btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .el-message-box__headerbtn {
    display: none;
  }
}
</style>