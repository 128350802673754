import {
  ACTION_ROOT_REQUEST,
  MUTATION_DSPCREATIVE_SET,
  URL_DSPCREATIVE_ACTIVE,
  URL_DSPCREATIVE_BLOCK,
  URL_DSPCREATIVE_DELETE,
  URL_DSPCREATIVE_GET,
  URL_DSPCREATIVE_MODERATE,
  URL_DSPCREATIVE_UNMODERATE,
  URL_DSPCREATIVE_UPDATE,
} from '../../const';

const state = {
  dspCreative: {},
  filteredDspCreatives: {},
};

const getters = {
  getDspCreative (state) {
    return state.dspCreative;
  },

  getFilteredDspCreativeTable (state) {
    return state.filteredDspCreatives;
  },

  DspTable () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 70,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 35,
      },
      {
        prop: 'externalId',
        label: 'External ID',
        width: 100,
      },
      {
        prop: 'dsp.name',
        label: 'DSP Name',
        width: 100,
      },
      {
        buttons: true,
        prop: 'files',
        label: 'Files',
        width: 100,
      },
      {
        prop: 'adomain',
        label: 'Domain',
        width: 150,
      },
      {
        prop: 'duration',
        label: 'Duration',
        width: 100,
      },
      {
        prop: 'type',
        label: 'Type',
        width: 100,
      },
      {
        buttons: true,
        prop: 'moderated',
        label: 'Moderated',
        width: 100,
      },
      {
        prop: 'created',
        label: 'Created at',
        width: 100,
      },
    ];
    return columns;
  },
};

const mutations = {
  setDspCreative (state, response) {
    state.dspCreative = response;
  },

  getFilteredDspCreatives (state, { dspId, status, searchText }) {
    let result;
    const initialDspCreatives = state.dspCreative;
    const rows = initialDspCreatives.rows.filter(dspCreative => {
      const value = dspCreative.code + dspCreative.externalId + dspCreative.landingUrl.toString();
      const stat = !status ? true : dspCreative.status === status;
      const id = !dspId ? true : dspCreative.dsp.id === dspId;
      const queryString = !searchText ? true : value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      return !!(stat && id && queryString);
    });
    result = {
      ...initialDspCreatives,
      rows,
      count: rows.length,
      currentPage: 1,
    };
    state.filteredDspCreatives = result;
  },
};

const actions = {
  fetchDspCreative: async ({ dispatch }, { params, query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_DSPCREATIVE_GET,
      commitName: MUTATION_DSPCREATIVE_SET,
    }, { root: true });
    return response;
  },

  updateDspCreative: async ({ dispatch }, { params, data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_DSPCREATIVE_UPDATE,
    }, { root: true });
    return res;
  },

  blockDspCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_DSPCREATIVE_BLOCK,
    }, { root: true });
    return response;
  },

  activeDspCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_DSPCREATIVE_ACTIVE,
    }, { root: true });
    return response;
  },

  moderateDspCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_DSPCREATIVE_MODERATE,
    }, { root: true });
    return response;
  },

  unModerateDspCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_DSPCREATIVE_UNMODERATE,
    }, { root: true });
    return response;
  },

  deleteDspCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_DSPCREATIVE_DELETE,
    }, { root: true });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
