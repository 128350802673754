<template>
  <div class="agencies">
    <el-button
      class="agencies-add-btn"
      type="primary"
      @click="openDialog"
    >
      Adding user
    </el-button>
    <Table
      :table-data="usersData"
      :column="getAgenciesUsersColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id"
          trigger="click"
          :disabled="ownerId === scope.row.id"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deleteAgency(scope.row.id)"
            > 
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <AgenciesUsersModal
      v-if="dialogVisible"
      :agency-id="id"
      :dialog-visible="dialogVisible"
      @close="close"
    />
  </div>
</template>

<script>

import 
{ 
  ACTION_AGENCIES_USERS_GET, 
  GETTER_AGENCIES_USERS_DATA, 
  GETTER_AGENCIES_USERS_TABLE,
  ACTION_AGENCIES_USERS_DELETE,
  ACTION_AGENCIES_GET_AGENCY,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import AgenciesUsersModal from './AgenciesUsersModal.vue';
import Table from '@/components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  name: 'AgencyUsers',
  components: {
    AgenciesUsersModal,
    Table,
  },
  data () {
    return {
      dialogVisible: false,
      id: null,
      loading: false,
      ownerId: null,
    };
  },

  computed: {
    ...mapGetters({
      getAgenciesUser: GETTER_AGENCIES_USERS_DATA,
      getAgenciesUsersColumns: GETTER_AGENCIES_USERS_TABLE,
    }),

    usersData () {
      let data = { ...this.getAgenciesUser };
      data.rows = data.rows ? data.rows.filter(item => item.id !== this.ownerId) : [];
      return data;
    },
  },

  async created () {
    const { data } = await this.fetchAgency({
      params: { id: parseInt(this.$route.params.id) },
    });
    if (data) {
      this.ownerId = data.owner.id;
    }
    await this.getNewPage();
  },

  methods: {
    ...mapActions({
      fetchAllUsers: ACTION_AGENCIES_USERS_GET,
      removeAgenciesUsers: ACTION_AGENCIES_USERS_DELETE,
      fetchAgency: ACTION_AGENCIES_GET_AGENCY,
    }),

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    openDialog () {
      this.dialogVisible = true;
      this.id = parseInt(this.$route.params.id);
    },

    async getNewPage (page=1) {
      this.loading = true;
      await this.fetchAllUsers({
        query: { page },
        params: this.$route.params,
      });
      this.loading = false;
    },

    async deleteAgency (userId) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {         
        const res = await this.removeAgenciesUsers({
          params: {
            agency_id: parseInt(this.$route.params.id),
            id: userId,
          },
        });
        if (res.success) {
          await this.fetchAllUsers({
            params: this.$route.params,
          });
        }
      });
    },

    close () {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>

</style>