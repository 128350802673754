<template>
  <el-container>
    <el-dialog
      :title="`DSP creative ${onlyVideo ? 'files' : ''} ${isEdit ? 'updating' : 'view'}`"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="80%"
      @close="$emit('close')"
      @blur="$emit('close')"
    >
      <el-form
        v-if="!onlyVideo"
        v-loading="loading"
        style="position: inherit!important;"
        class="demo-modalForm"
        label-position="top"
      >
        <el-form-item
          v-if="!isEdit"
          label="Status"
        >
          <el-input
            disabled
            :value="data.status"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="ID"
        >
          <el-input
            disabled
            :value="data.id"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="External ID"
        >
          <el-input
            disabled
            :value="data.externalId"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="Name"
        >
          <el-input
            disabled
            :value="data.dsp.name"
          />
        </el-form-item>
        <el-form-item
          prop="categoryId"
        >
          <el-tree
            ref="tree"
            :disabled="!isEdit"
            :props="treeProps"
            :data="catData"
            show-checkbox
            node-key="id"
            :expand-on-click-node="true"
            highlight-current
            @check="handleNodeClick"
          />
        </el-form-item>
        <el-form-item
          label="Domain"
        >
          <el-input
            v-model="form.adomain"
            :disabled="!isEdit"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="Type"
        >
          <el-input
            disabled
            :value="data.type"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="Duration"
        >
          <el-input
            disabled
            :value="data.duration"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="Moderating"
        >
          <el-input
            disabled
            :value="data.moderated ? 'Moderated' : 'Not moderated'"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="Created"
        >
          <el-input
            disabled
            :value="data.created"
          />
        </el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="Code"
        >
          <el-input
            disabled
            type="textarea"
            autosize
            :value="data.code"
          />
        </el-form-item>
        <el-form-item
          class="update-button-item"
        >
          <el-button
            v-if="isEdit"
            type="primary"
            @click="updateDspCreatives"
          >
            Save
          </el-button>
        </el-form-item>
      </el-form>
      <h3 v-if="!onlyVideo && !isEdit">
        DSP creative files
      </h3>
      <el-form
        v-if="!isEdit"
        style="position: inherit!important;"
        class="demo-modalForm"
        label-position="top"
      >
        <el-form-item
          v-for="file in data.files"
          :key="file.id"
          :class="!onlyVideo ? 'file-item' : ''"
          style="justify-content: center"
        >
          <div
            :class="onlyVideo ? 'onlyVideo' : ''"
          >
            <el-form-item
              label="ID"
            >
              <el-input
                disabled
                :value="file.id"
              />
            </el-form-item>
            <div class="file">
              <label>{{ onlyVideo ? `ID: ${file.id}` : 'File' }}</label>
              <el-button
                style="cursor: pointer; width: 100%; overflow: hidden;"
                type="info"
                plain
                @click="showVideo(file.id)"
              >
                {{ file.fileUrl }}
              </el-button>
              <video
                v-if="activeVideoId === file.id"
                class="video-player"
                width="90%"
                controls
                autoplay
              >
                <source
                  :src="file.fileUrl"
                  type="video/mp4"
                >
              </video>
            </div>
            <el-form-item
              label="Duration"
            >
              <el-input
                disabled
                :value="file.duration"
              />
            </el-form-item>
            <el-form-item
              label="Bitrate"
            >
              <el-input
                disabled
                :value="file.bitrate"
              />
            </el-form-item>
            <el-form-item
              label="Width"
            >
              <el-input
                disabled
                :value="file.width"
              />
            </el-form-item>
            <el-form-item
              label="Height"
            >
              <el-input
                disabled
                :value="file.height"
              />
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GETTER_APP_TABLE_CATEGORIES,
  ACTION_APPS_GET_CATEGORIES,
  ACTION_DSPCREATIVE_UPDATE,
} from '@/const';

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    onlyVideo: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      activeVideoId: null,
      form: {
        categoryId: null,
        adomain: null,
      },
      treeProps: {
        label: 'name',
        children: 'children',
      },
      selectedCategory: 'Categories',
    };
  },
  computed: {
    ...mapGetters({
      categories: GETTER_APP_TABLE_CATEGORIES,
    }),
    catData () {
      const catData = [{
        name: this.selectedCategory,
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
      }
      for (const [key, value] of Object.entries(this.categories)) {
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },
  },

  mounted () {
    if (this.isEdit) {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
        if (this.data.category) {
          this.selectedCategory = this.data.category.name;
          this.$refs.tree.setCheckedKeys([this.data.category.id]);
          this.form.categoryId = this.data.category.id;
        }
        this.form.adomain = this.data.adomain;
      });
    }
  },

  methods: {
    ...mapActions({
      getCategories: ACTION_APPS_GET_CATEGORIES,
      updateDspCreative: ACTION_DSPCREATIVE_UPDATE,
    }),

    openMessage (error) {
      this.$messageBox.alert(
        `${error.msg}`, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    async updateDspCreatives () {
      this.loading = true;
      const data = { ...this.form };

      const res = await this.updateDspCreative({
        params: { id: this.data.id },
        data,
      });

      if (res && res.data && res.data.errors) {
        this.openMessage(res.data.errors[0]);
      } else {
        this.$emit('close');
        this.$emit('getNewPage');
      }
      this.loading = false;
    },

    handleNodeClick (data) {
      if (data.id) {
        this.$refs.tree.setCheckedKeys([]);
        this.selectedCategory = data.name;
        this.$refs.tree.setCheckedKeys([data.id]);
        this.form.categoryId = data.id;
      }
    },

    showVideo (id) {
      if (this.activeVideoId === id) {
        this.activeVideoId = null;
      } else {
        this.activeVideoId = id;
      }
    },
  },
};

</script>

<style>
.video-player {
  justify-content: center;
  display: flex;
  margin-inline: auto;
  margin-top: 10px;
}

.onlyVideo .el-form-item {
  display: none;
}

.onlyVideo.file {
  display: grid;
}

.file-item {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
</style>
