export const names = {
  campaigns: 'Campaigns',
  creative: 'Creatives',
  strategy: 'Strategies',
  report: 'Report',
  reports: 'Reports',
  apps: 'Apps',
  // transactions: 'Транзакция',
  // orders: 'Заявки',
  users: 'Users',
  agencies: 'Agencies',
  advertisers: 'Advertisers',
  publishers: 'Publishers',
  dsp: 'DSP',
  dspcreative: 'DSP Creatives',
  ssp: 'SSP',
};
