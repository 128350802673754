<template>
  <el-dialog
    :title="userId ? 'Updating user' : 'Creating user'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="loading"
      style="position: inherit!important;"
      :model="form"
      :rules="rules"
    >
      <el-form-item
        label="User mail"
        prop="email"
      >
        <el-input
          v-model.trim="form.email"
        />
      </el-form-item>
      <el-form-item
        label="Password"
        prop="password"
      >
        <el-input
          v-model="form.password"
        />
      </el-form-item>
      <el-form-item
        label="Username"
        prop="name"
      >
        <el-input 
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item label="User role">
        <el-select
          v-model="form.role"
          placeholder="Select"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        class="create-button-item"
      >
        <el-button
          type="primary"
          @click="createUpdateUser"
        >
          {{ buttonTitle }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_USERS_GET_USER, ACTION_USERS_CREATE, ACTION_USERS_UPDATE, ACTION_USERS_GET_USERS } from '@/const';
export default {
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: null,
    },
    currentPage: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      form: {
        email: '',
        password: null,
        name: '',
        role: '',
      },
      loading: false,
      options: [
        { label: 'Admin', value: 'administrator' },
        { label: 'Manager', value: 'manager' },
        { label: 'Client', value: 'client' },
      ],
      rules: {
        name: [
          { required: true, message: 'Fill in the field' },
        ],
        email: [
          { required: true, message: 'Fill in the field' },
        ],
        password: [
          { required: true, message: 'Fill in the field' },

        ],
      },
    };
  },

  computed: {
    buttonTitle () {
      return this.userId ? 'Update' : 'Create';
    },
  },

  async created () {
    this.loading = true;
    if (this.userId) {
      const res = await this.getUser({
        params: { id: this.userId },
      });

      const formData = res.data;

      delete formData.advertisers,
      delete formData.agencies,
      delete formData.publishers,
      delete formData.updated,
      delete formData.created,

      this.form = formData;
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getUser: ACTION_USERS_GET_USER,
      createUser: ACTION_USERS_CREATE,
      updateUser: ACTION_USERS_UPDATE,
      getAllUsers: ACTION_USERS_GET_USERS,
    }),

    handleOpen (error) {
      this.$messageBox.alert(
        `${error.msg}`, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    alertMessage (res) {
      const status = res.status;
      const email = res.data.email;
      const updated = res.data.updated;

      if (status === 200 && !updated) {
        this.$messageBox.alert(`User with mail ${email} added`, 'Successfully', {
          confirmButtonText: 'OK',
          callback: async () => {
            this.$emit('newPage', this.currentPage);
          },
        });
      } else if (status === 200 && updated) {
        this.$messageBox.alert('Data updated successfully', 'Success', {
          confirmButtonText: 'OK',
          callback: async () => {
            this.$emit('newPage', this.currentPage);
          },
        });
      }
    },

    async createUpdateUser () {
      this.loading = true;
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          if (!this.userId) {
            const res = await this.createUser({
              data: this.form,
            });
            if (res.data.errors) {
              this.handleOpen(res.data.errors[0]);
            }
            if (res.status === 200) {
              this.$emit('close');
            }
            this.alertMessage(res);
          } else {
            const res = await this.updateUser({
              data: this.form,
              params: { id: this.userId },
            });
            if (res.data.errors) {
              this.handleOpen(res.data.errors[0]);
            }
            if (res.status === 200) {
              this.$emit('close');
            }
            this.alertMessage(res);
          }
          this.$emit('newPage', this.currentPage);
        } else {
          this.$messageBox.alert( 
            'Fill in the field'
          );
        }
      });
      this.loading = false;
    },

  },
};
</script>

<style lang="scss">
.el-form {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 450px;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
    }

    &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
    }

    & .el-autocomplete, .el-input, .el-date-picker, .el-select {
    max-width: 440px !important;
    width: 100% !important;
    }
  }

  .create-button-item {
    text-align: end;

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
.el-dialog{
  max-width: 500px;
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>