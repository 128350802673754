<template>
  <el-container>
    <div class="login-block">
      <h3 class="login-title">
        Sign In
      </h3>
      <el-form
        autocomplete="off"
        @submit.prevent
      >
        <el-form-item>
          <formInput
            :type="'Email'"
            :label="'Email'"
            :placeholder="'Enter your Email'"
            :is-error="isErrorMsgE"
            :error-msg="errorMsgEmail"
            @onBlur="onBlur"
          />
        </el-form-item>
        <el-form-item>
          <formInput
            :type="'Password'"
            :label="'Password'"
            :placeholder="'Enter your Password'"
            :is-password="true"
            :is-error="isErrorMsgP"
            :error-msg="errorMsgPass"
            @onBlur="onBlur"
          />
        </el-form-item>
        <el-form-item class="btn-block">
          <el-button 
            type="primary"
            @click="submitForm"
          >
            Sign In
          </el-button>
          <el-button 
            @click="resetForm"
          >
            Cancel
          </el-button>
        </el-form-item>
      </el-form>
      <div class="btn-block">
        <el-button 
          type="text"
          @click="openDialog"
        >
          Restore password
        </el-button>
      </div>
    </div>
    <el-dialog
      title="Password Recovery"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px"
    >
      <div
        v-loading="load"
        element-loading-background="transparent"
      >
        <el-form
          :model="modal"
          status-icon
          @submit.stop.prevent="sendModalEmail"
        >
          <el-form-item>
            <formInput
              :type="'restoreEmail'"
              :label="'Email'"
              :placeholder="'Enter your Email'"
              :is-error="isErrorMsgE"
              :error-msg="errorMsgEmail"
              @onBlur="onBlur"
            />
          </el-form-item>
          <el-form-item class="btn-center">
            <el-button
              type="primary"
              @click="sendModalEmail"
            >
              Send
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_AUTH_LOGIN, ACTION_AUTH_EMAIL } from '@/const';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import formInput from '@/components/inputs/formInput.vue';

export default {
  components: {
    formInput,
  },
  mixins: [validationMixin],
  data () {
    return {
      form: {
        email: '',
        password: '',
      },
      load: false,
      modal: {
        email: '',
      },
      // TODO
      isErrorMsgE: false,
      isErrorMsgP: false,
      errorMsgEmail: '',
      errorMsgPass: '',
      errorModalText: '',
      dialogVisible: false,
    };
  },
  validations: {
    modal: {
      email: {
        required,
        email,
      },
    },
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      loginData: ACTION_AUTH_LOGIN,
      sendEmail: ACTION_AUTH_EMAIL,
    }),

    onBlur (inp) {
      if (inp.type === 'Email' && inp.val !== '') {
        this.form.email = inp.val;
      } else if (inp.type === 'Password' && inp.val !== '') {
        this.form.password = inp.val;
      } else if (inp.type === 'restoreEmail') {
        this.modal.email = inp.val;
      }
    },

    resetError (booly) {
      this.isErrorMsgE = booly;
    },

    async submitForm () {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        const response = await this.loginData({ 
          data: { email: this.form.email, password: this.form.password },
        });
        // TODO исправить костыль
        if (response.status === 423) {
          response.data.errors.forEach(text => {
            this.errorMsgEmail = text;
          });
          this.isErrorMsgE = true;
        }
        if (response.status === 200) {
          this.$router.push({ name: 'Home' });
        } else if (response.status === 404) {
          response.data.errors.forEach(errorText => {
            if ('Incorrect password' === errorText) {
              this.errorMsgPass = errorText;
              this.isErrorMsgP = true;
            } else if (`User ${this.form.email} not found` === errorText) {
              this.errorMsgEmail = errorText;
              this.isErrorMsgE = true;
            }
          });
        }
      }
    },
    registerLink () {
      this.$router.push('/register');
    },
    resetForm () {
      this.$v.$reset();
      this.form.email = this.form.password = '';
    },
    openDialog () {
      this.$v.$reset();
      this.dialogVisible = true;
    },
    
    async sendModalEmail () {
      this.load = true;
      const response = await this.sendEmail({
        query: { email: this.modal.email },
      });
      if (response.data.errors) {
        this.load = false;
        this.dialogVisible = false;
        this.$v.$reset();
        response.data.errors.forEach(item => {
          this.errorModalText = item.msg;
        });
      }
      this.$messageBox.alert( 
        !response.data.errors
          ? 'Password recovery instructions have been sent to your email'
          : this.errorModalText, '', {
          type: !response.data.errors ? 'success' : 'error',
          confirmButtonText: 'OK',
          callback: () => {
            this.modal.email = '';
            this.$v.$reset();
            this.load = false;
            this.dialogVisible = false;
          },
        });
    },
  },
};


</script>

<style lang="scss">
.login-block {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(28, 24, 54, 0.12), 0 0 6px rgba(26, 34, 59, 0.04);

  .el-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & .login-title {
    text-align: center;
    letter-spacing: 2px;
    color: #606266;
    text-transform: uppercase;
  }

  & .btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .form-subtitle {
    color: #409EFF;
  }

  & .btn-block {
    display: flex;
    justify-content: space-between;
  }
}
.loader {
  width: 100%;
}
.btn-center {
  display: flex;
  justify-content: flex-end;
}
</style>