import {
  URL_SSP_ACTIVE,
  URL_SSP_BLOCK,
  URL_SSP_DELETE,
  URL_SSP_GET,
  URL_SSP_UPDATE,
  URL_SSPS_GET,
  URL_SSP_HANDLERS_GET,
  URL_SSP_REPORTS_GET,
  URL_SSP_CREATE,
  MUTATION_SSP_ONE_SET,
  MUTATION_SSP_HANDLERS_SET,
  MUTATION_SSP_REPORTS_SET,
  MUTATION_SSP_SET,
  ACTION_ROOT_REQUEST, MUTATION_SSP_REPORTS_LIST_SET, URL_SSP_REPORTS_LIST_GET,
} from '@/const';

const state = {
  ssp: null,
  oneSsp: null,
  handlers: [],
  reports: {},
  reportsList: {},
};

const getters = {
  getSsps (state) {
    return state.ssp;
  },

  getSspHandlers (state) {
    return state.handlers;
  },

  getSspReports (state) {
    return state.reports;
  },

  getSspReportsList (state) {
    return state.reportsList;
  },

  getOneSsp (state) {
    return state.oneSsp;
  },

  getSspTable () {
    let columns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 40,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 20,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 40,
      },
      {
        prop: 'endpoint',
        label: 'Endpoint',
        width: 100,
      },
      {
        buttons: true,
        prop: 'token',
        label: 'Token',
        width: 30,
      },
      {
        prop: 'limitPrice',
        label: 'Limit price',
        width: 60,
      },
      {
        prop: 'admPlace',
        label: 'Adm place',
        width: 60,
      },
      {
        prop: 'handler',
        label: 'Request handler',
        width: 70,
      },
      {
        prop: 'reqsToday',
        label: 'Request',
        width: 50,
      },
      {
        prop: 'bidsToday',
        label: 'Bids',
        width: 50,
      },
      {
        prop: 'impsToday',
        label: 'Imps',
        width: 50,
      },
      {
        prop: 'clicksToday',
        label: 'Clicks',
        width: 50,
      },
    ];
    return columns;
  },
};

const mutations = {
  setSsps (state, payload) {
    state.ssp = payload;
  },

  setSspHandlers (state, payload) {
    state.handlers = payload.handlers.map(value => {
      return { value };
    });
  },

  setOneSsp (state, payload) {
    state.oneSsp = payload;
  },

  setReportsList (state, payload) {
    state.reportsList = payload;
  },

  setSspReport (state, response) {
    state.reports = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.reports.rows = tableData;
    state.reports.excelData = excelData;
  },
};

const actions = {
  createSsp: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_SSP_CREATE,
    }, { root: true });
    return res;
  },

  getSspById: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_SSP_UPDATE,
    }, { root: true });
    return response;
  },

  fetchSsps: async ({ dispatch }, { params, query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_SSPS_GET,
      commitName: MUTATION_SSP_SET,
    }, { root: true });
    return response;
  },

  fetchSsp: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_SSP_GET,
      commitName: MUTATION_SSP_ONE_SET,
    }, { root: true });
    return response;
  },

  fetchSspHandlers: async ({ dispatch }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_SSP_HANDLERS_GET,
      commitName: MUTATION_SSP_HANDLERS_SET,
    }, { root: true });
    return response;
  },

  fetchSspReports: async ({ dispatch }, { params, query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_SSP_REPORTS_GET,
      commitName: MUTATION_SSP_REPORTS_SET,
    }, { root: true });
    return response;
  },

  fetchSspReportsList: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_SSP_REPORTS_LIST_GET,
      commitName: MUTATION_SSP_REPORTS_LIST_SET,
    }, { root: true });
    return response;
  },

  blockSsp: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_SSP_BLOCK,
    }, { root: true });
    return response;
  },

  activeSsp: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_SSP_ACTIVE,
    }, { root: true });
    return response;
  },

  updateSsp: async ({ dispatch }, { params, data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_SSP_UPDATE,
    }, { root: true });
    return response;
  },

  deleteSsp: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_SSP_DELETE,
    }, { root: true });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
