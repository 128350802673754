<template>
  <el-container>
    <el-dialog
      :title=" agencyId ? 'Update agency' : 'Create agency'"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form
        v-loading="loading"
        style="position: inherit!important;"
        :model="modalForm"
        name="modalForm" 
        class="demo-modalForm"
      >
        <el-form-item 
          label="Name"
          class="demo-item"
        >
          <el-input 
            v-model="modalForm.name" 
            type="text" 
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item 
          label="Owner"
        >
          <el-autocomplete
            v-model="modalForm.owner"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
          >
            <i
              slot="suffix"
              class="el-icon-edit el-input__icon"
            />
            <template slot-scope="{ item }">
              <div class="value"> 
                {{ item.value }} 
              </div>
              <span class="link">{{ item.link }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          label="Commission"
        >
          <el-input
            v-model.number="modalForm.commission"
            type="number"
          />
        </el-form-item>
        <el-form-item
            label="Budget Type"
            class="demo-item"
        >
          <el-select
              v-model="modalForm.budgetType"
              placeholder="Select"
          >
            <el-option
                v-for="option in ['unlimited', 'limited']"
                :key="option"
                :label="option"
                :value="option">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="Management"
            class="demo-item"
        >
          <el-select
              v-model="modalForm.management"
              placeholder="Select"
          >
            <el-option
                v-for="option in ['view', 'limited', 'full']"
                :key="option"
                :label="option"
                :value="option">
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item
          class="item-flex"
        >
          <el-button 
            v-if="agencyId"
            type="primary"
            @click="submitForm('update')"
          >
            Save
          </el-button>
          <el-button 
            v-else
            type="primary"
            @click="submitForm('create')"
          >
            Create
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { ACTION_USERS_GET_USERS, GETTER_AGENCIES_DATA, ACTION_AGENCIES_UPDATE, ACTION_AGENCIES_CREATE, GETTER_AUTH_CURRENT_USER, ACTION_AGENCIES_GET } from '@/const';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AgenciesModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    agencyId: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      modalForm: {
        name: '',
        commission: null,
        budgetType: 'unlimited',
        management: 'view',
        ownerId: null,
        owner: null,
      },
      users: [],
    };
  },

  computed: {
    ...mapGetters({
      getAgenciesData: GETTER_AGENCIES_DATA,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),
  },

  async created () {
    this.loading = true;
    const res = await this.getAllUsers({ query: { limit: 0 } });
    this.users = res.data.rows.map(user => {
      return {
        id: user.id,
        value: `${user.name} | ${user.email}`,
        email: user.email,
      };
    });

    if (this.agencyId) {
      this.getAgenciesData.rows.forEach(item => {
        if (this.agencyId === item.id) {
          const form = item;

          delete form.created;
          delete form.updated;
          delete form.campaigns;

          this.modalForm = {
            ...form,
            owner: form.emailName,
            email: form.owner.email,
            ownerId: form.owner.id,
          };
        }
      });
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      updateAgency: ACTION_AGENCIES_UPDATE,
      createAgency: ACTION_AGENCIES_CREATE,
      fetchAllAgencies: ACTION_AGENCIES_GET,
      getAllUsers: ACTION_USERS_GET_USERS,
    }),
    
    querySearch (queryString, cb) {
      const users = this.users;
      const results = queryString ? users.filter(this.createFilter(queryString)) : users;
      cb(results);
    },

    createFilter (queryString) {
      return (users) => {
        return (
          users.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
          users.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelect (item) {
      this.modalForm.ownerId = item.id;
    },

    openMessage (error = [{ msg: 'Something went wrong' }]) {
      this.$messageBox.alert(
        `${
          error.map((item) => {
            return ' ' + item.msg + ' '
            ;})
        }
        `, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    async submitForm (action) {
      this.loading = true;
      const page = { page: this.getAgenciesData.currentPage };
      const { name, ownerId, budgetType, commission, management } = this.modalForm;
      switch (action) {
      case 'update':
        if ([name, ownerId, budgetType, management].some(el => !el)) {
          this.$messageBox.alert(
            'Fill in all the fields'
          );
        } else {
          await this.updateAgency({
            params: { id: this.agencyId },
            data: { name, ownerId, budgetType, commission, management },
          }).then(response => {
            if (response.data.errors) {
              this.openMessage(response.data.errors);
            }
            if (response.status === 200) {
              this.$emit('close');
            }
          });
          await this.fetchAllAgencies({
            query: page,
          });
        }
        break;
      case 'create':
        if ([name, ownerId, budgetType, management].some(el => !el)) {
          this.$messageBox.alert(
            'Fill in all the fields'
          );
        } else {
          await this.createAgency({
            data: { name, ownerId, budgetType, commission, management },
          }).then(response => {
            if (response.data.errors) {
              this.openMessage(response.data.errors);
            }
            if (response.status === 200) {
              this.$emit('close');
            }
          });
          await this.fetchAllAgencies({
            query: page,
          });
        }
        break;
      default:
        break;
      }

      this.loading = false;
    },
  },
};
</script>