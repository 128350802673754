<template>
  <div class="dspCreative">
    <el-form
      label-position="top"
      class="dspCreative-from"
      :inline="true"
      :v-loading="loading"
    >
      <el-form-item
        label="DSP"
      >
        <el-autocomplete
          v-model="dspId"
          class="inline-input"
          placeholder="Select DSP"
          :fetch-suggestions="querySearchByDspId"
          @select="(item) => handleSelect(item, 'dspId')"
        />
      </el-form-item>
      <el-form-item
        label="Status"
      >
        <el-autocomplete
          v-model="query.status"
          class="inline-input"
          placeholder="Select status"
          :fetch-suggestions="querySearchStatus"
          @select="(item) => handleSelect(item)"
        />
      </el-form-item>
      <el-form-item
        label="Text"
      >
        <el-input
          v-model="query.searchText"
          placeholder="External Id, Code, URL"
          type="text"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="submitForm"
        >
          Search
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="resetForm(true)"
        >
          Reset
        </el-button>
      </el-form-item>
    </el-form>

    <Table
      :table-data="isFiltered ? filteredDspCreatives : data"
      :column="column"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
      @showModal="showOnlyFilesModal"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id "
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="handlePreview(scope.row)"
            >
              View
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-edit"
              divided
              @click.native="handleEditAdd(scope.row)"
            >
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deleteDspCreativeRow(scope.row)"
            >
              Delete
            </el-dropdown-item>
            <el-dropdown-item
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-view"
              @click.native="handleChangeModerate(scope.row)"
            >
              {{ getModerateType(scope.row) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>

    <dspModal
      v-if="dialogVisible"
      :only-video="onlyVideo"
      :dialog-visible="dialogVisible"
      :is-edit="isEdit"
      :data="modalData"
      @getNewPage="getNewPage"
      @close="close"
    />
  </div>
</template>

<script>
import Table from '../../components/Table.vue';
import dspModal from './dspModal';
import {
  ACTION_APPS_GET_CATEGORIES,
  ACTION_DSPCREATIVE_FETCH,
  ACTION_DSPCREATIVE_BLOCK,
  ACTION_DSPCREATIVE_ACTIVATE,
  ACTION_DSPCREATIVE_MODERATE,
  ACTION_DSPCREATIVE_UNMODERATE,
  ACTION_DSPCREATIVE_DELETE,
  GETTER_DSPCREATIVE_GETDSPCREATIVE,
  GETTER_DSPCREATIVE_DSPTABLE,
  GETTER_AUTH_CURRENT_USER,
  MUTATION_FILTERED_DSPCREATIVE_SET,
  GETTER_DSPCREATIVE_FILTERED_TABLE,
} from '@/const';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  name: 'DspCreative',

  components: {
    Table,
    dspModal,
  },

  data () {
    return {
      loading: false,
      page: null,
      dialogVisible: false,
      modalData: null,
      onlyVideo: false,
      isEdit: false,
      dspId: null,
      query: {
        dspId: null,
        status: null,
        searchText: null,
      },
      isFiltered: false,
    };
  },

  computed: {
    ...mapGetters({
      data: GETTER_DSPCREATIVE_GETDSPCREATIVE,
      filteredDspCreatives: GETTER_DSPCREATIVE_FILTERED_TABLE,
      column: GETTER_DSPCREATIVE_DSPTABLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),

    getStatusType () {
      return (item) => {
        switch (item.status) {
        case 'new':
          return 'Turn on';
        case 'paused':
          return 'Turn on';
        case 'active':
          return 'Block';
        case 'blocked':
          return 'Unlock';
        default:
          break;
        }
      };
    },

    getModerateType () {
      return (item) => item.moderated ? 'Cancel moderation' : 'Moderate';
    },

    getStatusClass () {
      return (item) => {
        switch (item.status) {
        case 'blocked':
          return 'el-icon-unlock';
        case 'new':
          return 'el-icon-unlock';
        case 'paused':
          return 'el-icon-video-play';
        case 'activate':
          return 'el-icon-video-pause';
        default:
          return 'el-icon-lock';
        }
      };
    },

    allDsps () {
      return this.data.rows.map(dspCreative => {
        return {
          value: dspCreative.dsp.name,
          id: dspCreative.dsp.id,
        };
      });
    },

    statuses () {
      return this.data.rows.reduce((result, dspCreative) => {
        return result.some(status => status === dspCreative.status) ? result : [...result , { value: dspCreative.status }];
      }, []);
    },
  },

  async created () {
    await this.getCategories();
    await this.getNewPage();
  },

  methods: {
    ...mapActions({
      getCategories: ACTION_APPS_GET_CATEGORIES,
      fetchDspCreative: ACTION_DSPCREATIVE_FETCH,
      blockDspCreative: ACTION_DSPCREATIVE_BLOCK,
      activeDspCreative: ACTION_DSPCREATIVE_ACTIVATE,
      moderateDspCreative: ACTION_DSPCREATIVE_MODERATE,
      unModerateDspCreative: ACTION_DSPCREATIVE_UNMODERATE,
      deleteDspCreative: ACTION_DSPCREATIVE_DELETE,
    }),

    ...mapMutations({
      getFilteredDspCreatives: MUTATION_FILTERED_DSPCREATIVE_SET,
    }),

    querySearchByDspId (id, cb) {
      const results = id ? this.allDsps.filter(dsp => dsp.id) : this.allDsps;
      cb(results);
    },

    querySearchStatus (queryString, cb) {
      const results = queryString ? this.statuses.filter(this.createFilter(queryString)) : this.statuses;
      cb(results);
    },

    createFilter (queryString) {
      return (item) => {
        return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleSelect (item, type) {
      if (type === 'dspId') {
        this.query.dspId = item.id;
      } else {
        this.query.status = item.value;
      }
    },

    async submitForm () {


      this.isFiltered = true;
      let query = {
        ...this.query,
      };

      await this.getFilteredDspCreatives(query);

      this.resetForm();
    },

    resetForm (resetFilter) {

      this.isFiltered = !resetFilter;

      this.query.dspId = this.query.status = this.dspId = this.query.searchText = null;
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    async dspCreative () {
      await this.fetchDspCreative({
        query: { page: this.page },
      });
    },

    async handleChangeStatus (row) {
      if (['administrator', 'manager'].includes(this.currentUser.role)) {
        switch (row.status) {
        case 'active':
          this.blockDspCreative({
            params: { id: row.id },
          }).then(() => {
            this.dspCreative();
          });
          break;
        default:
          this.activeDspCreative({
            params: { id: row.id },
          }).then(() => {
            this.dspCreative();
          });
          break;
        }
      }
    },

    async handleChangeModerate (row) {
      if (['administrator', 'manager'].includes(this.currentUser.role)) {
        if (row.moderated) {
          this.unModerateDspCreative({
            params: { id: row.id },
          }).then(() => {
            this.dspCreative();
          });
        } else {
          this.moderateDspCreative({
            params: {
              id: row.id },
          }).then(() => {
            this.dspCreative();
          });
        }
      }
    },

    async deleteDspCreativeRow (row) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        const { status } = await this.deleteDspCreative({
          params: {
            id: row.id,
          },
        });
        if (status === 200) {
          await this.fetchDspCreative({
            params: { campaign_id: this.$route.params.id },
            query: { page: this.page },
          });
        }
      });
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    async getNewPage ( page = 1 ) {
      this.loading = true;
      this.page = page;
      await this.fetchDspCreative({
        params: { campaign_id: this.$route.params.id },
        query: { page },
      });
      this.loading = false;
    },

    handleEditAdd (item) {
      this.modalData = {
        ...item,
        moderated: item.moderated ? 'Moderate' : 'Not moderated',
        status: item.status === 'active' ? 'Blocked' : 'Unlocked',
      };
      this.isEdit = true;
      this.dialogVisible = true;
    },

    handlePreview (item) {
      this.modalData = {
        ...item,
        moderated: item.moderated ? 'Moderated' : 'Not moderated',
        status: item.status === 'active' ? 'Blocked' : 'Unlocked',
      };
      this.onlyVideo = false;
      this.dialogVisible = true;
    },
    showOnlyFilesModal (item) {
      this.onlyVideo = true;
      this.modalData = item;
      this.dialogVisible = true;
    },
    close () {
      this.dialogVisible = false;
      this.isEdit = false;
      this.onlyVideo = false;
    },
  },

};
</script>

<style lang="scss">
.dspCreative {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }

  .dspCreative-from {
    position: absolute;
    top: 40px;
    right: 0;
    text-align: left;
    margin-bottom: 40px;

    & .el-form-item__label {
      line-height: 0;
    }

    .btns {
      margin-top: 20px;
    }
  }
}
</style>
