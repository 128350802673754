import {
  ACTION_ROOT_REQUEST,
  URL_BILLING_TRANSACTION_CREATE_GET,
  URL_BILLING_TRANSACTION_TRANSFER_CREATE,
  MUTATION_BILLING_TRANSACTIONS_SET,
  URL_BILLING_ORDERS_CREATE_GET,
  MUTATION_BILLING_ORDERS_SET,
  URL_BILLING_ORDERS_DONE,
  URL_BILLING_ORDERS_REJECT,
  URL_BILLING_ORDERS_ACCEPT,
  URL_BILLING_ORDERS_DELETE,
} from '../../const';
import moment from 'moment';
const state = {
  transactionsTableColumns: [
    {
      prop: 'id',
      label: 'ID',
      width: 50,
    },
    {
      prop: 'publisher',
      label: 'Publisher',
      width: 150,
    },
    {
      prop: 'agency',
      label: 'Agency',
      width: 150,
    },
    {
      prop: 'advertiser',
      label: 'Advertiser ',
      width: 150,
    },
    {
      prop: 'operation',
      label: 'Operations',
      width: 150,
    },
    {
      prop: 'amount',
      label: 'Amount',
      width: 150,
    },
    {
      prop: 'description',
      label: 'Description',
      width: 150,
    },
    {
      prop: 'created',
      label: 'Created at',
      width: 150,
    },
  ],
  transactionsData: {}, 
  ordersColumns: [
    {
      prop: 'options',
      label: '',
      width: 35,
      buttons: true,
    },
    {
      prop: 'status',
      label: 'Status',
      width: 70,
    },
    {
      prop: 'id',
      label: 'ID',
      width: 50,
    },
    {
      prop: 'publisher.name',
      label: 'Publisher',
      width: 150,
    },
    {
      prop: 'amount',
      label: 'Amount',
      width: 150,
    },
    {
      prop: 'description',
      label: 'Description',
      width: 150,
    },
    {
      prop: 'created',
      label: 'Created at',
      width: 150,
    },
  ],
  ordersData: {},
};

const getters = {
  transactionsColumn (state) {
    return state.transactionsTableColumns;
  },

  transactionsData (state) {
    return state.transactionsData;
  },

  ordersColumns (state) {
    return state.ordersColumns;
  },

  ordersData (state) {
    return state.ordersData;
  },
};

const mutations = {

  addTransaction (state, response) {
    state.transactionsData.push(response);
  },

  setTransactions (state, response) {
    state.transactionsData = response;
    const { rows } = response;
    if (rows) {
      state.transactionsData.rows = rows.map(item => {
        return { 
          ...item, 
          publisher: 'publisher' in item ? item.publisher.name : '-',
          agency: 'agency' in item ? item.agency.name : '-',
          advertiser: 'advertiser' in item ? item.advertiser.name : '-',
          created: moment(item.created).format('DD-MM-YYYY'),
        };
      });
    }
  },

  setOrders (state, response) {
    state.ordersData = response;
  },
};

const actions = {
  getTransactions: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_BILLING_TRANSACTION_CREATE_GET,
      commitName: MUTATION_BILLING_TRANSACTIONS_SET,
    }, { root: true });
    return res;
  },

  createTransaction: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_BILLING_TRANSACTION_CREATE_GET,
    }, { root: true });
    return res;
  },

  createTransfer: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_BILLING_TRANSACTION_TRANSFER_CREATE,
    }, { root: true });
    return res;
  },

  getOrders: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_BILLING_ORDERS_CREATE_GET,
      commitName: MUTATION_BILLING_ORDERS_SET,
    }, { root: true });
    return res;
  },

  createOrder: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_BILLING_ORDERS_CREATE_GET,
    }, { root: true });
    return res;
  },

  deleteOrder: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_BILLING_ORDERS_DELETE,
    }, { root: true });
    return response;
  },

  orderAccept: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_BILLING_ORDERS_ACCEPT,
    }, { root: true });
    return response;
  },

  orderReject: async ({ dispatch }, { params, data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      data,
      url: URL_BILLING_ORDERS_REJECT,
    }, { root: true });
    return response;
  },

  orderDone: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_BILLING_ORDERS_DONE,
    }, { root: true });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};