<template>
  <el-dialog
    :title=" formData ? 'Company Update' : 'Company creation'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="Loading"
      :model="form"
      :rules="rules"
      :disabled="userRole === 'advertiser'"
      label-position="left"
      style="position: inherit!important;"
    >
      <el-form-item
        v-if="userRole !== 'agency'"
        label="Agency"
        prop="agency"
      >
        <el-autocomplete
          v-model="form.agency"
          class="inline-input"
          :fetch-suggestions="querySearchAgency"
          @select="(item) => handleSelect(item, 'agency')"
        />
      </el-form-item>
      <el-form-item
        label="Advertiser"
        prop="advertiser"
      >
        <el-autocomplete
          v-model="form.advertiser"
          class="inline-input"
          :fetch-suggestions="querySearchAdvertiser"
          @select="(item) => handleSelect(item, 'advertiser')"
        />
      </el-form-item>
      <el-form-item
        prop="name"
        label="Name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        v-show="userRole === 'agency' ? userManagement !== 'limited' : true"
        label="Select level"
        prop="level"
      >
        <el-select
          v-model="form.level"
          placeholder="Select"
        >
          <el-option
            v-for="item in levels"
            :key="item.id"
            :label="item.label"
            :value="item.id"
            @click.native="form.level = item.id"
          />
            <!-- <el-option value="1" label="1 -- top priority before DSP" />
            <el-option value="2" label="2" />
            <el-option value="3" label="3" />
            <el-option value="4" label="4" />
            <el-option value="5" label="5 -- default priority before DSP" />
            <el-option value="6" label="6" />
            <el-option value="7" label="7 -- lowest priority before DSP" />
            <el-option value="8" label="8 -- top priority after DSP" />
            <el-option value="9" label="9" />
            <el-option value="10" label="10 -- lowest priority after DSP" /> -->
          <!-- <el-option
            v-for="item in 10"
            :key="item"
            :label="item"
            :value="item"
            @click.native="form.level = item"
          /> -->
        </el-select>
      </el-form-item>
      <el-form-item
        label="Start date"
        prop="limitDateStart"
      >
        <el-date-picker
          v-model="form.limitDateStart"
          class="el-date-picker"
          type="date"
        />
      </el-form-item>
      <el-form-item
        label="End date"
      >
        <el-date-picker
          v-model="form.limitDateEnd"
          class="el-date-picker"
          type="date"
        />
      </el-form-item>
      <el-form-item style="margin: 0">
        <el-col
          class="line"
          :span="5"
        >
          -
        </el-col>
        <el-col :span="5">
          Total
        </el-col>
        <el-col
          class="line"
          :span="5"
        >
          -
        </el-col>
        <el-col :span="5">
          Per day
        </el-col>
      </el-form-item>
      <el-form-item
        label="Imps"
        label-width="80px"
      >
        <el-col :span="11">
          <el-input
            v-model.number="form.limitImpsAll"
            type="number"
          />
        </el-col>
        <el-col
          class="line"
          :span="1"
        >
          -
        </el-col>
        <el-col :span="11">
          <el-input
            v-model.number="form.limitImpsDaily"
            type="number"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        label="Clicks"
        label-width="80px"
      >
        <el-col :span="11">
          <el-input
            v-model.number="form.limitClicksAll"
            type="number"
          />
        </el-col>
        <el-col
          class="line"
          :span="1"
        >
          -
        </el-col>
        <el-col :span="11">
          <el-input
            v-model.number="form.limitClicksDaily"
            type="number"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        label="Budget"
        label-width="80px"
      >
        <el-col :span="11">
          <el-input
            v-model.number="form.limitBudgetAll"
            type="number"
          />
        </el-col>
        <el-col
          class="line"
          :span="1"
        >
          -
        </el-col>
        <el-col :span="11">
          <el-input
            v-model.number="form.limitBudgetDaily"
            type="number"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData && userRole !== 'advertiser'"
          type="primary"
          @click="updateCampaigns"
        >
          Save
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="createCampaigns"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CAMPAIGNS_UPDATE,
  ACTION_CAMPAIGNS_CREATE,
  ACTION_AGENCIES_GET,
  GETTER_AGENCIES_DATA,
  GETTER_CAMPAINGS_GET_ONE,
  GETTER_ADVERTISERS_DATA,
  ACTION_ADVERTISERS_GET,
  GETTER_COMPANY_TABLE_DATA,
  GETTER_AUTH_USER_ROLE,
  ACTION_CAMPAIGN_GET,
  GETTER_AUTH_CURRENT_USER,
  GETTER_AUTH_USER_MANAGEMENT,
} from '@/const';

export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      Loading: false,
      form: {
        name: '',
        level: 6,
        limitDateStart: moment(),
        limitDateEnd: null,
        advertiser: '',
        agency: '',
        advertiserId: null,
        agencyId: null,
        limitImpsAll: null,
        limitImpsDaily: null,
        limitClicksAll: null,
        limitClicksDaily: null,
        limitBudgetAll: null,
        limitBudgetDaily: null,
      },
      rules: {
        advertiser: [
          { required: true, message: 'Fill in the field' },
        ],
        agency: [
          { required: true, message: 'Fill in the field' },
        ],
        name: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        level: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        limitDateStart: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
      },
      links: [],
    };
  },

  computed: {
    ...mapGetters({
      tableData: GETTER_COMPANY_TABLE_DATA,
      getAgenciesData: GETTER_AGENCIES_DATA,
      getAdvertisersData: GETTER_ADVERTISERS_DATA,
      userRole: GETTER_AUTH_USER_ROLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
      getOne: GETTER_CAMPAINGS_GET_ONE,
      userManagement: GETTER_AUTH_USER_MANAGEMENT,
    }),

    advertiserLinks () {
      return this.getAdvertisersData.rows.map(item => {
        return {
          id: item.id,
          value: item.name,
        };
      });
    },

    agencyLinks () {
      return this.getAgenciesData.rows.map(item => {
        return {
          id: item.id,
          value: item.name,
        };
      });
    },

    levels () {
      return [
        { id: 1, label: '1' },
        { id: 2, label: '2' },
        { id: 3, label: '3' },
        { id: 4, label: '4' },
        { id: 5, label: '5' },
        { id: 6, label: '6' },
        { id: 7, label: '7' },
        { id: 8, label: '8' },
        { id: 9, label: '9' },
        { id: 10, label: '10' },
      ];
    },
  },

  async created () {
    this.Loading = true;
    if (this.formData) {
      await this.getCampaign({
        params: { id: this.formData.id },
      });

      const form = this.getOne;

      delete form.updated;
      delete form.status;
      delete form.created;
      delete form.imps;
      delete form.impsToday;
      delete form.clicks;
      delete form.clicksToday;
      delete form.budget;
      delete form.budgetToday;
      delete form.creatives;
      delete form.agencyAdvertiser;
      delete form.limitDate;
      delete form.limitImps;
      delete form.limitClicks;
      delete form.limitBudget;

      this.form = { ...form,
        agency: form.agency.name,
        advertiser: form.advertiser.name,
        agencyId: form.agency.id,
        advertiserId: form.advertiser.id,

      };
    }

    if (this.userRole === 'agency') {
      this.form.agencyId = this.currentUser.agencies[0].id;
    }
    await this.fetchAllAgencies({ query: { limit: 0 } });
    await this.fetchAllAdvertisers({ query: { limit: 0 } });
    this.Loading = false;
  },

  methods: {
    ...mapActions({
      updateCampaign: ACTION_CAMPAIGNS_UPDATE,
      createCampaign: ACTION_CAMPAIGNS_CREATE,
      fetchAllAgencies: ACTION_AGENCIES_GET,
      fetchAllAdvertisers: ACTION_ADVERTISERS_GET,
      getCampaign: ACTION_CAMPAIGN_GET,
    }),

    querySearchAdvertiser (queryString, cb) {
      const links = this.advertiserLinks;
      const results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },

    querySearchAgency (queryString, cb) {
      const links = this.agencyLinks;
      const results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },


    createFilter (queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleOpen (error = [{ msg: 'Something went wrong' }]) {
      console.log(error, 'error-text');
      this.$messageBox.alert(
        `${
          error.map((item) => {
            return ' ' + item.msg + ' '
            ;})
        }
        `, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    handleSelect (item, position) {
      if (position === 'agency') {
        this.form.agencyId = item.id;
      }
      if (position === 'advertiser') {
        this.form.advertiserId = item.id;
      }
    },

    showAlert () {
      this.$messageBox.alert(
        'Fill in all required fields'
      );
    },

    async updateCampaigns () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          const endDate = this.form.limitDateEnd === null ? null : moment(this.form.limitDateEnd).format('YYYY-MM-DD');
          const data =  { 
            ...this.form,
            limitDateStart: moment(this.form.limitDateStart).format('YYYY-MM-DD'),
            limitDateEnd: endDate, 
          };

          delete data.agency;
          delete data.advertiser;

          this.Loading = true;
          const response = await this.updateCampaign({
            params: { id: this.form.id },
            data,
          });

          if (response && response.data.errors) {
            this.handleOpen(response.data.errors);
          }

          if (response.status === 200) {
            this.$emit('close');
          }
          this.$emit('newPage', this.tableData.currentPage);
          this.Loading = false;
        } else {
          this.showAlert();
        }
      });
    },

    async createCampaigns () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          const endDate = this.form.limitDateEnd === null ? null : moment(this.form.limitDateEnd).format('YYYY-MM-DD');
          this.Loading = true;
          
          const data =  { 
            ...this.form,
            limitDateStart: moment(this.form.limitDateStart).format('YYYY-MM-DD'), 
            limitDateEnd: endDate,
          };

          delete data.agency;
          delete data.advertiser;

          const response = await this.createCampaign({
            data,
          });

          if (response && response.data.errors) {
            this.handleOpen(response.data.errors);
          }

          if (response.status === 200) {
            this.$emit('close');
          }

          this.$emit('newPage', this.tableData.currentPage);
        } else {
          this.showAlert();
        }
        this.Loading = false;
      });
    },
  },

};
</script>

<style lang="scss">
  .el-form {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 450px;
    padding-right: 15px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
    }

    &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
    }

    & .el-autocomplete, .el-input, .el-date-picker {
    max-width: 440px !important;
    width: 100% !important;
    }
  }

  .update-button-item {
    text-align: end;

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
.el-dialog{
  max-width: 500px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.line {
  color: #fff;
}
</style>
