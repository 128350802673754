<template>
  <el-dialog
    :title=" formData ? 'DSP updating' : 'DSP creating'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      style="position: inherit!important;"
    >
      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="Currency"
        prop="currencyId"
      >
        <el-select v-model="form.currencyId">
          <el-option
            v-for="item in currencies"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Endpoint"
        prop="url"
      >
        <el-input
          v-model="form.url"
        />
      </el-form-item>
      <el-form-item
        label="Cookie matching url"
        prop="uidMatchUrl"
      >
        <el-input
          v-model="form.uidMatchUrl"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.matchedUsersOnly"
          label="Matched users only"
        />
      </el-form-item>
      <el-form-item
        label="Max bidfloor"
        prop="bidfloor"
      >
        <el-input
          v-model.number="form.bidfloor"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Commission %"
      >
        <el-input
          v-model.number="form.commission"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Moderation"
      >
        <el-select v-model="form.moderation">
          <el-option
            v-for="item in options.moderation"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Auction type"
      >
        <el-select v-model="form.auctionType">
          <el-option
            v-for="item in options.auctionType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Limit QPS"
      >
        <el-input
          v-model.number="form.limitQps"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Limit tmax"
      >
        <el-input
          v-model.number="form.limitTmax"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Request type"
      >
        <el-select v-model="form.requestType">
          <el-option
            v-for="item in options.requestType"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Response type"
      >
        <el-select v-model="form.responseType">
          <el-option
            v-for="item in options.responseType"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Adm place"
      >
        <el-select v-model="form.admPlace">
          <el-option
            v-for="item in options.admPlace"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Traffic type"
      >
        <el-select v-model="form.limitTrafficType">
          <el-option
            v-for="item in options.limitTrafficType"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Imp type"
      >
        <el-select v-model="form.limitImpType">
          <el-option
            v-for="item in options.limitImpType"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.callNurl"
          label="Call nurl"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.callBurl"
          label="Call burl"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.callLoss"
          label="Call loss"
        />
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData"
          type="primary"
          @click="updateDsp"
        >
          Save
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="createDsp"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_DSP_UPDATE,
  ACTION_DSP_CREATE,
  ACTION_DSP_GET_ONE,
  GETTER_DSP_ONE,
  GETTER_DSP_CURRENCIES,
  ACTION_DSP_CURRENCIES_GET,
} from '../../const';
export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      options: {
        moderation: [
          { value: 'premoderation' },
          { value: 'postmoderation' },
        ],
        auctionType: [
          { value: 1, label: 'First price' },
          { value: 2, label: 'Second price' },
        ],
        requestType: [
          { value: 'rtb' },
          { value: 'vast' },
        ],
        responseType: [
          { value: 'rtb' },
          { value: 'vast' },
        ],
        admPlace: [
          { value: 'adm' },
          { value: 'nurl' },
        ],
        limitTrafficType: [
          { value: 'all' },
          { value: 'app' },
          { value: 'site' },
        ],
        limitImpType: [
          { value: 'all' },
          { value: 'video' },
          { value: 'banner' },
        ],
        currencies: [],
      },
      loading: false,
      form: {
        name: '',
        currencyId: null,
        url: '',
        bidfloor: 0,
        commission: null,
        moderation: 'premoderation',
        uidMatchUrl: '',
        auctionType: 2,
        limitQps: 0,
        limitTmax: 0,
        matchedUsersOnly: false,
        requestType: 'rtb',
        responseType: 'rtb',
        admPlace: 'adm',
        limitTrafficType: 'all',
        limitImpType: 'all',
        callNurl: true,
        callBurl: false,
        callLoss: false,
      },
      rules: {
        name: { required: true, message: 'Fill in the field' },
        url: { required: true, message: 'Fill in the field' },
        moderation: { required: true, message: 'Fill in the field' },
        currencyId: { required: true, message: 'Fill in the field' },
      },
    };
  },

  computed: {
    ...mapGetters({
      currencies: GETTER_DSP_CURRENCIES, 
      oneDsp: GETTER_DSP_ONE,
    }),
  },

  async created () {
    await this.getCurrencies();
    if (this.formData) {
      this.loading = true;
      await this.fetchOneDsp({
        params: {
          id: this.formData.id,
        },
      });

      const form = { ...this.oneDsp };

      delete form.created;
      delete form.updated;

      this.form = {
        ...form,
        currencyId: form.currency ? form.currency.id : null,
        matchedUsersOnly: Boolean(form.matchedUsersOnly),
        callNurl: Boolean(form.callNurl),
        callBurl: Boolean(form.callBurl),
        callLoss: Boolean(form.callLoss),
      };
      this.loading = false;
    } else {
      const selected = this.currencies.filter(value => value.rate === 1);
      this.form.currencyId = selected.length ? selected[0].id : this.currencies[0].id;
    }
  },

  methods: {
    ...mapActions({
      create: ACTION_DSP_CREATE,
      update: ACTION_DSP_UPDATE,
      fetchOneDsp: ACTION_DSP_GET_ONE,
      getCurrencies: ACTION_DSP_CURRENCIES_GET,
    }),
    async createDsp () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.loading = true;
          await this.create({
            data: {
              ...this.form,
              matchedUsersOnly: Number(this.form.matchedUsersOnly),
              callNurl: Number(this.form.callNurl),
              callBurl: Number(this.form.callBurl),
              callLoss: Number(this.form.callLoss),
            },
          });
          this.$emit('close');
          this.$emit('newPage');
          this.loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
    },

    async updateDsp () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.loading = true;
          await this.update({
            params: { id: this.formData.id },
            data: {
              ...this.form,
              matchedUsersOnly: Number(this.form.matchedUsersOnly),
              callNurl: Number(this.form.callNurl),
              callBurl: Number(this.form.callBurl),
              callLoss: Number(this.form.callLoss),
            },
          });
          this.$emit('close');
          this.$emit('newPage');
          this.loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
    },
  },
};
</script>

<style>

</style>
