<template>
  <div class="agencies">
    <el-button
      class="agencies-add-btn"
      type="primary"
      @click="openDialog"
    >
      Add user
    </el-button>
    <Table
      :table-data="usersData"
      :column="getAdvertisersUsersColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id"
          trigger="click"
          :disabled="ownerId === scope.row.id"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deleteAdvertiser(scope.row.id)"
            > 
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <AdvertisersUsersModal
      v-if="dialogVisible"
      :advertiser_id="id"
      :dialog-visible="dialogVisible"
      @close="close"
    />
  </div>
</template>

<script>

import 
{ 
  ACTION_ADVERTISERS_USERS_GET,
  GETTER_ADVERTISERS_USERS_DATA, 
  GETTER_ADVERTISERS_USERS_TABLE,
  ACTION_ADVERTISERS_USERS_DELETE,
  ACTION_ADVERTISER_GET,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import AdvertisersUsersModal from './AdvertisersUsersModal.vue';
import Table from '@/components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  name: 'AdvertisersUsers',
  components: {
    AdvertisersUsersModal,
    Table,
  },
  data () {
    return {
      dialogVisible: false,
      id: null,
      loading: false,
      ownerId: null,
    };
  },
  computed: {
    ...mapGetters({
      getAdvertisersUser: GETTER_ADVERTISERS_USERS_DATA,
      getAdvertisersUsersColumns: GETTER_ADVERTISERS_USERS_TABLE,
    }),

    usersData () {
      let data = { ...this.getAdvertisersUser };
      data.rows = data.rows ? data.rows.filter(item => item.id !== this.ownerId) : [];
      return data;
    },
  },
  async created () {
    await this.getNewPage();
    const { data } = await this.fetchAdvertiser({
      params: { id: parseInt(this.$route.params.id) },
    });
    if (data) {
      this.ownerId = data.owner.id;
    }

  },
  methods: {
    ...mapActions({
      fetchAllUsers: ACTION_ADVERTISERS_USERS_GET,
      removeAdvertisersUsers: ACTION_ADVERTISERS_USERS_DELETE,
      fetchAdvertiser: ACTION_ADVERTISER_GET,
    }),

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    openDialog () {
      this.dialogVisible = true;
      this.id = this.$route.params;
    },

    async getNewPage (page = 1) {
      this.loading = true;
      await this.fetchAllUsers({
        params: this.$route.params,
        query: { page },
      });
      this.loading = false;
    },

    async deleteAdvertiser (userId) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {         
        const res = await this.removeAdvertisersUsers({
          params: {
            advertisers_id: parseInt(this.$route.params.id),
            id: userId,
          },
        });
        if (res.status === 200) {
          this.$messageBox.alert(res.data.success, 'Success', {
            confirmButtonText: 'OK',
            callback: async () => {
              await this.fetchAllUsers({
                params: this.$route.params,
              });
            },
          });
        }
      });
    },

    close () {
      this.dialogVisible = false;
    },
  },
};
</script>