<template>
  <div class="campaign-report">
    <el-form
      :inline="true"
      :model="form"
      label-position="top"
      class="campaign-report-form"
    >
      <el-form-item label="Choose report">
        <el-select
          v-model="form.report"
          placeholder="Choose"
        >
          <el-option
            v-for="item in filter.reports"
            :key="item.name"
            :label="item.title"
            :value="item.name"
            @click.native="selectedItem(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Select date range">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="Before"
          start-placeholder="Start date"
          end-placeholder="Date start"
        />
      </el-form-item>
      <el-form-item class="apply-btn">
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Apply
        </el-button>
      </el-form-item>
    </el-form>
    <ReportTable
      :report-data="campaignsReport"
      @goToPage="getNewPage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CAMPAIGNS_GET_REPORT,
  GETTER_COMPANY_REPORT,
  ACTION_CAMPAIGNS_GET_REPORT_LIST,
  GETTER_COMPANY_REPORTS_LIST,
} from '@/const';

import ReportTable from '@/components/ReportTable';
import { getReportQuery } from '@/utils';
export default {
  components: {
    ReportTable,
  },
  data () {
    return {
      filter: {
        date: moment(),
        reports: [],
      },
      form: {
        date: [moment().subtract(1, 'month').format('YYYY, MM, DD'), moment().format('YYYY, MM, DD')],
        report: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      campaignsReport: GETTER_COMPANY_REPORT,
      campaignsReportsList: GETTER_COMPANY_REPORTS_LIST,
    }),
  },

  async created () {
    await this.fetchCampaignsReportsList({
      params: { id: this.$route.params.id },
    });
    this.filter.reports = this.campaignsReportsList.rows;
    this.form.report = this.filter.reports[0].name;

    await this.getCampaignsReport({
      params: {
        id: this.$route.params.id,
        reportName: this.filter.reports[0].name,
      },
      query: getReportQuery(this),
    });
  },

  methods: {
    ...mapActions({
      getCampaignsReport: ACTION_CAMPAIGNS_GET_REPORT,
      fetchCampaignsReportsList: ACTION_CAMPAIGNS_GET_REPORT_LIST,
    }),
    async onSubmit () {
      await this.getCampaignsReport({
        params: {
          id: this.$route.params.id,
          reportName: this.form.report,
        },
        query: getReportQuery(this),
      });
    },

    async getNewPage (page) {
      await this.getCampaignsReport({
        params: {
          id: this.$route.params.id,
          reportName: this.form.report,
        },
        query: getReportQuery(this, page),
      });
    },

    selectedItem (item) {
      this.form.report = item.name;
    },
  },
};
</script>

<style lang="scss">
.campaign-report {
  width: 100%;
  position: relative;
  padding-top: 50px;

  .campaign-report-form{
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    top: 50px;
    position: absolute;
    right: 200px;
    z-index: 2;
  }

  &-form {
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    .el-form-item {
      margin-right: 10px !important;
      &__label{
        line-height: 0;
      }
    }
  }
}
</style>