<template>
  <div class="input-block">
    <span 
      class="label"
    >
      {{ label }}
    </span>
    <el-input
      v-model="inputValue"
      :type="type"
      :placeholder="placeholder"
      :class="{ 'input-error': isError }"
      :show-password="isPassword"
      autocomplete="off"
      @blur="onBlur(type)"
    />
    <span
      v-if="isError"
      class="errorMsg"
    >
      {{ errorMsg }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'FormInput',
  props: {
    type: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    isError: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    errorMsg: {
      type: String,
      default: '',
    },

    isPassword: {
      type: Boolean,
      default: false,
    },
  },
  
  data () {
    return {
      inputValue: '',
    };
  },
  methods: {
    onBlur (type) {
      this.$emit('onBlur', { val: this.inputValue, type, name: this.name });
    },
  },
};

</script>

<style lang="scss">
.input-block {
  
  & .input-error {
    border: 1px solid red;
    border-radius: 5px;
    max-height: 40px;
  }

  & .errorMsg {
    position: absolute;
    top: 103%;
    font-size: 13px;
    left: 10px;
    color: red;
    line-height: 1;
  }
}
</style>