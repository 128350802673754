import {
  MUTATION_SYSTEM_CURRENT_REPORT_SET,
  MUTATION_SYSTEM_REPORTS_LIST_SET,
  URL_SYSTEM_REPORT_GET,
  URL_SYSTEM_REPORTS_LIST_GET,
  ACTION_ROOT_REQUEST,
} from '@/const';

const state = {
  currentReport: {},
  reportsList: {},
};

const getters = {
  getCurrentReport (state) {
    return state.currentReport;
  },
  getReportsList (state) {
    return state.reportsList;
  },
};

const mutations = {
  setCurrentReport (state, response) {
    state.currentReport = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.currentReport.rows = tableData;
    state.currentReport.excelData = excelData;
  },
  setSystemReportsList (state, response) {
    state.reportsList = response;
  },
}; 

const actions = {
  fetchCurrentReport: async ({ dispatch }, { query, params } = {}) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_SYSTEM_REPORT_GET,
      commitName: MUTATION_SYSTEM_CURRENT_REPORT_SET,
    }, { root: true });
    return res;
  },

  fetchReportsList: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_SYSTEM_REPORTS_LIST_GET,
      commitName: MUTATION_SYSTEM_REPORTS_LIST_SET,
    }, { root: true });
    return res;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};