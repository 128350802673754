import moment from 'moment';


export const getUrl = (url, params) => {
  let keys = Object.keys(params);
  keys.forEach(key => {
    url = url.replace(':' + key, params[key]);
  });
  return url;
};
export const getReportQuery = ({ form }, page = null) => {
  const { report, date } = form;
  const query = {
    'between[from]': moment(date[0]).format('YYYY-MM-DD'),
    'between[to]': moment(date[1]).format('YYYY-MM-DD'),
    format: 'json',
    reportName: report,
  };
  if (page) {
    query.page = page;
  }

  return query;
};


export const changeOptionsBGMouseOver = (row, column, cell) => {
  if (column.property === 'options') {
    cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
  }
};


export const changeOptionsBGMouseOut = (row, column, cell) => {
  if (column.property === 'options') {
    cell.style = 'background-color: none; color: #409EFF';
  }
};