import {
  ACTION_ROOT_REQUEST,
  MUTATION_APPS_SET_REPORT,
  MUTATION_APPS_SET_REPORTS_LIST,
  MUTATION_MAC_ADDRESS_LIST_SET,
  MUTATION_MAC_ADDRESS_LIST_SETONE,
  URL_APPS_CHANGE_ACTIVATE,
  URL_APPS_CHANGE_BLOCK,
  URL_APPS_GET_REPORT,
  URL_APPS_GET_REPORT_LIST,
  URL_APPS_STATUS_PAUSE,
  URL_MAC_ADDRESS_LIST_DELETE_UPDATE,
  URL_MAC_ADDRESS_LIST_GET_CREATE,
} from '@/const';

const state = {
  tableDataMac: {},
  oneMacAddressList: null,
  categories: [],
  reportsData: {},
  reportsList: {},
};

const getters = {
  getTableColumnsMac (state, getters, rootState, rootGetters) {
    const userRole = rootGetters['auth/userRole'];
    const adminColumns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'pool_id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: 'agency.name',
        label: 'Agency',
        width: 150,
      },
    ];
    const clientColumns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'pool_id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
    ];
    return ['administrator', 'manager'].includes(userRole) ? adminColumns : clientColumns;
  },

  getTableDataMac (state) {
    return state.tableDataMac;
  },

  getOneMacAddressList (state) {
    return state.oneMacAddressList;
  },
};

const mutations = {
  setMacAddressList: (state, response) => {
    state.tableDataMac = {
      rows: response.rows,
    };
  },

  setOneMacAddressList (state, response) {
    state.oneMacAddressList = response;
  },
};

const actions = {
  getMacAddressList: async ({ dispatch }, query) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'GET',
        query,
        url: URL_MAC_ADDRESS_LIST_GET_CREATE,
        commitName: MUTATION_MAC_ADDRESS_LIST_SET,
      },
      { root: true }
    );
    return res.data;
  },

  getOneMacAddressList: async ({ dispatch }, { params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'GET',
        params,
        url: URL_MAC_ADDRESS_LIST_DELETE_UPDATE,
        commitName: MUTATION_MAC_ADDRESS_LIST_SETONE,
      },
      { root: true }
    );
    return res;
  },

  updateMacAddressList: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'PUT',
        data,
        params,
        url: URL_MAC_ADDRESS_LIST_DELETE_UPDATE,
      },
      { root: true }
    );
    return res;
  },

  createMacAddressList: async ({ dispatch }, { data }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'POST',
        data,
        url: URL_MAC_ADDRESS_LIST_GET_CREATE,
      },
      { root: true }
    );
    return res;
  },

  deleteMacAddressList: async ({ dispatch }, { params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'DELETE',
        params,
        url: URL_MAC_ADDRESS_LIST_DELETE_UPDATE,
      },
      { root: true }
    );
    return res;
  },

  changeStatusPause: async ({ dispatch }, { params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'PATCH',
        params,
        url: URL_APPS_STATUS_PAUSE,
      },
      { root: true }
    );
    return res;
  },

  changeStatusActivate: async ({ dispatch }, { params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'PATCH',
        params,
        url: URL_APPS_CHANGE_ACTIVATE,
      },
      { root: true }
    );
    return res;
  },

  changeStatusBlock: async ({ dispatch }, { params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'PATCH',
        params,
        url: URL_APPS_CHANGE_BLOCK,
      },
      { root: true }
    );
    return res;
  },

  getMacAddressReport: async ({ dispatch }, { params, query }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'GET',
        query,
        params,
        url: URL_APPS_GET_REPORT,
        commitName: MUTATION_APPS_SET_REPORT,
      },
      { root: true }
    );
    return res;
  },

  getMacAddressReportsList: async ({ dispatch }, { params }) => {
    const res = await dispatch(
      ACTION_ROOT_REQUEST,
      {
        method: 'GET',
        params,
        url: URL_APPS_GET_REPORT_LIST,
        commitName: MUTATION_APPS_SET_REPORTS_LIST,
      },
      { root: true }
    );
    return res;
  },

  getRole: ({ rootGetters }) => rootGetters['auth/userRole'],
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
