<template>
  <el-form
    id="performance"
    ref="form"
    v-loading="loading"
    class="demo-modalForm"
    style="max-height: 90%; margin: 0 auto; width: 100% !important;"
    label-position="top"
    :model="modalForm"
    :disabled="userRole === 'advertiser'"
    :rules="rules"
  >
    <el-form-item
      label="Name"
      class="demo-item"
      prop="name"
    >
      <el-input
        v-model="modalForm.name"
        type="text"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item
      prop="categoryId"
    >
      <el-tree
        ref="tree"
        :props="treePropsCategory"
        :data="catData"
        show-checkbox
        node-key="id"
        :expand-on-click-node="true"
        highlight-current
        @check="handleNodeClick"
      />
    </el-form-item>
    <el-form-item
      label="Landing URL"
      prop="landingUrl"
    >
      <el-input
        v-model="modalForm.landingUrl"
        type="text"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item
      class="text-area"
      label="Skip timeout (ms)"
    >
      <el-input
        v-model="params.closeTimeout"
        name="closeTimeout"
        type="number"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      class="text-area"
      label="CTA"
    >
      <el-input
        v-model="params.cta"
        name="cta"
        type="text"
      />
    </el-form-item>
    <el-form-item
      class="text-area"
      label="Store URL"
    >
      <el-input
        v-model="params.storeurl"
        name="storeurl"
        type="text"
      />
    </el-form-item>
    <el-form-item
      class="text-area"
      label="App title"
    >
      <el-input
        v-model="params.appTitle"
        name="appTitle"
        type="text"
      />
    </el-form-item>
    <el-form-item
      class="text-area"
      label="App rating"
    >
      <el-input
        v-model="params.appRating"
        name="appRating"
        type="text"
      />
    </el-form-item>
    <el-form-item
      class="column"
      label="App icon"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        :file-list="selectedIconFiles"
        :on-change="(file) => handlePreview(file, false, true)"
        :on-preview="openFile"
        action=""
        :before-remove="(file) => beforeRemove(file, false, true)"
        :auto-upload="false"
        multiple
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
        >
          Select a file
        </el-button>
      </el-upload>
    </el-form-item>
    <el-form-item
      class="column"
      label="Vertical"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        :file-list="selectedPortraitFiles"
        :on-change="(file) => handlePreview(file, true, false)"
        :on-preview="openFile"
        action=""
        :before-remove="(file) => beforeRemove(file, true, false)"
        :auto-upload="false"
        multiple
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
        >
          Select a file
        </el-button>
      </el-upload>
    </el-form-item>
    <el-form-item
      class="column upload-files"
      label="Horizontal"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        :file-list="selectedLandscapeFiles"
        :on-change="(file) => handlePreview(file, false, false)"
        :on-preview="openFile"
        action=""
        :before-remove="(file) => beforeRemove(file, false, false)"
        :auto-upload="false"
        multiple
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
        >
          Select a file
        </el-button>
      </el-upload>
    </el-form-item>
    <el-form-item
      class="fonts"
      label="Pixels"
    />
    <el-form-item
      label="Imps"
    >
      <el-input
        v-model="modalForm.params.impTracking"
        name="impression"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      label="Clicks"
    >
      <el-input
        v-model="modalForm.params.clickTracking"
        name="clickTracking"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      label="Start"
    >
      <el-input
        v-model="params.start"
        name="start"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      label="First quartile"
    >
      <el-input
        v-model="params.firstQuartile"
        name="firstQuartile"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      label="Midpoint"
    >
      <el-input
        v-model="params.midpoint"
        name="midpoint"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      label="Third quartile"
    >
      <el-input
        v-model="params.thirdQuartile"
        name="thirdQuartile"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      class="text-area"
      label="Complete"
    >
      <el-input
        v-model="params.complete"
        name="complete"
        type="textarea"
        autosize
        :rows="3"
      />
    </el-form-item>
    <el-form-item
      class="item-flex"
    >
      <el-button
        v-if="creativeId"
        type="primary"
        @click="() => submitForm('update')"
      >
        Save
      </el-button>
      <el-button
        v-else
        type="primary"
        @click="() => submitForm('create')"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CREATIVE_CREATE_FILES,
  ACTION_CREATIVE_CURRENT_GET,
  GETTER_APP_TABLE_CATEGORIES,
} from '@/const';

export default {
  name: 'Performance',
  props: {
    creativeId: {
      type: Number,
      default: null,
    },
    currentCreative: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    userRole: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      modalForm: {
        name: '',
        categoryId: null,
        files: [],
        landingUrl: '',
        params: {
          impTracking: '',
          clickTracking: '',
        },
      },
      params: {
        start: '',
        firstQuartile: '',
        midpoint: '',
        thirdQuartile: '',
        complete: '',
        closeTimeout: null,
        cta: '',
        storeurl: '',
        appTitle: '',
        appRating: '',
        iconUrl: null,
        iconFileId: null,
        videoLandscapeUrl: null,
        videoLandscapeFileId: null,
        videoPortraitUrl: null,
        videoPortraitFileId: null,
      },

      selectedIconFiles: [],
      selectedPortraitFiles: [],
      selectedLandscapeFiles: [],
      selectedCategory: 'Users Interests',
      treePropsCategory: {
        label: 'name',
        children: 'children',
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: GETTER_APP_TABLE_CATEGORIES,
    }),
    catData () {
      const catData = [{
        name: this.selectedCategory,
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
      }
      for (const [key, value] of Object.entries(this.categories)) {
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },
  },
  created () {
    if (this.creativeId) {
      this.modalForm = this.currentCreative;
      const form = this.currentCreative;

      delete form.budget;
      delete form.budgetToday;
      delete form.clicks;
      delete form.clicksToday;
      delete form.created;
      delete form.imps;
      delete form.impsToday;
      delete form.status;
      delete form.updated;
      delete form.lineItems;
      delete form.id;

      const { impTracking, clickTracking, eventTracking, cta, closeTimeout, storeurl, appTitle, appRating } = form.params;
      if (eventTracking) {
        const start = eventTracking.filter(item => item.event === 'start');
        const startArr = start.map(item => item.url);
        this.params.start = startArr.join('\n');

        const firstQu = eventTracking.filter(item => item.event === 'firstQuartile');
        const firstQuArr = firstQu.map(item => item.url);
        this.params.firstQuartile = firstQuArr.join('\n');

        const midPo = eventTracking.filter(item => item.event === 'midpoint');
        const midPoArr = midPo.map(item => item.url);
        this.params.midpoint = midPoArr.join('\n');

        const thirdQu = eventTracking.filter(item => item.event === 'thirdQuartile');
        const thirdQuArr = thirdQu.map(item => item.url);
        this.params.thirdQuartile = thirdQuArr.join('\n');

        const trComplete = eventTracking.filter(item => item.event === 'complete');
        const trCompleteArr = trComplete.map(item => item.url);
        this.params.complete = trCompleteArr.join('\n');
      }
      this.params.cta = cta;
      this.params.storeurl = storeurl;
      this.params.appTitle = appTitle;
      this.params.appRating = parseFloat(appRating) || null;
      this.params.closeTimeout = parseInt(closeTimeout) || null;
      if (impTracking) {
        this.imp = impTracking.join('\n');
      }
      if (clickTracking) {
        this.clicks = clickTracking.join('\n');
      }

      if (form) {
        const icon = form.files[form.params.iconFileId];
        const landscape = form.files[form.params.videoLandscapeFileId];
        const portrait = form.files[form.params.videoPortraitFileId];
        this.selectedIconFiles = icon ? [{ name: icon.name, url: icon.url, id: icon.id }] : [];
        this.selectedLandscapeFiles = landscape ? [{ name: landscape.name, url: landscape.url, id: landscape.id }] : [];
        this.selectedPortraitFiles = portrait ? [{ name: portrait.name, url: portrait.url, id: portrait.id }] : [];
        this.params.iconFileId = icon ? icon.id : null;
        this.params.iconUrl = icon ? icon.url : null;
        this.params.videoLandscapeFileId = landscape ? landscape.id : null;
        this.params.videoLandscapeUrl = landscape ? landscape.url : null;
        this.params.videoPortraitFileId = portrait ? portrait.id : null;
        this.params.videoPortraitUrl = portrait ? portrait.url : null;
        if (form.category) {
          this.selectedCategory = form.category.name;
          this.handleNodeClick(form.category);
        }
        this.modalForm = {
          ...form,
          categoryId: form.category ? form.category.id : null,
          files: Object.values(form.files).map(file => file.id),
        };
        this.modalForm.params = {
          impTracking: this.imp,
          clickTracking: this.clicks,
        };
        delete this.modalForm.category;
        delete this.modalForm.format;
        delete this.modalForm.campaign;
      }
    }
  },
  methods: {
    ...mapActions({
      getCurrentCreative: ACTION_CREATIVE_CURRENT_GET,
      createFiles: ACTION_CREATIVE_CREATE_FILES,
    }),
    submitForm (action) {
      this.$emit('submitForm', { action, commonData: { ...this.modalForm, params: { ...this.modalForm.params, ...this.params } } });
    },
    handleNodeClick (data) {
      this.$nextTick(() => {
        if (data.id) {
          this.$refs.tree.setCheckedKeys([]);
          this.selectedCategory = data.name;
          this.$refs.tree.setCheckedKeys([data.id]);
          this.modalForm.categoryId = data.id;
        }
      });
    },

    setFileData ({ fileId, fileUrl, fileData, isPortrait = false, isIcon = false }) {
      if (!fileId) {
        if (isPortrait && this.selectedPortraitFiles[0]) {
          this.$emit('pushFileForDelete', this.selectedPortraitFiles[0].id);
        } else if (isIcon && this.selectedIconFiles[0]) {
          this.$emit('pushFileForDelete', this.selectedIconFiles[0].id);
        } else if (!isPortrait && this.selectedLandscapeFiles[0]) {
          this.$emit('pushFileForDelete', this.selectedLandscapeFiles[0].id);
        }
      }
      if (isPortrait) {
        this.params.videoPortraitFileId = fileId || null;
        this.params.videoPortraitUrl = fileUrl || null;
        this.selectedPortraitFiles = fileData || [];
      } else if (isIcon) {
        this.params.iconFileId = fileId || null;
        this.params.iconUrl = fileUrl || null;
        this.selectedIconFiles = fileData || [];
      } else {
        this.params.videoLandscapeFileId = fileId || null;
        this.params.videoLandscapeUrl = fileUrl || null;
        this.selectedLandscapeFiles = fileData || [];
      }
    },

    async handlePreview (file, isPortrait, isIcon) {
      this.$emit('setLoad', true);
      let formData = new FormData();

      formData.append('file', file.raw);
      formData.append('name', file.name);

      const response = await this.createFiles({
        data: formData,
      });

      if (!response.data.errors) {
        const fileId = response.data.id;
        this.modalForm.files.push(parseInt(fileId));
        this.setFileData({
          fileId: parseInt(fileId),
          fileUrl: response.data.url,
          fileData: [{
            name: file.name,
            url: response.data.url,
            id: fileId,
          }],
          isPortrait,
          isIcon,
        });
      } else {
        this.setFileData({ isPortrait, isIcon });
        this.$messageBox.alert(
          'Invalid file!'
        );
      }
      this.$emit('setLoad', false);
    },
    openFile ({ url }) {
      if (this.creativeId) {
        window.open(url);
      }
    },
    beforeRemove (file, isPortrait, isIcon) {
      return this.$messageBox.confirm(`Delete file: ${ file.name } ?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then( async () => {
        if (this.creativeId) {
          this.$emit('pushFileForDelete', file.id);
        }
        this.setFileData({ isPortrait, isIcon });
      });
    },
  },
};
</script>