<template>
  <div class="table">
    <div class="table-btns">
      <el-button
        v-if="userRole !== 'advertiser'"
        class="campaigns-add-btn"
        type="primary"
        @click="createStrategy()"
      >
        Create
      </el-button>
    </div>
    <Table
      :table-data="tableData"
      :column="getColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo(scope.row.id)"
            >
              Reports
            </el-dropdown-item>
            <el-dropdown-item
              v-if="['administrator', 'manager', 'client'].includes(currentUser.role)"
              divided
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
            <el-dropdown-item
              :icon="userRole !== 'advertiser' ? 'el-icon-edit': ''"
              @click.native="handleEditAdd(scope.row)"
            >
              {{ userRole === 'advertiser'? 'View' :'Edit' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="['administrator', 'manager'].includes(currentUser.role)"
              icon="el-icon-copy-document"
              @click.native="handleCopy(scope.row)"
            >
              Copy
            </el-dropdown-item>
            <el-dropdown-item
              v-if="userRole !== 'advertiser'"
              icon="el-icon-delete"
              @click.native="deleteElement(scope.row)"
            >
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <StrategyModal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="closeModal"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GETTER_STRATEGY_CURRENT,
  ACTION_STRATEGY_DELETE,
  ACTION_STRATEGY_ACTIVATE,
  ACTION_STRATEGY_BLOCK,
  ACTION_STRATEGY_PAUSE,
  ACTION_STRATEGY_COPY,
  GETTER_STRATEGY_TABLE_COLUMNS,
  ACTION_STRATEGY_GET,
  GETTER_AUTH_CURRENT_USER,
  GETTER_AUTH_USER_ROLE,
  GETTER_AUTH_USER_MANAGEMENT,
  ACTION_APPS_GET_CATEGORIES,
  ACTION_STRATEGY_GEO_GET,
  ACTION_CREATIVES_GET,
} from '@/const';
import StrategyModal from '../Strategies/CreateStrategyModal';
import Table from '../../components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  components: {
    StrategyModal,
    Table,
  },

  data () {
    return {
      newStrategy: {},
      openModal: false,
      formData: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      tableData: GETTER_STRATEGY_CURRENT,
      getColumns: GETTER_STRATEGY_TABLE_COLUMNS,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
      userManagement: GETTER_AUTH_USER_MANAGEMENT,
    }),

    getStatusType () {
      return (item) => {
        switch (item.status) {
        case 'new':
          return 'Turn on';
        case 'paused':
          return 'Turn on';
        case 'active':
          if (['administrator', 'manager'].includes(this.currentUser.role)) {
            return 'Block';
          }
          return 'Turn off';
        case 'blocked':
          return 'Unlock';
        default:
          break;
        }
      };
    },

    getStatusClass () {
      return (item) => {
        const isAgent = this.currentUser.role === 'client';
        switch (item.status) {
        case 'blocked':
          return 'el-icon-unlock';
        case 'new': 
          return 'el-icon-unlock';
        case 'paused':
          if (isAgent) {
            return 'el-icon-video-play';
          }
          return 'el-icon-unlock';
        case 'activate':
          if (isAgent) {
            return 'el-icon-video-pause';
          }
          return 'el-icon-unlock';
        default:
          return 'el-icon-lock';
        }
      };
    },
  },

  async created () {
    this.getNewPage();
    await this.getCategories();
    await this.getGeo();
    await this.allCreatives({
      params: { campaign_id: this.$route.params.id },
      query: { limit: 0 },
    });
  },

  methods: {
    ...mapActions({
      delete: ACTION_STRATEGY_DELETE,
      activate: ACTION_STRATEGY_ACTIVATE,
      pause: ACTION_STRATEGY_PAUSE,
      block: ACTION_STRATEGY_BLOCK,
      copy: ACTION_STRATEGY_COPY,
      fetchStrategies: ACTION_STRATEGY_GET,
      getCategories: ACTION_APPS_GET_CATEGORIES,
      getGeo: ACTION_STRATEGY_GEO_GET,
      allCreatives: ACTION_CREATIVES_GET,
    }),

    createStrategy () {
      this.handleEditAdd(null);
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    handleEditAdd (row) {
      this.openModal = true;
      this.formData = row;
    },

    closeModal () {
      this.openModal = false;
    },

    async getNewPage (page = 1) {
      this.loading = true;
      const campaign_id = parseInt(this.$route.params.id);
      await this.fetchStrategies({
        query: { page },
        params: { campaign_id },
      });
      this.loading = false;
    },

    deleteElement (row){
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        await this.delete({
          params: { id: row.id, campaign_id: parseInt(this.$route.params.id) },
        });
        this.getNewPage(this.tableData.currentPage);
      }).catch(() => {});
    },

    async handleChangeStatus (row) {
      switch (row.status) {
      case 'paused':
        await this.turnOnElement(row);
        break;
      case 'new':
        await this.turnOnElement(row);
        break;
      case 'blocked': 
        await this.pauseElemenet(row);
        break;
      case 'active':
        if (['administrator', 'manager'].includes(this.currentUser.role)) {
          await this.blockElement(row);
        } else {
          await this.pauseElemenet(row);
        }
        break;
      default:
        break;
      }
      this.getNewPage(this.tableData.currentPage);
    },

    async handleCopy (row) {
      await this.copy( { params: { campaign_id: parseInt(this.$route.params.id), id: row.id } } );
      this.getNewPage(this.tableData.currentPage);
    },
    
    async blockElement (row) {
      await this.block( { params: { campaign_id: parseInt(this.$route.params.id), id: row.id } } );
    },
    
    async pauseElemenet (row) {
      await this.pause( { params: { campaign_id: parseInt(this.$route.params.id), id: row.id } } );
    },
    
    async turnOnElement (row) {
      await this.activate( { params: { campaign_id: parseInt(this.$route.params.id), id: row.id } } );
    },

    routeTo (strategy_id) {
      this.$router.push({ name: 'StrategyReport', params: { id: this.$route.params.id, strategy_id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  position: relative;

  .el-table {
    border: 1px solid #EBEEF5;
  }

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 0 0;
  }

  .el-container {
    width: 100%;
    position: absolute;
    height: inherit;
    right: 0;
    left: auto;
    padding: 15px;
  }
}
</style>