<template>
  <div
    :class="framesArr.length && 'wrapper'"
  >
    <div
      style="display: flex; height: calc(100% - 50px);"
    >
      <el-form
        ref="form"
        name="modalForm"
        style="height: 100% !important; max-height: 100%"
        class="demo-modalForm"
        :model="modalForm"
        :disabled="userRole === 'advertiser'"
        :rules="rules"
        label-position="top"
      >
        <h3>Main parameters</h3>
        <el-form-item
          label="Name"
          class="demo-item"
          prop="name"
        >
          <el-input
            v-model="modalForm.name"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          prop="categoryId"
        >
          <el-tree
            ref="tree"
            :props="treePropsCategory"
            :data="catData"
            show-checkbox
            node-key="id"
            :expand-on-click-node="true"
            highlight-current
            @check="handleNodeClick"
          />
        </el-form-item>
        <el-form-item
          label="Click URL"
          prop="landingUrl"
        >
          <el-input
            v-model="modalForm.landingUrl"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <el-form
        :model="framesArr[activeFrameIndex]"
        class="demo-modalForm central-form"
        style="max-height: 100%"
        label-position="top"
      >
        <h3>Screen parameters</h3>
        <el-form-item
          class="item-flex"
        >
          <el-button
            type="primary"
            @click="createFrame"
          >
            Create screen
          </el-button>
        </el-form-item>
        <el-form-item
          ref="customTabs"
          class="custom-tabs"
        >
          <div class="scroll-bar" />
          <div
            class="custom-tabs-items-wrap"
          >
            <el-button-group
              v-for="(item, index) in framesArr"
              :key="item.name"
              class="drag-el drop-zone is-closable el-tabs__nav"
            >
              <div
                class="el-tabs__item is-top custom-tab-item"
                :class="activeFrameName === item.name ? 'active' : ''"
                draggable
                @dragover.prevent
                @dragenter.prevent
                @dragstart="startDrag($event, index)"
                @drop="onDrop($event, index)"
                @click="changeFrame(item, index)"
              >
                {{ item.name }}
                <i
                  class="el-icon-close close-icon"
                  @click.stop="removeFrame(index)"
                />
              </div>
            </el-button-group>
          </div>
        </el-form-item>
        <el-form-item
          label="Screen type"
        >
          <el-select
            v-model="framesArr[activeFrameIndex].type"
            :disabled="!!creativeId && !!framesArr[activeFrameIndex].type"
            @change="changeFrameType"
          >
            <el-option
              v-for="item in frameTypesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <template v-if="framesArr[activeFrameIndex].type">
          <el-form-item
            class="column"
            :label="'Horizontal ' + (framesArr[activeFrameIndex].type === 'ImageCard' ? 'image' : 'video')"
          >
            <el-upload
              ref="upload"
              class="upload-demo"
              :file-list="framesArr[activeFrameIndex].selectedLandscapeFiles"
              :on-change="(file) => handlePreview({ file, type: 'landscape' })"
              :on-preview="openFile"
              action=""
              :before-remove="(file) => beforeRemove({ file , type: 'landscape' })"
              :auto-upload="false"
              :multiple="false"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >
                Select a file
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            class="column"
            :label="'Vertical ' + (framesArr[activeFrameIndex].type === 'ImageCard' ? 'image' : 'video')"
          >
            <el-upload
              ref="upload"
              class="upload-demo"
              :file-list="framesArr[activeFrameIndex].selectedPortraitFiles"
              :on-change="(file) => handlePreview({ file, type: 'portrait' })"
              :on-preview="openFile"
              action=""
              :before-remove="(file) => beforeRemove({ file , type: 'portrait' })"
              :auto-upload="false"
              :multiple="false"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >
                Select file
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="framesArr[activeFrameIndex].type === 'VideoPlayer'"
            class="column"
            label="Logo"
          >
            <el-upload
              ref="upload"
              class="upload-demo"
              :file-list="framesArr[activeFrameIndex].selectedLogoFiles"
              :on-change="(file) => handlePreview({ file , type: 'isVideoLogo' })"
              :on-preview="openFile"
              action=""
              :before-remove="(file) => beforeRemove({ file , type: 'isVideoLogo' })"
              :auto-upload="false"
              :multiple="false"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >
                Select logo
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="framesArr[activeFrameIndex].type === 'ImageCard'"
            class="column"
            label="Promo code"
          >
            <el-upload
              ref="upload"
              class="upload-demo"
              :file-list="framesArr[activeFrameIndex].selectedPromoFiles"
              :on-change="(file) => handlePreview({ file , type: 'isPromoCode' })"
              :on-preview="openFile"
              action=""
              :before-remove="(file) => beforeRemove({ file , type: 'isPromoCode' })"
              :auto-upload="false"
              :multiple="false"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >
                Select promo code
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="Click URL"
          >
            <el-input
              v-model="framesArr[activeFrameIndex].clickUrl"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            label="Impression link time (in milliseconds)"
          >
            <el-input
              v-model="framesArr[activeFrameIndex].impTrackingTimeout"
              type="number"
              autocomplete="off"
            />
          </el-form-item>

          <template v-if="framesArr[activeFrameIndex].type === 'ImageCard'">
            <el-form-item
              label="Call button | Specify phone number"
            >
              <el-input
                v-model="framesArr[activeFrameIndex].buttonCallPhone"
                type="text"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              label="Button go | Click URL"
            >
              <el-input
                v-model="framesArr[activeFrameIndex].buttonClickUrl"
                type="text"
                autocomplete="off"
              />
            </el-form-item>
          </template>
          <el-form-item
            class="fonts"
            label="Pixels"
          />
          <el-form-item
            label="Start"
            prop="start"
          >
            <el-input
              v-model="framesArr[activeFrameIndex].eventTracking.start"
              type="textarea"
              autosize
              autocomplete="off"
            />
          </el-form-item>
          <template v-if="framesArr[activeFrameIndex].type === 'VideoPlayer'">
            <el-form-item
              label="First quartile"
              prop="firstQuartile"
            >
              <el-input
                v-model="framesArr[activeFrameIndex].eventTracking.firstQuartile"
                type="textarea"
                autosize
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              label="Midpoint"
              prop="midpoint"
            >
              <el-input
                v-model="framesArr[activeFrameIndex].eventTracking.midpoint"
                type="textarea"
                autosize
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              label="Third quartile"
              prop="thirdQuartile"
            >
              <el-input
                v-model="framesArr[activeFrameIndex].eventTracking.thirdQuartile"
                type="textarea"
                autosize
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              label="Complete"
              prop="complete"
            >
              <el-input
                v-model="framesArr[activeFrameIndex].eventTracking.complete"
                type="textarea"
                autosize
                autocomplete="off"
              />
            </el-form-item>
          </template>
          <el-form-item
            label="Closing"
            prop="close"
          >
            <el-input
              v-model="framesArr[activeFrameIndex].eventTracking.close"
              type="textarea"
              autosize
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            label="Impression tracking links"
          >
            <el-input
              v-model="framesArr[activeFrameIndex].impTracking"
              type="textarea"
              autosize
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            label="Referral tracking links"
          >
            <el-input
              v-model="framesArr[activeFrameIndex].clickTracking"
              type="textarea"
              autosize
              autocomplete="off"
            />
          </el-form-item>
        </template>
        <h4
          v-else
          style="display: flex; justify-content: center"
        >
          Choose a screen type!
        </h4>
      </el-form>
    </div>
    <div
      class="submit-btn-wrap"
    >
      <el-button
        type="primary"
        @click="submitForm(creativeId ? 'update' : 'create')"
      >
        {{ creativeId ? 'Save' : 'Create' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CREATIVE_GET_FILES,
  GETTER_CREATIVE_ALL_FILES,
  ACTION_CREATIVE_CREATE_FILES,
  ACTION_CREATIVE_CURRENT_GET,
  GETTER_APP_TABLE_CATEGORIES,
} from '@/const';
export default {
  name: 'Promo',
  props: {
    creativeId: {
      type: Number,
      default: null,
    },
    userRole: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      Loading: false,
      activeFrameIndex: 0,
      activeFrameName: '1',
      modalForm: {
        name: '',
        categoryId: null,
        files: [],
        landingUrl: '',
        formatId: null,
      },
      treePropsCategory: {
        label: 'name',
        children: 'children',
      },
      selectedCategory: 'Users Interests',
      maxFrameIndex: 1,
      framesArr: [
        {
          name: '1',
          type: null,
          clickUrl: null,
          impTracking: null,
          impTrackingTimeout: null,
          clickTracking: '',
          eventTracking: {
            start: '',
            firstQuartile: '',
            midpoint: '',
            thirdQuartile: '',
            complete: '',
            close: '',
          },
        },
      ],
      commonFields: {
        type: null,
        clickUrl: null,
        eventTracking: {
          start: '',
          firstQuartile: '',
          midpoint: '',
          thirdQuartile: '',
          complete: '',
          close: '',
        },
        impTracking: null,
        impTrackingTimeout: null,
        clickTracking: null,
      },

      additionalVideoPlayerFields: {
        logoImageUrl: null,
        logoImageFileId: null,
        videoLandscapeUrl: null,
        videoLandscapeFileId: null,
        videoPortraitUrl: null,
        videoPortraitFileId: null,
        selectedLogoFiles: [],
        selectedLandscapeFiles: [],
        selectedPortraitFiles: [],
      },

      additionalImageCardFields: {
        imageLandscapeUrl: null,
        imageLandscapeFileId: null,
        imagePortraitUrl: null,
        imagePortraitFileId: null,
        buttonCallPhone: null,
        buttonClickUrl: null,
        buttonStoreUrl: null,
        buttonStoreFileId: null,
        selectedLandscapeFiles: [],
        selectedPortraitFiles: [],
        selectedPromoFiles: [],
      },

      frameTypesOptions: [
        { value: 'VideoPlayer', label: 'Video player' },
        { value: 'ImageCard',  label: 'Image card' },
      ],

      selectedFilesKeys: {
        promo: {
          files: 'selectedPromoFiles',
          url: 'buttonStoreUrl',
          id: 'buttonStoreFileId',
        },
        videoLogo: {
          files: 'selectedLogoFiles',
          url: 'logoImageUrl',
          id: 'logoImageFileId',
        },
        landscapeImage: {
          files: 'selectedLandscapeFiles',
          url: 'imageLandscapeUrl',
          id: 'imageLandscapeFileId',
        },
        portraitImage: {
          files: 'selectedPortraitFiles',
          url: 'imagePortraitUrl',
          id: 'imagePortraitFileId',
        },
        landscapeVideo: {
          files: 'selectedLandscapeFiles',
          url: 'videoLandscapeUrl',
          id: 'videoLandscapeFileId',
        },
        portraitVideo: {
          files: 'selectedPortraitFiles',
          url: 'videoPortraitUrl',
          id: 'videoPortraitFileId',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: GETTER_APP_TABLE_CATEGORIES,
      allFiles: GETTER_CREATIVE_ALL_FILES,
    }),
    catData () {
      const catData = [{
        name: this.selectedCategory,
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
      }
      for (const [key, value] of Object.entries(this.categories)) {
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },
  },

  async created () {
    this.$emit('setLoad', true);
    if (this.creativeId) {
      const response = await this.currentCreative({
        params: { id: this.$route.params.id, creativeId: this.creativeId },
      });

      const form = response.data || this.modalForm;
      if (form.params && form.params.frames) {
        this.framesArr = form.params.frames.map((frame, index) => {
          frame.name = index + 1 + '';
          frame.impTracking = frame.impTracking.join('\n');
          frame.clickTracking = frame.clickTracking.join('\n');
          frame.eventTracking = frame.eventTracking.reduce((acc, item) => {
            acc[item.event] = acc[item.event] + item.url + '\n';
            return acc;
          }, this.deepClone(this.commonFields.eventTracking));
          const files = Object.values(form.files).map(file => {
            return {
              ...file,
              uid: file.id,
            };
          });
          if (files.length) {
            const type = frame.type === 'ImageCard' ? 'image' : 'video';
            frame.selectedPortraitFiles = frame[type + 'PortraitFileId'] ? [form.files[frame[type + 'PortraitFileId']]] : [];
            frame.selectedLandscapeFiles = frame[type + 'LandscapeFileId'] ? [form.files[frame[type + 'LandscapeFileId']]] : [];
            frame.selectedLogoFiles = type === 'video' && form.files[frame.logoImageFileId] ? [form.files[frame.logoImageFileId]] : [];
            frame.selectedPromoFiles = type === 'image' && form.files[frame.buttonStoreFileId] ? [form.files[frame.buttonStoreFileId]] : [];
          }
          return frame;
        });
      }
      this.handleNodeClick(response.data.category);

      delete form.budget;
      delete form.budgetToday;
      delete form.clicks;
      delete form.clicksToday;
      delete form.created;
      delete form.imps;
      delete form.impsToday;
      delete form.status;
      delete form.updated;
      delete form.lineItems;

      if (form) {
        this.modalForm = {
          ...form,
          categoryId: form.category.id,
          files: Object.keys(response.data.files),
          formatId: form.format.id,
        };
      }

      delete form.format;
      delete form.category;
    }
    this.maxFrameIndex = this.framesArr.length;

    this.$emit('setLoad', false);
  },

  mounted () {
    if (this.data) {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
        this.selectedCategory = this.data.category.name;
        this.$refs.tree.setCheckedKeys([this.data.category.id]);
      });
    }
  },

  methods: {
    ...mapActions({
      currentCreative: ACTION_CREATIVE_CURRENT_GET,
      createFiles: ACTION_CREATIVE_CREATE_FILES,
      allCreativeFiles: ACTION_CREATIVE_GET_FILES,
    }),

    submitForm (action) {
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          this.$emit('submitForm', { action, commonData: this.modalForm, frames: this.framesArr });
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
    },

    startDrag (evt, index) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemIndex', index);
    },

    onDrop (evt, index) {
      const itemIndex = +evt.dataTransfer.getData('itemIndex');
      const item = this.framesArr[itemIndex];
      this.framesArr.splice(itemIndex, 1);
      this.framesArr.splice(index, 0, item);
    },

    createFrame () {
      const commonFields = this.deepClone(this.commonFields);
      const fields = { ...commonFields, name: this.maxFrameIndex + 1 + '' };
      this.framesArr = [...this.framesArr, fields];
      this.maxFrameIndex++;
      this.activeFrameIndex = this.framesArr.length - 1;
      this.activeFrameName = this.framesArr.length + '';
    },

    changeFrame (item, index) {
      this.activeFrameIndex = index;
      this.activeFrameName = item.name;
    },

    removeFrame (index) {
      if (this.framesArr[index].name !== this.activeFrameName) {
        this.framesArr.splice(index, 1);
        if (this.activeFrameIndex > index) {
          this.activeFrameIndex--;
        }
      }
    },

    changeFrameType (value) {
      let additionalFields = value ? this.deepClone(this['additional' + value + 'Fields']) : {};
      const commonFields = this.deepClone(this.commonFields);

      this.$set(this.framesArr, this.activeFrameIndex, {
        ...commonFields,
        ...additionalFields,
        type: value,
        name: this.framesArr[this.activeFrameIndex].name,
      });
    },

    getFileType (type) {
      switch (type) {
      case 'isPromoCode':
        return 'promo';
      case 'isVideoLogo':
        return 'videoLogo';
      case 'landscape':
        return this.framesArr[this.activeFrameIndex].type === 'ImageCard' ?
          'landscapeImage' :'landscapeVideo';
      case 'portrait':
        return this.framesArr[this.activeFrameIndex].type === 'ImageCard' ?
          'portraitImage' : 'portraitVideo';
      default:
        return '';
      }
    },

    async handlePreview ({ file, ...props }) {
      let formData = new FormData();

      formData.append('file', file.raw);
      formData.append('name', file.name);
      this.$emit('setLoad', true);

      const response = await this.createFiles({
        data: formData,
      });
      let fileId = null;
      let fileUrl = null;
      let selectedFile = [];
      if (!response.data.errors) {
        fileId = response.data.id;
        fileUrl = response.data.url;
        selectedFile = [{
          name: file.name,
          url: fileUrl,
          id: fileId,
        }];
        this.modalForm.files.push(fileId);
      } else {
        this.$messageBox.alert(
          'Invalid file!'
        );
      }
      let fileType = this.getFileType(props.type);

      this.$set(this.framesArr, this.activeFrameIndex, { ...this.framesArr[this.activeFrameIndex] });
      this.framesArr[this.activeFrameIndex][this.selectedFilesKeys[fileType].files] = selectedFile;
      this.framesArr[this.activeFrameIndex][this.selectedFilesKeys[fileType].url] = fileUrl;
      this.framesArr[this.activeFrameIndex][this.selectedFilesKeys[fileType].id]  = parseInt(fileId);
      this.$emit('setLoad', false);
    },

    handleNodeClick (data) {
      if (data.id) {
        this.$refs.tree.setCheckedKeys([]);
        this.selectedCategory = data.name;
        this.$refs.tree.setCheckedKeys([data.id]);
        this.modalForm.categoryId = data.id;
      }
    },
    openFile ({ url }) {
      if (this.creativeId) {
        window.open(url);
      }
    },
    beforeRemove (props) {
      return this.$messageBox.confirm(`Delete file: ${ props.file.name } ?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then( async () => {
        this.$emit('setLoad', true);
        this.$emit('pushFileForDelete', props.file.id);

        let fileType = this.getFileType(props.type);

        this.framesArr[this.activeFrameIndex][this.selectedFilesKeys[fileType].files] = [];
        this.framesArr[this.activeFrameIndex][this.selectedFilesKeys[fileType].url] = null;
        this.framesArr[this.activeFrameIndex][this.selectedFilesKeys[fileType].id]  = null;

        this.modalForm.files = this.modalForm.files.filter(file => file.id !== props.file.id);
        this.$emit('setLoad', false);
      });
    },
    deepClone (obj) {
      return JSON.parse(JSON.stringify(obj));
    },
  },
};
</script>
<style lang="scss">
.wrapper {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>