<template>
  <div class="ssp">
    <el-button
      v-if="!onlyRead"
      type="primary"
      @click="handleEditAdd(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="data"
      :column="column"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id "
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo('report', scope.row.id)"
            >
              Reports
            </el-dropdown-item>
            <el-dropdown-item
              v-if="!onlyRead"
              icon="el-icon-edit"
              divided
              @click.native="handleEditAdd(scope.row)"
            >
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              v-if="!onlyRead"
              icon="el-icon-delete"
              @click.native="deleteSspRow(scope.row)"
            >
              Delete
            </el-dropdown-item>
            <el-dropdown-item
              v-if="!onlyRead"
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <SspModal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="openModal=false"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>
import Table from '../../components/Table.vue';
import SspModal from './SspModal';
import {
  GETTER_AUTH_CURRENT_USER,
  GETTER_SSP_GETSSPS,
  GETTER_SSP_SSPTABLE,
  ACTION_SSP_ACTIVATE,
  ACTION_SSP_BLOCK,
  ACTION_SSP_DELETE,
  ACTION_SSP_REPORTS_GET,
  ACTION_SSP_UPDATE,
  ACTION_SSPONE_FETCH,
  ACTION_SSPS_FETCH,
  GETTER_AUTH_USER_ROLE,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  name: 'Ssp',

  components: {
    Table,
    SspModal,
  },

  data () {
    return {
      loading: false,
      page: null,
      openModal: false,
      formData: null,
    };
  },

  computed: {
    ...mapGetters({
      data: GETTER_SSP_GETSSPS,
      column: GETTER_SSP_SSPTABLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
    }),

    onlyRead () {
      return !['administrator', 'manager'].includes(this.userRole);
    },

    getStatusType () {
      return (item) => {
        switch (item.status) {
        case 'new':
          return 'Turn on';
        case 'paused':
          return 'Turn on';
        case 'active':
          return 'Block';
        case 'blocked':
          return 'Unlock';
        default:
          break;
        }
      };
    },

    getStatusClass () {
      return (item) => {
        switch (item.status) {
        case 'blocked':
          return 'el-icon-unlock';
        case 'new':
          return 'el-icon-unlock';
        case 'paused':
          return 'el-icon-video-play';
        case 'activate':
          return 'el-icon-video-pause';
        default:
          return 'el-icon-lock';
        }
      };
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      fetchSsps: ACTION_SSPS_FETCH,
      fetchOneSsp: ACTION_SSPONE_FETCH,
      blockSsp: ACTION_SSP_BLOCK,
      activeSsp: ACTION_SSP_ACTIVATE,
      deleteSsp: ACTION_SSP_DELETE,
      updateSsp: ACTION_SSP_UPDATE,
      getSspReports: ACTION_SSP_REPORTS_GET,
    }),

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    async ssp () {
      await this.fetchSsps({
        params: { campaign_id: this.$route.params.id },
        query: { page: this.page },
      });
    },

    async handleChangeStatus (row) {
      if (['administrator', 'manager'].includes(this.currentUser.role)) {
        switch (row.status) {
        case 'active':
          this.blockSsp({
            params: { id: row.id },
          }).then(response => {
            if (response.data.blocked) {
              this.ssp();
            }
          });
          break;
        default:
          this.activeSsp({
            params: { id: row.id },
          }).then(response => {
            if (response.data.activated) {
              this.ssp();
            }
          });
          break;
        }
      }
    },

    async deleteSspRow (row) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        const { status } = await this.deleteSsp({
          params: {
            id: row.id,
          },
        });
        if (status === 200) {
          await this.fetchSsps({
            params: { campaign_id: this.$route.params.id },
            query: { page: this.page },
          });
        }
      });
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    async getNewPage ( page = 1 ) {
      this.loading = true;
      this.page = page;
      await this.fetchSsps({
        params: { campaign_id: this.$route.params.id },
        query: { page },
      });
      this.loading = false;
    },

    handleEditAdd (row) {
      this.formData = row;
      this.openModal = true;
    },

    routeTo (routeURL, id) {
      switch (routeURL) {
      case 'report':
        this.$router.push({ name: 'SspReport', params: { id } });
        break;
      default:
        break;
      }
    },
    close () {
      this.dialogVisible = false;
    },
  },

};
</script>

<style lang="scss">
.ssp {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>
