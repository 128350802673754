<template>
  <el-dialog
    v-loading="Loading"
    class="creativeModalDialog"
    :title=" creativeId ? 'Creative update' : 'Creative creation'"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    width="100%"
    style="margin: 5% auto; overflow-y: hidden; max-width: 100%"
    :style="(!formatId || formatValue === 'vast' || 'vast-code' || 'banner-code' || 'banner-mraid' || 'performance' || 'html5-rich' || 'html5-overlay') ? 
    { width: '50%' } : { width: '80%' }"
    center
    @close="$emit('close')"
  >
    <Format
      v-if="!formatId"
      :loading="Loading"
      :format-options="formatOptions"
      :user-role="userRole"
      @changeFormatId="(id) => formatId = id"
    />

    <VastVideoForm
      v-else-if="formatValue === 'vast'"
      :creative-id="creativeId"
      :current-creative="currentCreative"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData }) => submitForm(action, commonData)"
    />

    <VastCode 
      v-else-if="formatValue === 'vast-code'" 
      :creative-id="creativeId"
      :current-creative="currentCreative"
      :rules="codeRules"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData }) => submitForm(action, commonData)"
    />
    
    <BannerCode 
      v-else-if="formatValue === 'banner-code'" 
      :creative-id="creativeId"
      :current-creative="currentCreative"
      :rules="codeRules"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData }) => submitForm(action, commonData)"
    />

    <HTML5Rich 
      v-else-if="formatValue === 'html5-rich'" 
      :creative-id="creativeId"
      :current-creative="currentCreative"
      :rules="rules"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData }) => submitForm(action, commonData)"
    />

    <HTML5Overlay
      v-else-if="formatValue === 'html5-overlay'" 
      :creative-id="creativeId"
      :current-creative="currentCreative"
      :rules="rules"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData }) => submitForm(action, commonData)"
    />

    <Promo
      v-else-if="formatValue === 'mraid'"
      :format-id="formatId"
      :creative-id="creativeId"
      :user-role="userRole"
      :rules="rules"
      :data="data"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData, frames }) => submitForm(action, commonData, frames)"
    />

    <Performance
      v-else-if="formatValue === 'performance'"
      :creative-id="creativeId"
      :current-creative="currentCreative"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData }) => submitForm(action, commonData)"
    />
    
    <NewFormat
      v-else-if="formatValue === 'banner-mraid'"
      :format-id="formatId"
      :creative-id="creativeId"
      :user-role="userRole"
      :rules="rules"
      :data="data"
      @setLoad="(val) => Loading = val"
      @pushFileForDelete="(fileId) => pushFileForDelete(fileId)"
      @submitForm="({ action, commonData, frames }) => submitForm(action, commonData, frames)"
    />
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Format from './Format';
import VastVideoForm from './VastVideoForm';
import BannerCode from './BannerCode.vue';
import VastCode from './VastCode.vue';
import Performance from './Performance';
import Promo from './Promo';
import NewFormat from './NewFormat';
import HTML5Rich from './HTML5Rich';
import HTML5Overlay from './HTML5Overlay';

import {
  ACTION_CREATIVE_DELETE_FILES,
  ACTION_CREATIVE_UPDATE,
  ACTION_CREATIVE_CREATE,
  ACTION_CREATIVE_CURRENT_GET,
  ACTION_CREATIVES_GET,
  GETTER_AUTH_USER_ROLE,
  GETTER_APP_TABLE_CATEGORIES,
  ACTION_APPS_GET_CATEGORIES,
  ACTION_FORMATS_GET,
  GETTER_CREATIVE_ALL_FORMATS,
} from '@/const';

export default {
  name: 'CreativeModal',
  components: {
    Promo,
    VastVideoForm,
    BannerCode,
    VastCode,
    Performance,
    Format,
    NewFormat,
    HTML5Rich,
    HTML5Overlay,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    creativeId: {
      type: Number,
      default: null,
    },

    currentPage: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      Loading: false,
      formatId: null,

      currentCreative: {},

      filesForDelete: [],

      rules: {
        name: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        categoryId: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        landingUrl: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
      },
      codeRules: {
        name: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        categoryId: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        code: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        landingUrl: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters ({
      userRole: GETTER_AUTH_USER_ROLE,
      categories: GETTER_APP_TABLE_CATEGORIES,
      formats: GETTER_CREATIVE_ALL_FORMATS,
    }),

    formatValue () {
      const format = this.formatOptions && this.formatOptions.find(f => f.id === this.formatId);
      return format ? format.handler : '';
    },

    formatOptions () {
      let rows = this.formats.rows;
      let res = rows ? rows.map(format => {
        return {
          value: format.name,
          id: format.id,
          handler: format.handler,
        };
      }) : [];
      return res;
    },
  },
  async created () {
    this.Loading = true;
    await this.getAllFormats();
    await this.getCategories();

    if (this.creativeId) {
      const response = await this.getCurrentCreative({
        params: { id: this.$route.params.id, creativeId: this.creativeId },
      });

      const form = response.data;
      if (form.params || form.code) {
        this.formatId = form.format.id;
      } else {
        this.handleOpen();
      }
      this.currentCreative = form;
    }
    this.Loading = false;
  },

  methods: {
    ...mapActions({
      getCategories: ACTION_APPS_GET_CATEGORIES,
      updateCreative: ACTION_CREATIVE_UPDATE,
      createCreative: ACTION_CREATIVE_CREATE,
      getCurrentCreative: ACTION_CREATIVE_CURRENT_GET,
      allCreatives: ACTION_CREATIVES_GET,
      getAllFormats: ACTION_FORMATS_GET,
      deleteFiles: ACTION_CREATIVE_DELETE_FILES,
    }),

    handleOpen (error = [{ msg: 'Something went wrong' }]) {
      this.$messageBox.alert(
        `${
          error.map((item) => {
            return ' ' + item.msg + ' '
            ;})
        }
        `, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    pushFileForDelete (id) {
      this.filesForDelete.push(id);
    },

    reStructureEvents (parent) {
      const tracking = [];
      const keys = ['start', 'firstQuartile', 'midpoint', 'thirdQuartile', 'close', 'complete'];

      keys.forEach(key => {
        const event = parent[key] ? parent[key].trim().split('\n') : null;

        if (event) {
          const arr = this.mapper(event, key);
          arr.map(item => tracking.push(item));
        }
      });
      return tracking;
    },

    filtered (array) {
      return array.filter(item =>  item !== null && item !== '');
    },

    mapper (array, event) {
      let newArray = this.filtered(array);
      return newArray.map(url => {
        if ( url !== '') {
          return {
            event,
            url,
          };
        }
      });
    },

    async getAllCreatives () {
      await this.allCreatives({
        params: { campaign_id: this.$route.params.id },
        query: { page: this.currentPage },
      });
    },

    checkResponse (response) {
      if (response && response.data && response.data.errors) {
        this.handleOpen(response.data.errors);
      } else {
        this.getAllCreatives();
      }
      if (response.status === 200) {
        this.$emit('close');
      }
    },

    async submitForm (action, commonData, frames) {
      this.Loading = true;

      if (this.formatValue === 'vast') {
        const convertStrToArray = (str) => {
          return str ? this.filtered(str.trim().split('\n')).map(item => item)
            : null;
        };
        const {
          videoLandscapeFileId,
          videoLandscapeUrl,
          videoPortraitFileId,
          videoPortraitUrl,
          cta,
          impTracking,
          clickTracking,
          ...events
        } = commonData.params;

        const eventTracking = this.reStructureEvents(events);

        commonData.params = {
          impTracking: convertStrToArray(impTracking),
          clickTracking: convertStrToArray(clickTracking),
          eventTracking,
          cta,
          closeTimeout: commonData.params.closeTimeout ? parseInt(commonData.params.closeTimeout) : null,
          videoLandscapeFileId, videoLandscapeUrl, videoPortraitFileId, videoPortraitUrl,
        };
      } else if (this.formatValue === 'performance') {
        const convertStrToArray = (str) => {
          return str ? this.filtered(str.trim().split('\n')).map(item => item)
            : null;
        };
        const {
          iconFileId,
          iconUrl,
          videoLandscapeFileId,
          videoLandscapeUrl,
          videoPortraitFileId,
          videoPortraitUrl,
          cta,
          storeurl,
          appTitle,
          impTracking,
          clickTracking,
          ...events
        } = commonData.params;

        const eventTracking = this.reStructureEvents(events);

        commonData.params = {
          impTracking: convertStrToArray(impTracking),
          clickTracking: convertStrToArray(clickTracking),
          eventTracking,
          cta,
          storeurl,
          appTitle,
          appRating: commonData.params.appRating ? parseFloat(commonData.params.appRating) : null,
          closeTimeout: commonData.params.closeTimeout ? parseInt(commonData.params.closeTimeout) : null,
          iconFileId,
          iconUrl,
          videoLandscapeFileId,
          videoLandscapeUrl,
          videoPortraitFileId,
          videoPortraitUrl,
        };
      } else if (this.formatValue === 'mraid') {

        const framesWithoutType = frames.filter(frame => !frame.type);
        if (framesWithoutType.length) {
          this.$messageBox.alert(
            'Select types for screens: '
            + framesWithoutType.map(f => f.name).join(', ')
            + '. Either delete them!', 'Error', {
              confirmButtonText: 'Ok',
            });
          this.Loading = false;
          return;
        }

        commonData.params = {
          frames: frames.map(frame => {
            delete frame.name;
            delete frame.selectedLandscapeFiles;
            delete frame.selectedPortraitFiles;
            delete frame.selectedPromoFiles;
            delete frame.selectedLogoFiles;
            return  {
              ...frame,
              clickTracking: frame.clickTracking ? frame.clickTracking.split('\n') : [],
              impTracking: frame.impTracking ? frame.impTracking.split('\n') : [],
              impTrackingTimeout: frame.impTrackingTimeout ? parseInt(frame.impTrackingTimeout) : null,
              eventTracking: this.reStructureEvents(frame.eventTracking),
            };
          }),
        };
      }

      await Promise.all(this.filesForDelete.map(async (fileId) => {
        await this.deleteFiles({
          params: { id: fileId },
        });
      }));
      const data = {
        ...commonData,
        formatId: this.formatId,
      };

      if (action === 'update') {
        this.updateCreative({
          data,
          params: {
            id: this.$route.params.id,
            creativeId: this.creativeId,
          },
        }).then(response => {
          this.checkResponse(response);
        });
      } else if (action === 'create') {
        this.createCreative({
          data,
          params: { campaign_id: this.$route.params.id },
        }).then(response => {
          this.checkResponse(response);
        });
      }
      this.Loading = false;
    },
  },
};
</script>

<style lang="scss">
.creativeModalDialog {
  .el-dialog {
    margin-top: 0 !important;
    height: 100% !important;
    max-width: 100%;

    hr {
      border-top: 1px solid #e4e7ed;
    }

    .el-dialog__body {
      height: inherit !important;
      padding-bottom: 0;

      .central-form {
        width: 67% !important;
        border-right: 1px solid #e4e7ed;
        border-left: 1px solid #e4e7ed;

        .el-autocomplete, .el-input, .el-date-picker, .el-select {
          max-width: 100% !important;
          width: 100% !important;
        }

      }
    }
  }
  .custom-tabs {
      overflow-x: scroll;
    .custom-tabs-items-wrap {
      width: max-content;
      margin-bottom: 5px;
      border-bottom: 1px solid #e4e7ed;

      .custom-tab-item {
        border-top: 1px solid #e4e7ed;
        border-left: 1px solid #e4e7ed;
        border-right: 1px solid #e4e7ed;
        color: #303133 !important;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .close-icon {
          display: none;
        }

        &:hover {
          color: #409EFF !important;
          width: 70px;
          animation-duration: 0.5s;
          animation-name: changeWidth;
          .close-icon {
            display: inline-block;
          }
        }

        @keyframes changeWidth {
          0% {
            width: 50px;
          }
          100% {
            width: 70px;
          }
        }
      }

      & .active {
        color: #409EFF !important;
        margin-bottom: -1px;
        border-bottom: 1px solid #ffffff;
        height: calc(100% + 1px);
        .close-icon {
          display: inline-block;
        }
      }

    }
    &::-webkit-scrollbar:horizontal {
      height: 5px;
      border-radius: 5px;
      background-color: #E7EEF4;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: #409EFF;
      border-radius: 6px;
    }

    .el-form-item__content {
      line-height: 0 !important;
      display: grid;
      align-items: center;

      .scroll-icon {
        padding: 0 5px;
      }
    }
  }

  .submit-btn-wrap {
    display: flex;
    justify-content: center;
  }

  .drop-zone {
    background-color: #eee;
  }

  .drag-el {
    background-color: #fff;
  }

  .el-input {
    white-space: pre-wrap;
  }
  .item-flex {
    display: flex;
    justify-content: flex-end;
  }
  .center {
    display: flex;
    align-items: center;
  }
  .fonts {
    .el-form-item__label {
      line-height: 24px;
      font-size: 18px;
      color: #303133;
    }
  }
  .creative-dialog{
    max-width: 500px;
    position: relative !important;
  }
  .el-form {
    width: 33% !important;
    padding-left: 10px;
  }

  .demo-modalForm {
    overflow-y: scroll !important;
    padding-right: 15px;

    .el-input {
      max-width: 100% !important;
    }
    .el-select {
      max-width: 100% !important;
    }

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 6px;
      background-color: #E7EEF4;;
    }

    &::-webkit-scrollbar-thumb {
      background: #409EFF;
      border-radius: 6px;
    }

    .focusing {
      .el-icon-close-tip {
        color: transparent !important;
      }
    }
  }

  .el-textarea__inner {
    outline: none;
    overflow: hidden;
    min-height: 40px !important;
    padding-top: 8px !important;
  }
}

</style>
