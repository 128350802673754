<template>
  <el-dialog
    :title=" formData ? 'Updating app' : 'Creating app'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="Loading"
      :rules="rules"
      :model="form"
      style="position: inherit!important;"
    >
      <el-form-item
        v-if="isNoPublisher"
        prop="publisherId"
        label="Publisher"
      >
        <el-autocomplete
          v-model="publisherName"
          class="inline-input"
          :fetch-suggestions="querySearch"
          @select="handleSelect"
          @change="changePublisherName"
        />
      </el-form-item>
      <el-form-item
        prop="categoryId"
      >
        <el-tree
          ref="tree"
          :props="treeProps"
          :data="catData"
          show-checkbox
          node-key="id"
          :expand-on-click-node="true"
          highlight-current
          @check="handleNodeClick"
        />
      </el-form-item>
      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="Bundle"
        prop="bundle"
      >
        <el-input
          v-model="form.bundle"
        />
      </el-form-item>
      <el-form-item
        label="Min. price"
      >
        <el-input
          v-model.number="form.bidfloor"
          type="number"
          :disabled="userRole === 'publisher' && !!form.status && form.status !== 'new'"
        />
      </el-form-item>
      <el-form-item
        prop="useInSelfService"
      >
        <el-checkbox
          v-model="form.useInSelfService"
          label="Use in self service"
        />
      </el-form-item>
      <el-form-item
        label="DSP"
        style="margin: 0"
      />
      <el-form-item v-if="allDSP">
        <el-checkbox-group
          v-model="selectedDSP"
        >
          <el-checkbox
            v-for="item of allDSP"
            :key="item.id"
            class="checkbox-dsp"
            :label="item"
          >
            {{ item.name }}
            <el-input
              v-model="item.commission"
              placeholder="DSP - Min. price"
            />
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="secretKey"
        label="Secret key"
      >
        <el-input
          v-model="secretKey"
          type="text"
          :disabled="true"
        />
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData"
          type="primary"
          @click="updateApps"
        >
          Save
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="createApps"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_APPS_CREATE,
  ACTION_APPS_GET,
  ACTION_APPS_ONE,
  ACTION_APPS_UPDATE,
  ACTION_DSP_GET,
  GETTER_APP_GET_ONE,
  GETTER_APP_TABLE_CATEGORIES,
  GETTER_APP_TABLE_DATA,
  GETTER_AUTH_CURRENT_USER,
  GETTER_AUTH_USER_ROLE,
  GETTER_DSP_DATA,
  GETTER_PUBLISHERS_DATA,
} from '@/const';

export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      treeProps: {
        label: 'name',
        children: 'children',
      },
      secretKey: '',
      value: '',
      Loading: false,
      publisherName: '',
      form: {
        publisherId: null,
        name: '',
        bundle: '',
        bidfloor: null,
        categoryId: null,
        useInSelfService: false,
        dsps: null,
      },
      links: [],
      dspBidfloor: null,
      allDSP: null,
      selectedDSP: [],
      selectedCategory: 'Categories',
      rules: {
        name: [
          { required: true, trigger: blur, message: 'Fill in the field' },
        ],
        bundle: [
          { required: true, message: 'Fill in the field' },
        ],
        categoryId: [
          { required: true, trigger: blur, message: 'Fill in the field' },
        ],
        publisherId: [
          { required: true, message: 'Fill in the field' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_DATA,
      categories: GETTER_APP_TABLE_CATEGORIES,
      userRole: GETTER_AUTH_USER_ROLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
      getOneApp: GETTER_APP_GET_ONE,
      getTableData: GETTER_APP_TABLE_DATA,
      getDspData: GETTER_DSP_DATA,
    }),

    isNoPublisher () {
      return this.userRole !== 'publisher';
    },

    catData () {
      const catData = [{
        name: this.selectedCategory,
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
      }
      for (const [key, value] of Object.entries(this.categories)) {
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },
  },

  async created () {
    this.Loading = true;
    await this.fetchDspData({ query: { limit: 0 } });
    const publisherId = this.isNoPublisher ? null : this.currentUser.publishers[0].id;
    this.allDSP = this.getDspData.rows;

    if (this.formData) {
      await this.fetchOneApp({
        params: {
          id: this.formData.id,
        },
      });

      // TODO
      const form = this.getOneApp;

      delete form.updated;
      delete form.created;
      delete form.reports;
      delete form.lineItems;

      this.form = { ...this.form, ...form };
      this.secretKey = form.secretKey;
      this.publisherName = form.publisher.name;
      this.form.publisherId = form.publisher.id;
      this.form.categoryId = form.category.id;
      this.form.useInSelfService = !!form.useInSelfService;
      this.status = form.status;

      const dsp = this.form.dsps ? this.form.dsps : [];

      if (dsp) {
        this.form.dsps = dsp.map(item => {
          return {
            id: item.id,
            bidfloor: item.bidfloor,
          };
        });

        const dspIdArray = [];
        dsp.forEach(dsp => dspIdArray.push(dsp.id));

        let unSelected = [];

        this.allDSP.forEach(item => {
          if(!dspIdArray.includes(item.id)) {
            unSelected.push(item);
          }
        });

        this.selectedDSP = dsp;
        this.allDSP = [...dsp, ...unSelected];
      }
    }

    if (this.isNoPublisher) {
      this.links = this.getPublishersData.rows.map(item => {
        return {
          id: item.id.toString(),
          value: item.name,
        };
      });

    } else if (this.formData) {
      const form = this.formData;

      delete form.updated;
      delete form.created;
      delete form.reports;
      delete form.lineItems;

      this.form = { ...this.form, ...form };
      this.secretKey = form.secretKey;
      this.form.useInSelfService = !!form.useInSelfService;
      this.form.publisherId = publisherId;
    } else {
      this.form.publisherId = publisherId;
    }
    this.Loading = false;
  },

  async mounted () {
    if (this.formData) {
      this.$nextTick(() => {
        this.selectedCategory = this.formData.category.name;
        this.form.useInSelfService = !!this.formData.useInSelfService;
        this.$refs.tree.setCheckedKeys([this.formData.category.id]);
      });
    }
  },

  methods: {
    ...mapActions({
      updateApp: ACTION_APPS_UPDATE,
      createApp: ACTION_APPS_CREATE,
      getApps: ACTION_APPS_GET,
      fetchDspData: ACTION_DSP_GET,
      fetchOneApp: ACTION_APPS_ONE,
    }),

    querySearch (queryString, cb) {
      const links = this.links;
      const results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },

    createFilter (queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    changePublisherName (value) {
      if (!value) {
        this.form.publisherId = null;
      }
    },

    handleSelect (item) {
      this.form.publisherId = parseInt(item.id);
    },

    handleNodeClick (data) {
      if (data.id) {
        this.$refs.tree.setCheckedKeys([]);
        this.selectedCategory = data.name;
        this.$refs.tree.setCheckedKeys([data.id]);
        this.form.categoryId = data.id;
      }
    },

    openMessage (error) {
      this.$messageBox.alert(
        `${error.msg}`, 'Error', {
          confirmButtonText: 'Ok',
        });
    }, 

    async updateApps () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.Loading = true;
          const { currentPage } = this.getTableData;
          const data = {
            ...this.form,
            dsps: this.selectedDSP.map(item => {
              return {
                id: item.id,
                commission: item.commission,
              };
            }),
            useInSelfService: Number(this.form.useInSelfService),
          };

          delete data.reports;
          delete data.lineItems;
          delete data.updated;
          delete data.created;
          delete data.publisher;
          delete data.category;

          const res = await this.updateApp({
            params: { id: this.formData.id },
            data,
          });

          if (res && res.data && res.data.errors) {
            this.openMessage(res.data.errors);
          }

          await this.getApps({
            query: { page: currentPage },
          });

          if (res.status === 200) {
            this.$emit('close');
          }

          this.Loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });

    },

    async createApps () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.Loading = true;
          const { currentPage } = this.getTableData;

          const data = {
            ...this.form,
            dsps: this.selectedDSP.map(item => {

              return {
                id: item.id,
                commission: item.commission,
              };
            }),
            bidfloor: this.form.bidfloor || 0,
            useInSelfService: Number(this.form.useInSelfService),
          };

          const res = await this.createApp({
            data,
          });

          if (res && res.data && res.data.errors) {
            this.openMessage(res.data.errors);
          }
          await this.getApps({
            query: { page: currentPage },
          });

          if (res.status === 200) {
            this.$emit('close');
          }
          this.Loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
    },
  },
};

</script>

<style lang="scss">
.el-form {
  overflow: hidden;
  max-height: 450px;
  padding-right: 15px;

  .checkbox {
    display: flex;
    max-width: 450px;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .el-checkbox__label {
      display: flex;
      // align-items: center;
      flex-direction: column;
      gap: 5px;
    }
  }
  .el-checkbox-group {
    max-height: 200px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .checkbox-dsp {
    position: relative;
    margin: 10px 0;
    .el-input {
      width: 100px !important;
      position: absolute;
      left: auto;
      right: 20px;
      bottom: 1px;
      .el-input__inner {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  ::-webkit-scrollbar {
  width: 5px;
  border-radius: 6px;
  background-color: #E7EEF4;;
  }

  ::-webkit-scrollbar-thumb {
  background: #409EFF;
  border-radius: 6px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}
</style>
