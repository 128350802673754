import {
  URL_CREATIVE_ACTIVATE,
  URL_CREATIVE_BLOCK,
  URL_CREATIVE_PAUSE,
  URL_CREATIVE_DELETE,
  MUTATION_CREATIVE_SET_FILES,
  URL_CREATIVE_DELETE_FILE,
  MUTATION_CREATIVE_SET_REPORTS,
  MUTATION_CREATIVE_SET,
  ACTION_ROOT_REQUEST,
  URL_CREATIVE_CREATE,
  URL_CREATIVE_GET,
  URL_CREATIVE_CREATE_FILE,
  URL_CREATIVE_ALL_FILES,
  URL_CREATIVE_UPDATE,
  URL_CREATIVE_REPORTS,
  URL_CREATIVE_FORMATS,
  MUTATION_CREATIVE_SET_FORMATS,
  URL_CREATIVE_REPORTS_LIST,
  MUTATION_CREATIVE_SET_REPORTS_LIST,
} from '@/const';

const state = {
  creatives: {},
  files: [],
  reports: {},
  formats: [],
  reportsList: {},
};

const getters = {

  allCurrentCreative (state) {
    return state.creatives;
  },

  creativeTable () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 50,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 35,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 100,
      },
      {
        prop: 'category.name',
        label: 'Category',
        width: 100,
      },
      {
        prop: 'format.name',
        label: 'Format',
        width: 100,
      },
      {
        prop: 'impsAll',
        label: 'Imps',
        width: 80,
      },
      {
        prop: 'clicksAll',
        label: 'Clicks',
        width: 80,
      },
      {
        prop: 'budgetAll',
        label: 'Budget',
        width: 80,
      },
    ];
    return columns;
  },

  allFiles (state) {
    return state.files;
  },

  allReports (state) {
    return state.reports;
  },

  getFormats (state) {
    return state.formats;
  },

  reportsList (state) {
    return state.reportsList;
  },
};

const mutations = {
  setAllCreatives (state, response) {
    state.creatives = response;
    state.creatives.rows = response.rows.map((item) => {
      const { imps, impsToday, budget, budgetToday, clicks, clicksToday } = item;
      return {
        ...item,
        impsAll: `${imps || 0} / ${impsToday || 0}`,
        budgetAll: `${budget || 0} / ${budgetToday || 0}`,
        clicksAll: `${clicks || 0} / ${clicksToday || 0}`,
      };
    });
  },

  setFiles (state, files) {
    state.files = Object.values(files);
  },

  createFiles (state, file) {
    state.files.push(file);
  },

  update (state, form) {
    state.creatives.forEach((item, index) => {
      if (item.id === form.id) {
        state.creatives[index] = form;
      }
    });
  },

  delete (state, id) {
    state.creatives = state.creatives.filter(item => item.id !== id);
  },

  create (state, data) {
    state.creatives.push(data);
  },

  pause (state, id) {
    state.creatives.forEach((item, index) => {
      if (item.id === id) {
        state.creatives[index].status = 'paused';
      }
    });
  },

  active (state, id) {
    state.creatives.forEach((item, index) => {
      if (item.id === id) {
        state.creatives[index].status = 'activeted';
      }
    });
  },

  block (state, id) {
    state.creatives.forEach((item, index) => {
      if (item.id === id) {
        state.creatives[index].status = 'blocked';
      }
    });
  },

  setAllReports (state, response) {
    state.reports = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.reports.rows = tableData;
    state.reports.excelData = excelData;
  },

  setFormats (state, response) {
    state.formats = response;
  },

  setReportsList (state, response) {
    state.reportsList = response;
  },
};

const actions = {
  updateCreative: async ({ dispatch }, { data, params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_CREATIVE_UPDATE,
    }, { root: true });
    return response;
  },

  currentCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_CREATIVE_UPDATE,
    }, { root: true });
    return response;
  },

  allCreatives: async ({ dispatch }, { params, query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_CREATIVE_GET,
      commitName: MUTATION_CREATIVE_SET,
    }, { root: true });
    return response;
  },

  deleteCreative: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_CREATIVE_DELETE,
    }, { root: true });
    return response;
  },

  createCreative: async ({ dispatch }, { params, data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_CREATIVE_CREATE,
    }, { root: true });
    return response;
  },

  changeStatusToPause: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_CREATIVE_PAUSE,
    }, { root: true });
    return response;
  },

  changeStatusToBlock: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_CREATIVE_BLOCK,
    }, { root: true });
    return response;
  },

  changeStatusToActive: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_CREATIVE_ACTIVATE,
    }, { root: true });
    return response;
  },

  allCreativeFiles: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_CREATIVE_ALL_FILES,
      commitName: MUTATION_CREATIVE_SET_FILES,
    }, { root: true });
    return response;
  },

  deleteFiles: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_CREATIVE_DELETE_FILE,
    }, { root: true });
    return response;
  },

  createFiles: async ({ dispatch }, { data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_CREATIVE_CREATE_FILE,
    }, { root: true });
    return response;
  },

  getFormats: async ({ dispatch }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_CREATIVE_FORMATS,
      commitName: MUTATION_CREATIVE_SET_FORMATS,
    }, { root: true });
    return response;
  },

  allCreativeReports: async ({ dispatch }, { query, params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      params,
      url: URL_CREATIVE_REPORTS,
      commitName: MUTATION_CREATIVE_SET_REPORTS,
    }, { root: true });
    return response;
  },

  getCreativesReportsList: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_CREATIVE_REPORTS_LIST,
      commitName: MUTATION_CREATIVE_SET_REPORTS_LIST,
    }, { root: true });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
