<template>
  <component :is="currentComponent" />
</template>

<script>
import { GETTER_AUTH_CURRENT_USER, GETTER_AUTH_USER_ROLE, MUTATION_AUTH_SET_BALANCE } from '@/const';
import { mapGetters, mapMutations } from 'vuex';
import Basic from './layout/Basic';
import Empty from './layout/Empty';

export default {
  name: 'App',
  components: {
    Empty,
    Basic,
  },
  data () {
    return {
      empty: ['Registration', 'Verify', 'Restore', 'Login', '404'],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
    }),

    currentComponent () {
      const routeName = this.$route.name;
      let component = '';
      if (this.empty.includes(routeName)) {
        component = 'Empty';
      } else {
        component = 'Basic';
      }
      return component;
    },
  },
  watch: {
    ['currentUser'] (user) {
      if (['agency'].includes(this.userRole)) {
        const balance = user.agencies[0].balance;
        this.setBalance(balance);
      }
    },
  },
  
  methods: {
    ...mapMutations({
      setBalance: MUTATION_AUTH_SET_BALANCE,
    }),
  },
};
</script>

<style lang="scss">
body {
  font-family: "Roboto";
  margin: 0;
  color: #606266;
}
</style>
