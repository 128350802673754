import {
  URL_STRATEGY_CREATE,
  URL_STRATEGIES_UPDATE,
  URL_STRATEGY_BLOCK,
  URL_STRATEGY_ACTIVATE,
  URL_STRATEGY_DELETE,
  URL_STRATEGY_COPY,
  MUTATION_STRATEGY_SET_REPORTS,
  URL_STRATEGY_PAUSE,
  MUTATION_STRATEGY_SET_CURRENT,
  ACTION_ROOT_REQUEST,
  URL_STRATEGIES_GET,
  URL_STRATEGY_APPS_GET,
  MUTATION_STRATEGY_SETONE,
  MUTATION_STRATEGY_APPS_SET,
  URL_STRATEGY_REPORT_GET,
  URL_STRATEGY_GEO_GET,
  URL_STRATEGY_GET,
  MUTATION_STRATEGY_SET_GEO,
  URL_STRATEGY_REPORTS_LIST_GET,
  MUTATION_STRATEGY_SET_REPORTS_LIST,
  URL_STRATEGY_DEVICE_TYPES,
  MUTATION_STRATEGY_SET_DEVICE_TYPES,
  URL_STRATEGY_OSES,
  MUTATION_STRATEGY_SET_OSES, URL_STRATEGY_LANGUAGES, MUTATION_STRATEGY_SET_LANGUAGES,
} from '@/const';
import moment from 'moment';

const state = {
  strategies: {},
  reports: {},
  geography: [],
  deviceTypes: [],
  oses: [],
  languages: [],
  apps: [],
  strategy: null,
  reportsList: {},
};

const getters = {
  getCurrentStrategies (state) {
    return state.strategies;
  },

  getCurrentReport (state) {
    return state.reports;
  },

  getGeo (state) {
    return state.geography;
  },

  getDeviceTypes (state) {
    return state.deviceTypes;
  },
  
  getOses (state) {
    return state.oses;
  },

  getLanguages (state){
    return state.languages;
  },

  getStrategy (state) {
    return state.strategy;
  },

  getApps (state) {
    return state.apps;
  },

  getReportsList (state) {
    return state.reportsList;
  },

  getColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 70,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: 'limitPrice',
        label: 'Price',
        width: 150,
      },
      {
        prop: 'limitDelivery',
        label: 'Delivery',
        width: 150,
      },
      {
        prop: 'start_end',
        label: 'Start/End',
        width: 150,
      },
      {
        prop: 'imps',
        label: 'Imps',
        width: 150,
      },
      {
        prop: 'clicks',
        label: 'Clicks',
        width: 150,
      },
      {
        prop: 'budget',
        label: 'Budget',
        width: 150,
      },
    ];
    return columns;
  },
};

const mutations = {
  setCurrentStrategies: (state, data) =>  {
    state.strategies = data;
    let newData = [];

    for ( const value of Object.values(state.strategies.rows)) {
      let { limitDateStart, limitDateEnd } = value;
      limitDateStart = limitDateStart ? limitDateStart : null;
      limitDateEnd = limitDateEnd ? limitDateEnd : null;


      newData.push({
        ...value,
        start_end: `${limitDateStart ? moment(value.limitDateStart).format('YYYY-MM-DD') : ''} / ${limitDateEnd ? moment(value.limitDateEnd).format('YYYY-MM-DD') : ''}`,
        clicks: `${value.clicks || 0} / ${value.clicksToday || 0}`,
        budget: `${value.budget || 0} / ${value.budgetToday || 0}`,
        imps: `${value.imps || 0} / ${value.impsToday || 0}`,
      });

    }
    state.strategies.rows = newData;
  },

  setOne (state, response) {
    state.strategy = response;
  },

  setCurrentStrategyReports (state, response) {
    state.reports = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.reports.rows = tableData;
    state.reports.excelData = excelData;
  },

  setGeo (state, response) {
    state.geography = response;
  },
  
  setDeviceTypes (state, response) {
    state.deviceTypes = response;
  },
  
  setOses (state, response) {
    state.oses = response;
  },

  setLanguages (state, response) {
    state.languages = response;
  },

  setApps (state, response) {
    state.apps = response;
  },

  setStrategyReportsList (state, response) {
    state.reportsList = response;
  },
};

const actions = {
  fetchStrategies: async ({ dispatch }, { query, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      params,
      url: URL_STRATEGIES_GET,
      commitName: MUTATION_STRATEGY_SET_CURRENT,
    }, { root: true });
    return res;
  },

  updateStrategy: async ({ dispatch }, { data, params }) => {
    if('forbiddenMacPools' in data){
      delete data.forbiddenMacPools;
    }
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_STRATEGIES_UPDATE,
    }, { root: true });
    return res;
  },

  getStrategy: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_STRATEGY_GET,
      commitName: MUTATION_STRATEGY_SETONE,
    }, { root: true });
    return res;
  },

  getGeo: async ({ dispatch }, val) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_STRATEGY_GEO_GET + `${val}`,
      commitName: MUTATION_STRATEGY_SET_GEO,
    }, { root: true });
    return res;
  },
  
  fetchDeviceTypes: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_STRATEGY_DEVICE_TYPES,
      commitName: MUTATION_STRATEGY_SET_DEVICE_TYPES,
    }, { root: true });
    return res;
  },
  
  fetchOses: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_STRATEGY_OSES,
      commitName: MUTATION_STRATEGY_SET_OSES,
    }, { root: true });
    return res;
  },

  fetchLanguages: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_STRATEGY_LANGUAGES,
      commitName: MUTATION_STRATEGY_SET_LANGUAGES,
    }, { root: true });
    return res;
  },

  createStrategy: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_STRATEGY_CREATE,
    }, { root: true });
    return res;
  },

  deleteStrategy: async ({ dispatch }, { params }) => {
    const data = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_STRATEGY_DELETE,
    }, { root: true });
    return data;
  },

  activateStrategy: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_STRATEGY_ACTIVATE,
    }, { root: true });
    return res;
  },

  blockStrategy: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_STRATEGY_BLOCK,
    }, { root: true });
    return res;
  },

  pauseStrategy: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_STRATEGY_PAUSE,
    }, { root: true });
    return res;
  },

  copyStrategy: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      params,
      url: URL_STRATEGY_COPY,
    }, { root: true });
    return res;
  },

  fetchReports: async ({ dispatch }, { query, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      params,
      url: URL_STRATEGY_REPORT_GET,
      commitName: MUTATION_STRATEGY_SET_REPORTS,
    }, { root: true });
    return res;
  },

  fetchReportsList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_STRATEGY_REPORTS_LIST_GET,
      commitName: MUTATION_STRATEGY_SET_REPORTS_LIST,
    }, { root: true });
    return res;
  },

  fetchApps: async ({ dispatch }, query) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_STRATEGY_APPS_GET + `${query}`,
      commitName: MUTATION_STRATEGY_APPS_SET,
    }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
