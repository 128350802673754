import { 
  URL_USERS_GET_CREATE, 
  MUTATION_USERS_SET, 
  ACTION_ROOT_REQUEST, 
  URL_USERS_GET_UPDATE,
  URL_USERS_ACTIVATE,
  URL_USERS_BLOCK,
  URL_USERS_GET_CURRENT,
  URL_USERS_DELETE_CURRENT,
} from '@/const';

const state = {
  usersData: {},
};

const getters = {
  getUsersData (state) {
    return state.usersData;
  },

  getUsersColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 35,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 35,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 35,
      },
      {
        prop: 'email',
        label: 'Mail',
        width: 100,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 100,
      },
      {
        prop: 'role',
        label: 'Role',
        width: 100,
      },
      {
        prop: 'company',
        label: 'Company',
        width: 100,
        buttons: true,
      },
    ];
    return columns;
  },
};

const mutations = {
  setUsers (state, users) {
    state.usersData = users;
    state.usersData.rows = users.rows.map(item => {
      const company = item.agencies.map(item => `Agency: ${item.name}`).join('\n') || 'No data';
      return {
        ...item,
        company,
      };
    });
  },

  addUser (state, user) {
    state.usersData.push(user);
  },
};

const actions = {
  getAllUsers: async ({ dispatch }, { query={} }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_USERS_GET_CREATE,
      commitName: MUTATION_USERS_SET,
    }, { root: true });
    return res;
  },

  getUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_USERS_GET_CURRENT,
    }, { root: true });
    return res;
  },

  createUser: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_USERS_GET_CREATE,
    }, { root: true });
    return res;
  },

  updateUser: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_USERS_GET_UPDATE,
      // commitName: MUTATION_USERS_UPDATE,
    }, { root: true });
    return res;
  },

  deleteUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_USERS_DELETE_CURRENT,
    }, { root: true });
    return res;
  },

  activateUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_USERS_ACTIVATE,
    }, { root: true });
    return res;
  },

  blockUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_USERS_BLOCK,
      // commitName: MUTATION_USERS_BLOCK,
    }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};