import { 
  URL_AGENCIES_GET_POST, 
  MUTATION_AGENCIES_SET, 
  ACTION_ROOT_REQUEST, 
  URL_AGENCIES_DELETE_PUT, 
  MUTATION_AGENCIES_USERS_SET,
  URL_AGENCIES_USERS_GET_POST,
  URL_AGENCIES_USERS_DELETE,
} from '@/const';

const state = {
  agenciesData: {},
  columns: [
    {
      prop: 'options',
      label: '',
      width: 40,
      buttons: true,
    },
    {
      prop: 'id',
      label: 'ID',
      width: 35,
    },
    {
      prop: 'name',
      label: 'Name',
      width: 100,
    },
    {
      prop: 'emailName',
      label: 'Owner',
      width: 100,
    },
    {
      prop: 'userCount',
      label: 'Number of users',
      width: 100,
    },
    {
      prop: 'balance',
      label: 'Balance',
      width: 50,
    },
    {
      prop: 'commission',
      label: 'Commission',
      width: 100,
    },
    {
      prop: 'budgetType',
      label: 'Budget Type',
      width: 55,
    },
    {
      prop: 'management',
      label: 'Management',
      width: 100,
    },
  ],
  userColumns: [
    {
      prop: 'options',
      label: '',
      width: 40,
      buttons: true,
    },
    {
      prop: 'id',
      label: 'ID',
      width: 35,
    },
    {
      prop: 'email',
      label: 'Mail',
      width: 100,
    },
    {
      prop: 'name',
      label: 'Username',
      width: 100,
    },
    {
      prop: 'status',
      label: 'Status',
      width: 70,
      buttons: true,
    },
  ],
  agenciesUsers: {
    rows: [],
    page: null,
    limitPerPage: null,
  },
};

const getters = {
  getAgenciesData (state) {
    if (state.agenciesData.rows && state.agenciesData.rows.length) {
      state.agenciesData.rows = state.agenciesData.rows.map(item => {
        return {
          ...item,
          emailName: `${item.owner.name} | ${item.owner.email}`,
        };
      });
    }
    return state.agenciesData;
  },

  getAgenciesUser (state) {
    return state.agenciesUsers;
  },

  getAgenciesColumns (state) {
    return state.columns;
  },

  getAgenciesUsersColumns (state) {
    return state.userColumns;
  },
};

const mutations = {
  setAgencies (state, data) {
    state.agenciesData = data;
  },
  
  setAgenciesUser (state, data) {
    state.agenciesUsers.rows = Object.values(data.rows);
  },
};

const actions = {
  fetchAllAgencies: async ({ dispatch }, { query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_AGENCIES_GET_POST,
      commitName: MUTATION_AGENCIES_SET,
    }, { root: true });
    return response.data;
  },

  fetchAgency: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_AGENCIES_DELETE_PUT,
    }, { root: true });
    return response;
  },

  fetchAllUsers: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_AGENCIES_USERS_GET_POST,
      commitName: MUTATION_AGENCIES_USERS_SET,
    }, { root: true });
    return res;
  },

  removeAgency: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_AGENCIES_DELETE_PUT,
    }, { root: true });
    return response;
  },

  updateAgency: async ({ dispatch }, { data, params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_AGENCIES_DELETE_PUT,
    }, { root: true });
    return response;
  },

  createAgency: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_AGENCIES_GET_POST,
    }, { root: true });
    return res;
  },
  
  addNewAgenciesUsers: async ({ dispatch }, { data, params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_AGENCIES_USERS_GET_POST,
    }, { root: true });
    return response.data;
  },

  removeAgenciesUsers: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_AGENCIES_USERS_DELETE,
    }, { root: true });
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};