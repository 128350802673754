<template>
  <el-container>
    <el-dialog
      :title=" id ? 'Advertiser updating' : 'Advertiser creating'"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form
        v-loading="loading"
        style="position: inherit!important;"
        :model="modalForm"
        name="modalForm" 
        class="demo-modalForm"
      >
        <el-form-item 
          label="Name"
          class="demo-item"
        >
          <el-input 
            v-model="modalForm.name" 
            type="text" 
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          v-if="showOwner"
          label="Owner"
        >
          <el-autocomplete
            v-model="modalForm.owner"
            :fetch-suggestions="querySearch"
            @select="(item) => (metaForm.ownerId = item.id)"
          >
            <i
              slot="suffix"
              class="el-icon-edit el-input__icon"
            />
            <template slot-scope="{ item }">
              <div class="value"> 
                {{ item.value }} 
              </div>
              <span class="link">{{ item.link }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          v-if="userRole !== 'agency'"
          label="Agency"
          prop="agency"
        >
          <el-autocomplete
            v-model="modalForm.agency"
            class="inline-input"
            :fetch-suggestions="querySearchAgency"
            @select="(item) => (metaForm.agencyId = item.id)"
          />
        </el-form-item>
        <el-form-item
          class="item-flex"
        >
          <el-button 
            v-if="id"
            type="primary"
            @click="submitForm('update')"
          >
            Save
          </el-button>
          <el-button 
            v-else
            type="primary"
            @click="submitForm('create')"
          >
            Create
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import {
  GETTER_ADVERTISERS_DATA,
  ACTION_USERS_GET_USERS,
  ACTION_ADVERTISERS_CREATE,
  ACTION_ADVERTISERS_UPDATE,
  ACTION_AGENCIES_GET,
  ACTION_ADVERTISERS_GET,
  GETTER_AUTH_USER_MANAGEMENT,
  GETTER_AUTH_USER_ROLE,
  GETTER_AGENCIES_DATA,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'AdvertisersModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      metaForm: {
        ownerId: null,
        agencyId: null,
      },
      modalForm: {
        name: '',
        owner: null,
        agency: '',
      },
      users: [],
    };
  },
  validations: {
    modalForm: {
      name: {
        required,
        min: minLength(6),
      },
    },
  },
  computed: {
    ...mapGetters({
      getAdvertisersData: GETTER_ADVERTISERS_DATA,
      userManagement: GETTER_AUTH_USER_MANAGEMENT,
      userRole: GETTER_AUTH_USER_ROLE,
      getAgenciesData: GETTER_AGENCIES_DATA,
    }),

    showOwner () {
      return this.userRole === 'agency' ? !['full', 'limited'].includes(this.userManagement) : true;
    },

    agencyLinks () {
      return this.getAgenciesData.rows.map(item => {
        return {
          id: item.id,
          value: item.name,
        };
      });
    },
  },

  watch: {
    ['modalForm.owner'] (newValue) { this.validateSelect(newValue, 'ownerId'); },
    ['modalForm.agency'] (newValue) { this.validateSelect(newValue, 'agencyId'); },
  },

  async created () {


    this.loading = true;
    await this.fetchAllAgencies({ query: { limit: 0 } });
    if (this.showOwner) {
      const res = await this.getAllUsers({
        query: { limit: 0 },
      });

      this.users = res.data.rows.map(user => {
        return {
          id: user.id,
          value: `${user.name} | ${user.email}`,
          email: user.email,
        };
      });
    }

    if (this.id) {
      this.getAdvertisersData.rows.forEach(item => {
        if (this.id === item.id) {
          this.modalForm.name = item.name;
          this.modalForm.owner = item.owner;
          this.modalForm.agency = 'agency' in item ? item.agency.name : null;

          this.metaForm.ownerId = item.ownerId;
          this.metaForm.agencyId = 'agency' in item ? item.agency.id : null;
        }
      });
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      updateAdvertiser: ACTION_ADVERTISERS_UPDATE,
      createAdvertiser: ACTION_ADVERTISERS_CREATE,
      fetchAllAdvertisers: ACTION_ADVERTISERS_GET,
      getAllUsers: ACTION_USERS_GET_USERS,
      fetchAllAgencies: ACTION_AGENCIES_GET,
    }),

    validateSelect (updatedValue, metaKey) {
      this.metaForm[metaKey] = updatedValue.trim() ? this.metaForm[metaKey] : null;
    },

    querySearchAgency (queryString, cb) {
      const options = this.agencyLinks;
      const results = queryString ? options.filter(this.createAgencyFilter(queryString)) : options;
      cb(results);
    },


    createAgencyFilter (queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    // TODO
    querySearch (queryString, cb) {
      const users = this.users;
      const results = queryString ? users.filter(this.createFilter(queryString)) : users;
      cb(results);
    },

    createFilter (queryString) {
      return (user) => {
        return (
          user.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
          user.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleOpen (error = [{ msg: 'Something went wrong' }, { msg: 'Something went wrong' }]) {
      const message = error.map((item) => ' ' + item.msg + ' ').join(' | ');
      const title = 'Error';
      const options = { confirmButtonText: 'Ok' };
      this.$messageBox.alert(message, title, options);
      this.loading = false;
    },

    async submitForm (action) {
      if (this.modalForm.name) {
        this.loading = true;
        const { currentPage } = this.getAdvertisersData;
        switch (action) {
        case 'update':
          await this.updateAdvertiser({
            params: { id: this.id },
            data: this.showOwner ? { name: this.modalForm.name, ownerId: this.metaForm.ownerId, agencyId: this.metaForm.agencyId } : { name: this.modalForm.name },
          }).then(response => {
            if (response.data.errors) {
              this.handleOpen(response.data.errors);
            } else {
              this.loading = false;
            }
            if (response.status === 200) {
              this.$emit('close');
            }
          });
          this.fetchAllAdvertisers({
            query: { page: currentPage },
          });
          break;
        case 'create':
          if(!this.modalForm.name) {
            this.$messageBox.alert(
              'Fill in all the fields'
            );
            this.loading = false;
          } else {
            const getData = (form) => {
              const data = { name: form.name };
              if (!!form.owner && !!form.ownerId) {
                data.ownerId = form.ownerId;
              }
              if (form.agencyId) {
                data.agencyId = form.agencyId;
              }
              return data;
            };
            await this.createAdvertiser({
              data: getData({ ...this.modalForm, ...this.metaForm }),
            }).then(res => {
              if (res.data.errors) {
                this.handleOpen(res.data.errors);
              } else {
                this.loading = false;
              }
              if (res.status === 200) {
                this.$emit('close');
              }
            });
            this.fetchAllAdvertisers({
              query: { page: currentPage },
            });
          }
          break;
        default:
          break;
        }
      }  else {
        this.$messageBox.alert(
          'Fill in all required fields'
        );
      }
    },
  },
};
</script>