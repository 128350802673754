import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage';
import NotFound from '../views/NotFound';
import Login from '../views/Authentication/Login.vue';
import Restore from '../views/Authentication/Restore.vue';
import Register from '../views/Authentication/Register';
import Verify from '../views/Authentication/Verify';
import Campaigns from '../views/Campaigns';
import CampaignsReport from '../views/Campaigns/CampaignsReport';
import StrategyTable from '../views/Strategies/StategiesTable';
import StrategyReports from '../views/Strategies/StrategyReports';
import Edit from '../views/Authentication/Edit';
import Creative from '../views/Creative';
import CreativeReport from '../views/Creative/CreativeReport.vue';
import SystemReport from '../views/SystemReports';
import Transactions from '../views/Billing/Transactions';
import Orders from '../views/Billing/Orders';
import Apps from '../views/Apps/index';
import AppsReport from '../views/Apps/AppsReport';
import Agencies from '../views/Settings/_Agencies';
import Advertisers from '../views/Settings/_Advertisers';
import Publishers from '../views/Settings/_Publishers';
import Users from '../views/Settings/_Users';
import AgencyUsers from '../views/Settings/_Agencies/agencyUsers.vue';
import AdvertisersUsers from '../views/Settings/_Advertisers/AdvertisersUsers.vue';
import PublishersUsers from '../views/Settings/_Publishers/PublishersUsers.vue';
import Dsp from '../views/Dsp';
import CreativeDsp from '../views/DspCreative/index.vue';
import Ssp from '../views/Ssp/index.vue';
import SspReport from '../views/Ssp/SspReport';

import store from '../store';
import DspReport from '@/views/Dsp/DspReport';
import AppsList from '../views/Settings/_AppsList';
import DomainsList from '../views/Settings/_DomainsList';
import IpAddressesList from '../views/Settings/_IpAddressesList';
import MacAddressList from '../views/Settings/_MacAddressList';


Vue.use(VueRouter);

const title = 'Green Grey';

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home',
    },
    component: HomePage,
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Sign In',
    },
    component: Login,
  },
  {
    path: '/restore',
    name: 'Restore',
    meta: {
      title: 'Restore password',
    },
    component: Restore,
  },
  {
    path: '/register',
    name: 'Registration',
    meta: {
      title: 'Sign Up',
    },
    component: Register,
  },
  {
    path: '/confirm-email',
    name: 'Verify',
    meta: {
      title: 'Confirm email',
    },
    component: Verify,
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    meta: {
      title: 'Campaigns',
    },
    component: Campaigns,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/campaigns/:id/report',
    name: 'CampaignsReport',
    meta: {
      title: 'Campaign Reports',
      routeParent: 'campaigns',
    },
    component: CampaignsReport,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/apps/:id/report',
    name: 'AppsReport',
    meta: {
      title: 'App Reports',
      routeParent: 'apps',
    },
    component: AppsReport,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/campaigns/:id/strategy',
    name: 'CampaignsStrategy',
    meta: {
      title: 'Strategies',
      routeParent: 'campaigns',
    },
    component: StrategyTable,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/campaigns/:id/strategy/:strategy_id/report',
    name: 'StrategyReport',
    meta: {
      title: 'Strategy Reports',
      routeParent: 'campaigns',
    },
    component: StrategyReports,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/campaigns/:id/creative',
    name: 'CampaignsCreative',
    meta: {
      title: 'Creatives',
      routeParent: 'campaigns',
    },
    component: Creative,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    name: 'CreativeReport',
    path: '/campaigns/:id/creative/:creative_id/report',
    meta: {
      title: 'Creative Reports',
      routeParent: 'campaigns',
    },
    component: CreativeReport,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/apps',
    name: 'Apps',
    meta: {
      title: 'Apps',
    },
    component: Apps,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/appsList',
    name: 'AppsList',
    meta: {
      title: 'Apps list',
    },
    component: AppsList,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/ipAddressesList',
    name: 'IpAddressesList',
    meta: {
      title: 'IP-addresses list',
    },
    component: IpAddressesList,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/domainsList',
    name: 'DomainsList',
    meta: {
      title: 'Domains list',
    },
    component: DomainsList,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/macAddressList',
    name: 'MacAddressList',
    meta: {
      title: 'Mac-addresses list',
    },
    component: MacAddressList,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/agencies',
    name: 'Agencies',
    meta: {
      title: 'Agencies',
      parent: 'Settings',
    },
    component: Agencies,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/agencies/:id/users',
    name: 'AgencyUsers',
    meta: {
      title: 'Agency users',
      parent: 'Settings',
      routeParent: 'agencies',
    },
    component: AgencyUsers,
  },
  {
    path: '/advertisers',
    name: 'Advertisers',
    meta: {
      title: 'Advertisers',
      parent: 'Settings',
    },
    component: Advertisers,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/advertisers/:id/users',
    name: 'AdvertisersUsers',
    meta: {
      title: 'Advertiser users',
      parent: 'Settings',
      routeParent: 'advertisers',
    },
    component: AdvertisersUsers,
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      title: 'Users',
      parent: 'Settings',
    },
    component: Users,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/publishers',
    name: 'Publishers',
    meta: {
      title: 'Publishers',
      parent: 'Settings',
    },
    component: Publishers,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/publishers/:id/users',
    name: 'PublishersUsers',
    meta: {
      title: 'Publisher Users',
      parent: 'Settings',
      routeParent: 'publishers',
    },
    component: PublishersUsers,
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Profile',
    },
    component: Edit,
  },
  {
    path: '/reports',
    name: 'Report',
    meta: {
      title: 'Reports',
      access: ['admin', 'manager'],
    },
    component: SystemReport,
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      title: 'Transactions',
      parent: 'Billing',
    },
    component: Transactions,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {
      title: 'Orders',
      parent: 'Billing',
    },
    component: Orders,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/dspcreative',
    name: 'CreativeDsp',
    meta: {
      title: 'DSP creatives',
    },
    component: CreativeDsp,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/ssp',
    name: 'SSP',
    meta: {
      title: 'SSP management',
      routeParent: 'campaigns',
    },
    component: Ssp,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/ssp/:id/report',
    name: 'SspReport',
    meta: {
      title: 'SSP reports',
    },
    component: SspReport,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/dsp',
    name: 'DSP',
    meta: {
      title: 'DSP management',
    },
    component: Dsp,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/dsp/:id/report',
    name: 'DspReport',
    meta: {
      title: 'DSP reports',
    },
    component: DspReport,
    beforeEnter: (to, from, next) => {
      checkRole(to, from, next);
    },
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not found',
    },
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const checkRole = async (to, from, next) => {
  const hasUserData = store.state.auth.user;
  const path = '/' + to.path.split('/')[1];

  if (!hasUserData) {
    await store.dispatch('auth/getCurrentUser');
  }

  const hasAccess = store.getters['auth/getUserAccess'].find(item => {
    if (!item.subMenu) {
      return item.route === path;
    }
    return item.subMenu.find(val => val.route === path);
  });

  if (!hasAccess) {
    router.push({ name: 'Home' });
  } else next();
};

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
});
router.afterEach((to) => {
  Vue.nextTick(() =>  {
    document.title = title + ' | ' + to.meta.title;
  });
});

router.beforeEach((to, from, next) => {
  const accessRoutes = ['Restore', 'Verify', 'Registration', 'Login'];
  if (!localStorage.getItem('token') && !accessRoutes.includes(to.name) ) {
    router.push({ name: 'Login' }).catch(() => {});
  } else next();
});

export default router;
