import {
  ACTION_ROOT_REQUEST,
  URL_DSP_GET,
  URL_DSP_CREATE,
  URL_DSP_UPDATE,
  URL_DSP_DELETE,
  MUTATION_DSP_SET,
  URL_DSP_BLOCK,
  URL_DSP_ACTIVATE,
  MUTATION_DSP_SET_ONE,
  GETTER_AUTH_USER_ROLE,
  MUTATION_DSP_SET_REPORTS,
  MUTATION_DSP_SET_REPORTS_LIST,
  URL_DSP_CURRENCIES_GET,
  MUTATION_DSP_CURRENCIES_SET,
  URL_DSP_REPORTS_LIST_GET,
  URL_DSP_REPORTS_GET,
} from '../../const';

const state = {
  dspTableColumns: [
    {
      prop: 'options',
      label: '',
      width: 40,
      buttons: true,
    },
    {
      prop: 'status',
      label: 'Status',
      width: 30,
      buttons: true,
    },
    {
      prop: 'id',
      label: 'ID',
      width: 40,
    },
    {
      prop: 'name',
      label: 'Name',
      width: 150,
    },
    {
      prop: 'bidfloor',
      label: 'Max bidfloor',
      width: 70,
    },
    {
      prop: 'commission',
      label: 'Commission',
      width: 70,
    },
    {
      prop: 'moderation',
      label: 'Moderation',
      width: 120,
    },
    {
      prop: 'reqsToday',
      label: 'Requests',
      width: 50,
    },
    {
      prop: 'bidsToday',
      label: 'Bids',
      width: 50,
    },
    {
      prop: 'impsToday',
      label: 'Imps',
      width: 50,
    },
    {
      prop: 'clicksToday',
      label: 'Clicks',
      width: 50,
    },
  ],
  dspData: {},
  oneDsp: {},
  dspReportsList: {},
  reports: {},
  currencies: [],
};

const getters = {

  getDspColumn (state, getters, rootState, rootGetters) {
    let columns = state.dspTableColumns;
    let role = rootGetters[GETTER_AUTH_USER_ROLE];
    if ('publisher' === role) {
      columns = state.dspTableColumns.filter(i => ['id', 'name', 'status'].includes(i.prop));
    }
    return columns;
  },

  getDspReports (state) {
    return state.reports;
  },

  getDspReportsList (state) {
    return state.dspReportsList;
  },

  getDspData (state) {
    return state.dspData;
  },

  getOneDsp (state) {
    return state.oneDsp;
  },

  getCurrencies (state) {
    return state.currencies;
  },
};

const mutations = {
  
  setDspData (state, response) {
    state.dspData = response;
  },

  setOneDsp (state, response) {
    state.oneDsp = response;
  },

  setReports (state, response) {
    state.reports = response;
  },

  setDspReportsList (state, response) {
    state.dspReportsList = response;
  },

  setDspReport (state, response) {
    state.reports = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.reports.rows = tableData;
    state.reports.excelData = excelData;
  },

  setCurrencies (state, response) {
    state.currencies = response.rows;
  },
};

const actions = {
  fethcDspData: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_DSP_GET,
      query,
      commitName: MUTATION_DSP_SET,
    }, { root: true });
    return res;
  },

  fetchOneDsp: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_DSP_UPDATE,
      params,
      commitName: MUTATION_DSP_SET_ONE,
    }, { root: true });
    return res;
  },

  createDsp: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_DSP_CREATE,
    }, { root: true });
    return res;
  },

  updateDsp: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_DSP_UPDATE,
    }, { root: true });
    return res;
  },

  deleteDsp: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_DSP_DELETE,
    }, { root: true });
    return res;
  },

  activateDsp: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_DSP_ACTIVATE,
    }, { root: true });
    return res;
  },

  blockDsp: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_DSP_BLOCK,
    }, { root: true });
    return res;
  },

  fetchDspReports: async ({ dispatch }, { params, query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_DSP_REPORTS_GET,
      commitName: MUTATION_DSP_SET_REPORTS,
    }, { root: true });
    return response;
  },

  fetchDspReportsList: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_DSP_REPORTS_LIST_GET,
      commitName: MUTATION_DSP_SET_REPORTS_LIST,
    }, { root: true });
    return response;
  },

  getCurrencies: async ({ dispatch }, { query, params } = {}) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_DSP_CURRENCIES_GET,
      commitName: MUTATION_DSP_CURRENCIES_SET,
    }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
