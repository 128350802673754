<template>
  <el-dialog
    :title="formData ? 'Updating strategy' : 'Creating strategy'"
    :visible="openModal"
    :close-on-click-modal="false"
    class="strategyDialog"
    width="500px"
    style="margin: -5% auto; overflow-y: hidden; max-width: 100%"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="loading"
      style="position: inherit !important"
      :model="form"
      :rules="rules"
      :disabled="userRole === 'advertiser'"
    >
      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input
          v-model="form.name"
          type="text"
        />
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        label="Traffic type"
        prop="limitTrafficType"
      >
        <el-select v-model="form.limitTrafficType">
          <el-option
            value="app"
            label="App"
          />
          <el-option
            value="site"
            label="Site"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="CPM">
        <el-input
          v-model.number="form.limitPrice"
          type="number"
        />
      </el-form-item>
      <el-form-item label="Target ECPC">
        <el-input
          v-model.number="form.targetEcpc"
          type="number"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-if="!isAgencyWithLimitedPermission"
          v-model="form.interstitial"
          label="Interstitial"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-if="!isAgencyWithLimitedPermission"
          v-model="form.rewarded"
          label="Rewarded"
        />
      </el-form-item>
      <el-form-item
        label="bundle"
        prop="bundle"
      >
        <el-input
          v-model="form.bundle"
          type="text"
        />
      </el-form-item>
      <el-form-item
        label="adomain"
        prop="adomain"
      >
        <el-input
          v-model="form.adomain"
          type="text"
        />
      </el-form-item>
      <el-form-item
        label="Delivery"
        prop="limitDelivery"
      >
        <el-select v-model="form.limitDelivery">
          <el-option
            value="asap"
            label="Fast"
          />
          <el-option
            value="even"
            label="Even"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Start date"
        prop="limitDateStart"
      >
        <el-date-picker
          v-model="form.limitDateStart"
          type="date"
        />
      </el-form-item>
      <el-form-item label="End date">
        <el-date-picker
          v-model="form.limitDateEnd"
          type="date"
        />
      </el-form-item>
      <el-form-item style="margin: 0">
        <el-col
          class="line"
          :span="5"
        >
          -
        </el-col>
        <el-col :span="5">
          Total
        </el-col>
        <el-col
          class="line"
          :span="5"
        >
          -
        </el-col>
        <el-col :span="5">
          Per day
        </el-col>
      </el-form-item>
      <el-form-item
        label-width="80px"
        label="Imps"
      >
        <el-col :span="11">
          <el-input
            v-model.number="form.limitImpsAll"
            type="number"
          />
        </el-col>
        <el-col
          class="line"
          :span="1"
        >
          -
        </el-col>
        <el-col :span="11">
          <el-input
            v-model.number="form.limitImpsDaily"
            type="number"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        label="Clicks"
        label-width="80px"
      >
        <el-col :span="11">
          <el-input
            v-model.number="form.limitClicksAll"
            type="number"
          />
        </el-col>
        <el-col
          class="line"
          :span="1"
        >
          -
        </el-col>
        <el-col :span="11">
          <el-input
            v-model.number="form.limitClicksDaily"
            type="number"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        label="Budget"
        label-width="80px"
      >
        <el-col :span="11">
          <el-input
            v-model.number="form.limitBudgetAll"
            type="number"
          />
        </el-col>
        <el-col
          class="line"
          :span="1"
        >
          -
        </el-col>
        <el-col :span="11">
          <el-input
            v-model.number="form.limitBudgetDaily"
            type="number"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="Imps time" />
      <el-form-item>
        <el-container>
          <div class="checkbox-group-width">
&nbsp;
          </div>
          <div
            v-for="(k, l) of checkBoxes.width"
            :key="l"
            class="checkbox-group-width clickable"
          >
            <span @click="selectCheckerColumn(l)">{{ k }}</span>
          </div>
        </el-container>
        <el-container
          v-for="(hoursRow, b) in checkBoxValues"
          :key="b"
        >
          <div
            class="checkbox-group-width clickable"
            @click="selectCheckerRow(b)"
          >
            {{ b + 1 }}:00
          </div>
          <div
            v-for="(_, l) in hoursRow"
            :key="l"
            class="checkbox-group-width"
          >
            <el-checkbox
              v-model="checkBoxValues[b][l]"
              type="checkbox"
            />
          </div>
        </el-container>
      </el-form-item>
      <el-form-item
        label="Imps frequency"
        style="margin: 0"
      />
      <el-form-item>
        <el-col :span="7">
          <el-input
            v-model.number="form.limitFqImpsCount"
            type="number"
            placeholder="amount"
          />
        </el-col>
        <el-col :span="8">
          <el-input
            v-model.number="form.limitFqImpsInterval"
            type="number"
            placeholder="interval"
          />
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="limitFqImpsInterval"
            @change="(value) => handleSelect(value, 'limitFqImpsInterval')"
          >
            <el-option
              v-for="counts in limitCount"
              :key="counts.value"
              :value="counts.value"
              :label="counts.title"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item
        label="Click frequency"
        style="margin: 0"
      />
      <el-form-item>
        <el-col :span="7">
          <el-input
            v-model.number="form.limitFqClicksCount"
            type="number"
            placeholder="amount"
          />
        </el-col>
        <el-col :span="8">
          <el-input
            v-model.number="form.limitFqClicksInterval"
            type="number"
            placeholder="interval"
          />
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="limitFqClicksInterval"
            @change="(value) => handleSelect(value, 'limitFqClicksInterval')"
          >
            <el-option
              v-for="counts in limitCount"
              :key="counts.value"
              :value="counts.value"
              :label="counts.title"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="Unique impressions">
        <el-input
          v-model.number="form.limitUniqImps"
          type="number"
        />
      </el-form-item>
      <el-form-item label="Clicks unique">
        <el-input
          v-model.number="form.limitUniqClicks"
          type="number"
        />
      </el-form-item>
      <el-form-item>
        <el-tree
          ref="tree"
          :props="treeProps"
          :data="catData"
          show-checkbox
          node-key="id"
          :expand-on-click-node="true"
          highlight-current
          @check="handleNodeClick"
        />
      </el-form-item>
      <el-form-item
        label="Creatives"
        style="margin: 0"
      />
      <el-form-item v-if="allCreatives">
        <el-checkbox-group v-model="selectedCreative">
          <el-checkbox
            v-for="item of allCreatives"
            :key="item.id"
            :label="item"
            @change="(booly) => handleChange(booly, item)"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        label="Apps"
      >
        <el-select
          ref="appsInput"
          v-model="label"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'apps')"
          :loading="loading"
          placeholder="Select app"
          @remove-tag="removeApp"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            @click.native="selectedApps(item, 'apps')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Geography">
        <el-select
          ref="geoInput"
          v-model="geolabel"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'geo')"
          :loading="loading"
          placeholder="Select geography"
          @remove-tag="removeGeo"
        >
          <el-option
            v-for="item in geoOptions"
            :key="item.cityId"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'geo')"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        class="superGeoWrapper"
      >
        <p>Super Geo</p>
        <el-form-item
          v-for="(superGeoItem, index) in form.superGeo"
          :key="superGeoItem.id || index"
          class="superGeoItem"
        >
          <i
            class="el-icon-close close-icon"
            @click.stop="() => {
              form.superGeo = form.superGeo.filter((_, ind) => ind !==index)
            }"
          />
          <el-form-item
            label="Longitude"
            required
          >
            <el-input
              v-model.number="superGeoItem.lon"
              type="number"
              placeholder="Longitude"
            />
          </el-form-item>
          <el-form-item
            label="Latitude"
            required
          >
            <el-input
              v-model.number="superGeoItem.lat"
              type="number"
              aria-required="true"
              placeholder="Latitude"
            />
          </el-form-item>
          <el-form-item
            label="Radius"
            required
          >
            <el-input
              v-model.number="superGeoItem.radius"
              type="number"
              placeholder="Radius"
            />
          </el-form-item>
          <el-form-item
            label="Type"
            required
          >
            <el-select v-model="superGeoItem.type">
              <el-option
                value="allow"
                label="Allow"
              />
              <el-option
                value="reject"
                label="Reject"
              />
            </el-select>
          </el-form-item>
        </el-form-item>
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        class="update-button-item"
      >
        <el-button
          type="primary"
          @click="addNewSuperGeoItem"
        >
          Add note
        </el-button>
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        label="Ssps"
      />
      <el-form-item v-if="!isAgencyWithLimitedPermission && (sspsNames && sspsNames.length)">
        <el-checkbox-group v-model="sspsLabel">
          <el-checkbox
            v-for="(item, index) of sspsNames"
            :key="index"
            :label="item"
            :style="setColor(ssps[index])"
            @change="(booly) => handleChangeSsps(booly, item, index)"
          >
            {{ item }}
            <el-input
              v-model.number="sspsLimitPrices[index]"
              @blur="changeLimitPrice(item, index)"
            />
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="Operation system">
        <el-select
          ref="osInput"
          v-model="osesLabel"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'os')"
          :loading="loading"
          placeholder="Select operation system"
          @remove-tag="removeOs"
        >
          <el-option
            v-for="item in osesOptions"
            :key="item.name"
            :label="item.name"
            :value="item.name"
            @click.native="selectedApps(item, 'os')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="OS Language">
        <el-select
          ref="languagesInput"
          v-model="languagesLabel"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'language')"
          :loading="loading"
          placeholder="Start typing language name"
          @remove-tag="removeLanguage"
        >
          <el-option
            v-for="item in languagesOptions"
            :key="item.name"
            :label="item.name"
            :value="item.name"
            @click.native="selectedApps(item, 'language')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Device types">
        <el-select
          ref="deviceTypesInput"
          v-model="deviceTypesLabel"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'device')"
          :loading="loading"
          placeholder="Select device"
          @remove-tag="removeDevice"
        >
          <el-option
            v-for="item in deviceTypesOptions"
            :key="item.name"
            :label="item.name"
            :value="item.name"
            @click.native="selectedApps(item, 'device')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="App list">
        <el-select
          ref="appsListInput"
          v-model="appListlabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'appsList')"
          :loading="loading"
          placeholder="Select app list"
          @remove-tag="removeAppsList"
        >
          <el-option
            v-for="item in appsListOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'appsList')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Forbidden app list">
        <el-select
          ref="forbiddenAppsListInput"
          v-model="forbiddenAppsListLabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'forbiddenAppsList')"
          :loading="loading"
          placeholder="Select app list"
          @remove-tag="removeForbiddenAppsList"
        >
          <el-option
            v-for="item in forbiddenAppsListOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'forbiddenAppsList')"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        label="Domain list"
      >
        <el-select
          ref="domainsListInput"
          v-model="domainsListLabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'domainsList')"
          :loading="loading"
          placeholder="Select domain"
          @remove-tag="removeDomainsList"
        >
          <el-option
            v-for="item in domainsListOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'domainsList')"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!isAgencyWithLimitedPermission"
        label="Forbidden domain list"
      >
        <el-select
          ref="forbiddenDomainsListInput"
          v-model="forbiddenDomainsListLabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'forbiddenDomainsList')"
          :loading="loading"
          placeholder="Select domain"
          @remove-tag="removeForbiddenDomainsList"
        >
          <el-option
            v-for="item in forbiddenDomainsListOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'forbiddenDomainsList')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="IP-addresses list">
        <el-select
          ref="ipsListInput"
          v-model="ipListLabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'ipsList')"
          :loading="loading"
          placeholder="Select IP-addresses list"
          @remove-tag="removeIpsList"
        >
          <el-option
            v-for="item in ipListOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'ipsList')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Forbidden IP-addresses list">
        <el-select
          ref="forbiddenIpsListInput"
          v-model="forbiddenIpsListLabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'forbiddenIpsList')"
          :loading="loading"
          placeholder="Select IP-addresses list"
          @remove-tag="removeForbiddenIpsList"
        >
          <el-option
            v-for="item in forbiddenIpsListOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'forbiddenIpsList')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="MAC-addresses list">
        <el-select
          ref="macPoolsInput"
          v-model="macPoolslabel"
          filterable
          multiple
          remote
          reserve-keyword
          :remote-method="(query) => remoteMethod(query, 'macPools')"
          :loading="loading"
          placeholder="Select MAC-addresses list"
          @remove-tag="removeMacAddressList"
        >
          <el-option
            v-for="item in macPoolsOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
            :style="setColor(item)"
            @click.native="selectedApps(item, 'macPools')"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Gender">
        <el-select
          v-model="limit_gender_name"
          placeholder="Select gender"
          clearable
        >
          <el-option
            v-for="item in limitGenderOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name"
            :style="setColor(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Age">
        <el-select
          v-model="form.limitAge"
          multiple
          placeholder="Select age"
        >
          <el-option
            v-for="item in limitAgeCheckboxes"
            :key="item.id"
            :label="item.name"
            :value="item.name"
            :style="setColor(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Income">
        <el-select
          v-model="form.limitIncome"
          multiple
          placeholder="Select Income"
        >
          <el-option
            v-for="item in limitIncomeCheckboxes"
            :key="item.id"
            :label="item.name"
            :value="item.name"
            :style="setColor(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="update-button-item">
        <el-button
          v-if="formData"
          type="primary"
          @click="submitUpdate"
        >
          Save
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="submitCreate"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_STRATEGY_CREATE,
  ACTION_STRATEGY_UPDATE,
  ACTION_STRATEGY_GET_NAME,
  ACTION_STRATEGY_APPS_GET,
  ACTION_SSPS_FETCH,
  GETTER_STRATEGY_CURRENT,
  GETTER_AUTH_USER_ROLE,
  GETTER_APP_TABLE_CATEGORIES,
  GETTER_STRATEGY_GEO,
  GETTER_CREATIVE_CURRENT,
  GETTER_STRATEGY_APPS,
  GETTER_STRATEGY_ONE,
  GETTER_SSP_GETSSPS,
  GETTER_STRATEGY_DEVICE_TYPES,
  ACTION_STRATEGY_DEVICE_TYPES_GET,
  GETTER_STRATEGY_OSES,
  ACTION_STRATEGY_OSES_GET,
  GETTER_DOMAINS_LIST_TABLE_DATA,
  ACTION_DOMAINS_LIST_GET,
  GETTER_IP_ADDRESSES_LIST_TABLE_DATA, ACTION_IP_ADDRESSES_LIST_GET,
  GETTER_AUTH_CURRENT_USER, GETTER_STRATEGY_LANGUAGES, ACTION_STRATEGY_LANGUAGES_GET,
} from '@/const';
import { ACTION_APPS_LIST_GET, ACTION_MAC_ADDRESS_LIST_GET, ACTION_STRATEGY_GEO_GET, GETTER_APPS_LIST_TABLE_DATA, GETTER_MAC_ADDRESS_LIST_TABLE_DATA } from '../../const';

export default {
  name: 'StrategyModal',
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      statuses: {
        isNew: true,
        isBlocked: false,
        isPaused: false,
        isActive: false,
      },
      geoFull: [],
      deviceTypesFull: [],
      osFull: [],
      languagesFull: [],
      appFull: [],
      appsListFull: [],
      ipListFull: [],
      forbiddenIpsListFull: [],
      domainsListFull: [],
      forbiddenAppsListFull: [],
      forbiddenDomainsListFull: [],
      macListFull: [],
      ssps: [],
      options: [],
      geoOptions: [],
      deviceTypesOptions: [],
      osesOptions: [],
      languagesOptions: [],
      appsListOptions: [],
      ipListOptions: [],
      forbiddenIpsListOptions: [],
      domainsListOptions: [],
      forbiddenAppsListOptions: [],
      forbiddenDomainsListOptions: [],
      macPoolsOptions: [],
      sspsOptions: [],
      checkBoxValues: [],
      appsName: '',
      selectedRow: 1,
      treeProps: {
        label: 'name',
        children: 'children',
      },
      limitFqImpsInterval: null,
      limitFqClicksInterval: null,
      checkBoxes: {
        height: 24,
        width: ['Mn', 'Tu', 'Wed', 'Th', 'Fr', 'St', 'Sn'],
        selectRow: null,
        selectColumn: null,
      },
      label: [],
      geolabel: [],
      deviceTypesLabel: [],
      osesLabel: [],
      languagesLabel: [],
      appListlabel: [],
      ipListLabel: [],
      forbiddenIpsListLabel: [],
      domainsListLabel: [],
      forbiddenAppsListLabel: [],
      forbiddenDomainsListLabel: [],
      macPoolslabel: [],
      sspsLabel: [],
      sspsLimitPrices: [],
      value: 1,
      form: {
        name: '',
        limitTrafficType: 'app',
        limitPrice: null,
        rewarded: false,
        interstitial: false,
        bundle: null,
        adomain: null,
        limitDelivery: 'even',
        limitDateStart: moment(),
        limitDateEnd: null,
        limitImpsAll: null,
        limitImpsDaily: null,
        limitClicksAll: null,
        limitClicksDaily: null,
        limitBudgetAll: null,
        limitBudgetDaily: null,
        limitDayHours: null,
        limitFqImpsCount: null,
        limitFqImpsInterval: null,
        limitFqClicksCount: null,
        limitFqClicksInterval: null,
        limitUniqImps: null,
        limitUniqClicks: null,
        categories: [],
        creatives: [],
        apps: [],
        geo: [],
        superGeo: [],
        deviceTypes: [],
        oses: [],
        languages: [],
        bundlePools: [],
        ipPools: [],
        forbiddenIpPools: [],
        domainPools: [],
        forbiddenBundlePools: [],
        forbiddenDomainPools: [],
        macPools: [],
        ssps: [],
        limitGender: null,
        limitAge: [],
        limitIncome: [],
      },
      activeBundleId: null,
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        limitTrafficType: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        limitDelivery: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
        limitDateStart: [
          { required: true, message: 'Fill in the field', trigger: 'blur' },
        ],
      },
      limitCount: [
        {
          title: 'q hour',
          value: 3600,
        },
        {
          title: '4 hours',
          value: 14400,
        },
        {
          title: '12 hours',
          value: 43200,
        },
        {
          title: 'day',
          value: 86400,
        },
        {
          title: 'week',
          value: 604800,
        },
        {
          title: 'month',
          value: 2592000,
        },
      ],
      superGeoTemplate: {
        lat: null,
        lon: null,
        radius: null,
        type: 'allow',
      },
      allSpss: null,
      bundlesLabel: [],
      apps: [],
      bundlePools: [],
      ipPools: [],
      forbiddenIpPools: [],
      domainPools: [],
      macPools: [],
      geo: [],
      devices: [],
      oses: [],
      languages: [],
      allCreatives: [],
      selectedCategories: [],
      selectedCreative: [],
      limit_gender_name: '',
    };
  },

  computed: {
    ...mapGetters({
      allCurrentCreative: GETTER_CREATIVE_CURRENT,
      tableData: GETTER_STRATEGY_CURRENT,
      userRole: GETTER_AUTH_USER_ROLE,
      categories: GETTER_APP_TABLE_CATEGORIES,
      getApps: GETTER_STRATEGY_APPS,
      getGeo: GETTER_STRATEGY_GEO,
      getDeviceTypes: GETTER_STRATEGY_DEVICE_TYPES,
      getOses: GETTER_STRATEGY_OSES,
      getLanguages: GETTER_STRATEGY_LANGUAGES,
      getAppsList: GETTER_APPS_LIST_TABLE_DATA,
      getIpsList: GETTER_IP_ADDRESSES_LIST_TABLE_DATA,
      getDomainsList: GETTER_DOMAINS_LIST_TABLE_DATA,
      getMacAddressList: GETTER_MAC_ADDRESS_LIST_TABLE_DATA,
      getStrategy: GETTER_STRATEGY_ONE,
      getSsps: GETTER_SSP_GETSSPS,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),

    limitAgeCheckboxes () {
      return [
        {
          name: '0-18',
          id: 1,
        },
        {
          name: '19-24',
          id: 2,
        },
        {
          name: '25-34',
          id: 3,
        },
        {
          name: '35-50',
          id: 4,
        },
        {
          name: '50+',
          id: 5,
        },
      ];
    },
    limitIncomeCheckboxes () {
      return [
        {
          name: 'A',
          id: 1,
        },
        {
          name: 'B',
          id: 2,
        },
        {
          name: 'C',
          id: 3,
        },
        {
          name: 'C+',
          id: 4,
        },
      ];
    },
    limitGenderOptions () {
      return [{ name: 'Male', id: 1 }, { name: 'Female', id: 2 }];
    },

    isAgencyWithLimitedPermission () {
      const isAgency = this.userRole === 'agency';
      const management = this.currentUser.agencies.length ? this.currentUser.agencies[0].management : '';
      return isAgency && management === 'limited';
    },

    crtves () {
      return this.allCurrentCreative.rows;
    },

    catData () {
      const catData = [{
        name: 'Users Interests',
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },

    setColor () {
      return (item) => {
        if (!item) return;
        let style = 'flex-direction: column; justify-content: space-between; height: 100%;';
        switch (item.status) {
        case 'active':
          style = style + 'color: green';
          break;
        case 'blocked':
          style = style + 'color: red';
          break;
        case 'new':
          style = style + 'color: grey';
          break;
        case 'paused':
          style = style + 'color: orange';
          break;
        default:
          break;
        }
        return style;
      };
    },

    sspsNames () {
      return this.getSsps ? this.getSsps.rows.map(item => item.name) : [];
    },
  },
  watch: {
    ['limit_gender_name'] (updatedValue) {
      const gender = this.limitGenderOptions.find(opt => opt.name === updatedValue) || { id: null };
      this.form.limitGender = gender.id;
    },
  },

  mounted () {
    this.initCheckers();
    if (this.apps) {
      this.apps.map(app => this.setStatus(app.status));
    }
    if (this.formData) {
      this.form.categories = this.formData.categories.map(item => item.appCategory.id);
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.form.categories);
      });
    }
  },

  async created () {
    this.loading = true;
    // await this.fetchApps();
    // await this.fetchGeo();
    // await this.fetchAppsList({});
    // await this.fetchDomainsList({});
    // await this.fetchMacAddressList();

    await this.fetchSsps({
      query: { limit: 0 },
    });

    await this.fetchDeviceTypes();
    await this.fetchOses();
    await this.fetchLanguages();

    this.ssps = this.getSsps.rows.map(item => {
      return {
        name: item.name,
        id: item.id,
        limitPrice: item.limitPrice,
        status: item.status,
      };
    });

    this.sspsLimitPrices = this.getSsps.rows.map(() => 0);
    this.allCreatives = this.crtves;
    if (this.getApps) {
      this.apps = this.getApps.map(app => {
        return {
          value: app.name,
          label: app.name,
          id: app.id,
          status: app.status,
          bundle: app.bundle,
        };
      });

    }
    if (this.getGeo) {
      this.geo = this.getGeo.rows;
      this.geo = this.getGeo.rows.map(geo => {
        return {
          // cityId: geo.city,
          // value: geo.name,
          // label: geo.name,----Пока что оставлю, вдруг бэк что-то поменяет
          // id: geo.city_id,
          // countryId: geo.country_id,
          // stateId: geo.state_id,
          city: { id: geo.city_id, name: geo.name },
          country: { id: geo.country_id, name: null },
          state: { id: geo.state_id, name: null },
          status: 'new',
        };
      });
    }
    
    if (Object.keys(this.getAppsList).length !== 0) {
      this.bundlePools = this.getAppsList.rows.map(appL => {
        return {
          value: appL.name,
          label: appL.name,
          id: appL.pool_id,
        };
      });
    }

    if (Object.keys(this.getDomainsList).length !== 0) {
      this.domainPools = this.getDomainsList.rows.map(appL => {
        return {
          value: appL.name,
          label: appL.name,
          id: appL.pool_id,
        };
      });
    }
    
    if (Object.keys(this.getMacAddressList).length !== 0) {
      this.macPools = this.getMacAddressList.rows.map(macL => {
        return {
          value: macL.name,
          label: macL.name,
          id: macL.pool_id,
        };
      });
    }
    if (this.formData) {
      await this.fetchStrategy({
        params: { campaign_id: this.$route.params.id, id: this.formData.id },
      });

      const strategyData = { ...this.getStrategy };

      delete strategyData.status;
      delete strategyData.updated;
      delete strategyData.campaign;
      delete strategyData.created;
      delete strategyData.clicks;
      delete strategyData.clicksToday;
      delete strategyData.imps;
      delete strategyData.impsToday;
      delete strategyData.budgetToday;

      strategyData.rewarded = !!strategyData.rewarded;
      strategyData.interstitial = !!strategyData.interstitial;
      strategyData.limitDateEnd = strategyData.limitDateEnd || null;
      strategyData.creatives = strategyData.creatives || [];
      strategyData.categories = this.formData.categories.map(item => item.appCategory.id);
      strategyData.ssps = strategyData.ssps.map(ssp => {
        const { id, limitPrice, name } = ssp;
        this.ssps.forEach((item, index) => {
          if (item.id === id) {
            this.sspsLimitPrices[index] = limitPrice;
          }
        });
        return {
          id,
          limitPrice,
          name,
        };
      });

      this.form = {
        ...this.form,
        ...strategyData,
      };

      if (typeof this.formData.limitGender === 'number') {
        const id = 1;
        const option = this.limitGenderOptions.find(opt => opt.id === id);
        this.limit_gender_name = option ? option.name : '';
      }

      if (Array.isArray(this.formData.limitAge)) {
        const ids = this.formData.limitAge;
        const pre = this.limitAgeCheckboxes.filter(age => ids.includes(age.id)).map(age => age.name);
        this.form.limitAge = pre;
      }
      if (Array.isArray(this.formData.limitIncome)) {
        const ids = this.formData.limitIncome;
        const pre = this.limitIncomeCheckboxes.filter(age => ids.includes(age.id)).map(age => age.name);
        this.form.limitIncome = pre;
      }

      if (strategyData.creatives) {
        this.form.creatives = strategyData.creatives.map(item => item.id);

        const creativesIdArray = [];
        strategyData.creatives.forEach(creative => creativesIdArray.push(creative.id));

        let selected = [];
        let unSelected = [];

        this.crtves.forEach(item => {
          if(creativesIdArray.includes(item.id)) {
            selected.push(item);
          } else {
            unSelected.push(item);
          }
        });

        this.selectedCreative = selected;
        this.allCreatives = [...selected, ...unSelected];
      }
      // apps
      let appsArr = [];
      if (Array.isArray(this.form.apps)) {
        this.appFull = this.form.apps;
        appsArr = this.form.apps.map(item => item.id);
        this.label = this.form.apps.map(item => item.name);
      }
      this.form.apps = appsArr;
      
      // Geo
      let geoArr = [];
      if (Array.isArray(this.form.geo)) {
        this.geoFull = this.form.geo;
        geoArr = this.form.geo.map(item => {
          return {
            cityId: item.city_id && item.city_id,
            countryId: item.country_id && item.country_id,
            stateId: item.state_id && item.state_id,
          };
        });
        this.geolabel = this.form.geo.map(item => item.name);
      }
      this.form.geo = geoArr;

      // DeviceTypes
      let devicesArr = [];
      if (Array.isArray(this.form.deviceTypes)) {
        this.deviceTypesFull = this.form.deviceTypes;
        devicesArr = this.form.deviceTypes;
        this.deviceTypesLabel = this.form.deviceTypes.map(item => item.name);
      }
      this.form.deviceTypes = devicesArr.map(device => device.id);

      // Oses
      let osesArr = [];
      if (Array.isArray(this.form.oses)) {
        this.osFull = this.form.oses;
        osesArr = this.form.oses;
        this.osesLabel = this.form.oses.map(item => item.name);
      }
      this.form.oses = osesArr.map(os => os.id);

      let languagesArr = [];
      if(Array.isArray(this.form.languages)){
        this.languagesFull = this.form.languages;
        languagesArr = this.form.languages;
        this.languagesLabel = this.form.languages.map(item => item.name);
      }
      this.form.languages = languagesArr.map(item => item.id);
      
      // appsList
      let appslistArr = [];
      if (Array.isArray(this.form.bundlePools)) {
        this.appsListFull = this.form.bundlePools;
        appslistArr = this.form.bundlePools.map(item => item.pool_id);
        this.appListlabel = this.form.bundlePools.map(item => item.name);
      }
      this.form.bundlePools = appslistArr;

      // IpsList
      let IpsListArr = [];
      if (Array.isArray(this.form.ipPools)) {
        this.ipListFull = this.form.ipPools;
        IpsListArr = this.form.ipPools.map(item => item.pool_id);
        this.ipListLabel = this.form.ipPools.map(item => item.name);
      }
      this.form.ipPools = IpsListArr;

      // ForbiddenIpsList
      let ForbiddenIpsListArr = [];
      if (Array.isArray(this.form.forbiddenIpPools)) {
        this.forbiddenIpsListFull = this.form.forbiddenIpPools;
        ForbiddenIpsListArr = this.form.forbiddenIpPools.map(item => item.pool_id);
        this.forbiddenIpsListLabel = this.form.forbiddenIpPools.map(item => item.name);
      }
      this.form.forbiddenIpPools = ForbiddenIpsListArr;

      // domainsList
      let domainsListArr = [];
      if (Array.isArray(this.form.domainPools)) {
        this.domainsListFull = this.form.domainPools;
        domainsListArr = this.form.domainPools.map(item => item.pool_id);
        this.domainsListLabel = this.form.domainPools.map(item => item.name);
      }
      this.form.domainPools = domainsListArr;

      // forbiddenAppsList
      let forbiddenAppslistArr = [];
      if (Array.isArray(this.form.forbiddenBundlePools)) {
        this.forbiddenAppsListFull = this.form.forbiddenBundlePools;
        forbiddenAppslistArr = this.form.forbiddenBundlePools.map(item => item.pool_id);
        this.forbiddenAppsListLabel = this.form.forbiddenBundlePools.map(item => item.name);
      }
      this.form.forbiddenBundlePools = forbiddenAppslistArr;

      // forbiddenDomainsList
      let forbiddenDomainsListArr = [];
      if (Array.isArray(this.form.forbiddenDomainPools)) {
        this.forbiddenDomainsListFull = this.form.forbiddenDomainPools;
        forbiddenDomainsListArr = this.form.forbiddenDomainPools.map(item => item.pool_id);
        this.forbiddenDomainsListLabel = this.form.forbiddenDomainPools.map(item => item.name);
      }
      this.form.forbiddenDomainPools = forbiddenDomainsListArr;
      
      // MacAddressList
      let macArr = [];
      if (Array.isArray(this.form.macPools)) {
        this.macListFull = this.form.macPools;
        macArr = this.form.macPools.map(item => item.pool_id);
        this.macPoolslabel = this.form.macPools.map(item => item.name);
      }
      this.form.macPools = macArr;


      if (this.form.ssps) {
        this.sspsLabel = this.form.ssps.map(i => i.name);
      }
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      fetchStrategy: ACTION_STRATEGY_GET_NAME,
      update: ACTION_STRATEGY_UPDATE,
      create: ACTION_STRATEGY_CREATE,
      fetchApps: ACTION_STRATEGY_APPS_GET,
      fetchGeo: ACTION_STRATEGY_GEO_GET,
      fetchDeviceTypes: ACTION_STRATEGY_DEVICE_TYPES_GET,
      fetchOses: ACTION_STRATEGY_OSES_GET,
      fetchLanguages: ACTION_STRATEGY_LANGUAGES_GET,
      fetchAppsList: ACTION_APPS_LIST_GET,
      fetchIpsList: ACTION_IP_ADDRESSES_LIST_GET,
      fetchDomainsList: ACTION_DOMAINS_LIST_GET,
      fetchMacAddressList: ACTION_MAC_ADDRESS_LIST_GET,
      fetchSsps: ACTION_SSPS_FETCH,
    }),

    addNewSuperGeoItem () {
      this.form.superGeo = [...this.form.superGeo, { ...this.superGeoTemplate }];
    },

    changeLimitPrice (ssp, index) {
      this.form.ssps.forEach((item, idx) => {
        if (item.id === this.ssps[index].id) {
          this.form.ssps[idx].limitPrice = this.sspsLimitPrices[index];
        }
      });
    },

    removeApp (label, type = 'apps') {
      if (type === 'apps') {
        const obj = this.appFull.find(item => {
          if (item.name === label) {
            return item;
          }
        });
        const id = obj.id;
        this.form.apps = this.form.apps.filter(item => item !== id);
      } else {
        const obj = this.ssps.find(item => item.name === label);
        const id = obj.id;
        this.form.apps = this.form.apps.filter(item => item !== id);
      }
    },
    removeGeo (label, type = 'geo') {
      if (type === 'geo') {
        if (this.form.geo.length) {
          const obj = this.geoFull.find(item => {
            if (item.name === label) {
              return item;
            }
          });
          let cityID;
          let stateID;
          let countryID;
          if (obj.city_id) {
            cityID = obj.city_id;
          } else if (obj.state_id) {
            stateID = obj.state_id;
          } else if (obj.country_id) {
            countryID = obj.country_id;
          }
          this.form.geo = this.form.geo.filter((item) => {
            if (item.cityId) {
              return item.cityId !== cityID;
            } else if (item.stateId) {
              return item.stateId!== stateID;
            } else if (item.countryId) {
              return item.countryId !== countryID;
            }
          });
        } else {
          this.form.geo = [];
        }
      } else {
        const obj = this.ssps.find(item => item.name === label);
        const id = obj.id;
        this.form.geo = this.form.geo.filter(item => item !== id);
      }
    },

    removeDevice (label) {
      const device = this.deviceTypesFull.find(item => item.name === label);
      this.form.deviceTypes  = this.form.deviceTypes.filter(item => item !== device.id);
    },

    removeOs (label) {
      const os = this.osFull.find(item => item.name === label);
      this.form.oses  = this.form.oses.filter(item => item !== os.id);
    },
      
    removeLanguage (label) {
      const language = this.languagesFull.find(item => item.name === label);
      this.form.languages = this.form.languages.filter(item => item !== language.id);
    },
      
    removeAppsList (label, type = 'appsList') {
      if (type === 'appsList') {
        const obj = this.appsListFull.find(item => item.name === label);
        const id = obj.pool_id;
        this.form.bundlePools = this.form.bundlePools.filter(item => item !== id);
      }
    },

    removeIpsList (label, type = 'ipsList') {
      if (type === 'ipsList') {
        const obj = this.ipListFull.find(item => item.name === label);
        const id = obj.pool_id;
        this.form.ipPools = this.form.ipPools.filter(item => item !== id);
      }
    },

    removeForbiddenIpsList (label, type = 'forbiddenIpsList') {
      if (type === 'forbiddenIpsList') {
        const obj = this.forbiddenIpsListFull.find(item => item.name === label);
        const id = obj.pool_id;
        this.form.forbiddenIpPools = this.form.forbiddenIpPools.filter(item => item !== id);
      }
    },

    removeDomainsList (label, type = 'domainsList') {
      if (type === 'domainsList') {
        const obj = this.domainsListFull.find(item => item.name === label);
        const id = obj.pool_id;
        this.form.domainPools = this.form.domainPools.filter(item => item !== id);
      }
    },
    removeForbiddenAppsList (label) {
      const obj = this.forbiddenAppsListFull.find(item => item.name === label);
      const id = obj.pool_id;
      this.form.forbiddenBundlePools = this.form.forbiddenBundlePools.filter(item => item !== id);
    },
    removeForbiddenDomainsList (label) {
      const obj = this.forbiddenDomainsListFull.find(item => item.name === label);
      const id = obj.pool_id;
      this.form.forbiddenDomainPools = this.form.forbiddenDomainPools.filter(item => item !== id);
    },
    removeMacAddressList (label, type = 'macPools') {
      if (type === 'macPools') {
        const obj = this.macListFull.find(item => item.name === label);
        const id = obj.pool_id;
        this.form.macPools = this.form.macPools.filter(item => item !== id);
      }
    },

    setStatus (status) {
      if (status === 'new') {
        this.status.isNew = true;
      }
      if (status === 'blocked') {
        this.status.isBlocked = true;
      }
      if (status === 'active') {
        this.status.isActive = true;
      }
      if (status === 'paused') {
        this.status.isPaused = true;
      }
    },

    selectedApps (item, type) {
      const { id, limitPrice } = item;
      let apps = this.form.apps;
      let geo = this.form.geo;
      let deviceTypes = this.form.deviceTypes;
      let oses = this.form.oses;
      let languages = this.form.languages;
      let appsList = this.form.bundlePools;
      let ipsList = this.form.ipPools;
      let forbiddenIpsList = this.form.forbiddenIpPools;
      let domainsList = this.form.domainPools;
      let forbiddenAppsList = this.form.forbiddenBundlePools;
      let forbiddenDomainsList = this.form.forbiddenDomainPools;
      let macList = this.form.macPools;

      if (!apps.includes(id) && type === 'apps') {
        this.appFull = item;
        apps.push(id);
        this.$refs.appsInput.query = '';
      } else if (apps) {
        apps = apps.filter(value => value !== id);
      }

      if (!forbiddenAppsList.includes(id) && type === 'forbiddenAppsList') {
        forbiddenAppsList.push(id);
        this.forbiddenAppsListFull.push(item);
        this.$refs.forbiddenAppsListInput.query = '';
      } else if (forbiddenAppsList) {
        forbiddenAppsList = forbiddenAppsList.filter(value => value !== id);
      }

      if (!forbiddenDomainsList.includes(id) && type === 'forbiddenDomainsList') {
        forbiddenDomainsList.push(id);
        this.forbiddenDomainsListFull.push(item);
        this.$refs.forbiddenDomainsListInput.query = '';
      } else if (forbiddenDomainsList) {
        forbiddenDomainsList = forbiddenDomainsList.filter(value => value !== id);
      }

      if (!deviceTypes.includes(id) && type === 'device') {
        deviceTypes.push(id);
        this.deviceTypesFull.push(item);
        this.$refs.deviceTypesInput.query = '';
      } else if (deviceTypes) {
        deviceTypes = deviceTypes.filter(value => value !== id);
      }

      if(!languages.includes(id) && type === 'language'){
        languages.push(id);
        this.languagesFull.push(item);
        this.$refs.languagesInput.query = '';
      }else{
        languages = languages.filter(value => value !== id);
      }
      
      if (!oses.includes(id) && type === 'os') {
        oses.push(id);
        this.osFull.push(item);
        this.$refs.osInput.query = '';
      } else if (oses) {
        oses = oses.filter(value => value !== id);
      }

      if (type === 'geo' && !geo.includes(id)) {
        if (geo[0] && geo[0].city || geo[0] && geo[0].country) {
          geo = geo.map((elem) => {
            return {
              cityId: elem.city_id,
              countryId: elem.country_id,
              stateId: elem.state_id,
            };
          });
        }
        const obj = {
          cityId: item.cityId,
          countryId: item.countryId,
          stateId: item.stateId,
        };
        this.geoFull.push(item);
        geo.push(obj);
        this.$refs.geoInput.query = '';
        this.geoOptions = [];
      } else if (geo) {
        geo = geo.filter(value => value !== id);
      }

      if (!appsList.includes(id) && type === 'appsList') {
        appsList.push(id);
        this.appsListFull.push(item);
        this.$refs.appsListInput.query = '';
      } else if (appsList) {
        appsList = appsList.filter(value => value !== id);
      }

      if (!ipsList.includes(id) && type === 'ipsList') {
        ipsList.push(id);
        this.ipListFull.push(item);
        this.$refs.ipsListInput.query = '';
      } else if (ipsList) {
        ipsList = ipsList.filter(value => value !== id);
      }

      if (!forbiddenIpsList.includes(id) && type === 'forbiddenIpsList') {
        forbiddenIpsList.push(id);
        this.forbiddenIpsListFull.push(item);
        this.$refs.forbiddenIpsListInput.query = '';
      } else if (forbiddenIpsList) {
        forbiddenIpsList = forbiddenIpsList.filter(value => value !== id);
      }

      if (!domainsList.includes(id) && type === 'domainsList') {
        domainsList.push(id);
        this.domainsListFull.push(item);
        this.$refs.domainsListInput.query = '';
      } else if (domainsList) {
        domainsList = domainsList.filter(value => value !== id);
      }

      if (!macList.includes(id) && type === 'macPools') {
        macList.push(id);
        this.macListFull.push(item);
        this.$refs.macPoolsInput.query = '';
      } else if (macList) {
        macList = macList.filter(value => value !== id);
      }

      if (type === 'ssps') {
        const idArr = this.form.ssps.map(item => item.id);
        if (!idArr.includes(id)) {
          const ssps = {
            id,
            limitPrice: Number(limitPrice),
          };
          this.form.ssps.push(ssps);
          this.$refs.sspsInput.query = '';
        } else {
          this.form.ssps = this.form.ssps.filter(value => value.id !== id);
        }
      }
    },

    encodeCheckers () {
      let a = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 24; j++) {
          a.push(this.checkBoxValues[j][i] ? '1' : '0');
        }
      }
      this.form.limitDayHours = a.join('');
      return this.form.limitDayHours;
    },

    handleChange (booly, item) {
      const selected = this.selectedCreative.filter(({ id }) => id !== item);
      this.form.creatives = selected.map(({ id }) => id);
    },

    handleChangeSsps (booly, item, index) {
      if (booly) {
        this.form.ssps.push({
          id: this.ssps[index].id,
          limitPrice: this.sspsLimitPrices[index],
        });
      } else {
        this.form.ssps = this.form.ssps.filter(value => value.id !== this.ssps[index].id);
      }
    },

    handleNodeClick () {
      this.selectedCategories = this.$refs.tree.getCheckedNodes();
      const categories = this.selectedCategories.map(item => item);
      this.form.categories = categories.map(item => item.id);
    },

    initCheckers () {
      if (this.formData && this.formData.limitDayHours) {
        const array = this.formData.limitDayHours.split('');
        if (array.length === this.checkBoxes.height * this.checkBoxes.width.length) {
          for (let i = 0; i < this.checkBoxes.height; i++) {
            if (this.checkBoxValues[i] === undefined) {
              this.checkBoxValues.push([]);
            }
            for (let j = 0; j < this.checkBoxes.width.length; j++) {
              if (this.checkBoxValues[i][j] === undefined) {
                this.checkBoxValues[i].push(array[(j * this.checkBoxes.height) + i] === '1');
              }
            }
          }
          return;
        }
      }
      for (let i = 0; i < this.checkBoxes.height; i++) {
        if (this.checkBoxValues[i] === undefined) {
          this.checkBoxValues.push([]);
        }
        for (let j = 0; j < this.checkBoxes.width.length; j++) {
          if (this.checkBoxValues[i][j] === undefined) {
            this.checkBoxValues[i].push(true);
          }
        }
      }
    },

    remoteMethod (query, type) {
      if (query !== '' && type === 'apps') {
        this.loading = true;
        this.fetchApps(query).then(({ data }) => {
          if (data.length) {
            this.options = data.map((item) => {
              return {
                value: item.name,
                label: item.name,
                status: item.status,
                bundle: item.bundle,
                id: item.id,
              };
            });
          }
        });
      } else {
        this.options = [];
      }

      if (query !== '' && type === 'geo') {
        if (query.length > 1) {
          this.fetchGeo(query).then(({ data }) => {
            if (data.rows) {
              this.geoOptions = data.rows.map((item) => {
                return {
                  value: item.name,
                  label: item.name,
                  id: item.city_id,
                  countryId: item.country_id,
                  stateId: item.state_id,
                  cityId: item.city_id,
                };
              });
            }
          });
        }
        this.loading = true;
      } else {
        this.geoOptions = [];
      }

      if (query !== '' && type === 'device') {
        this.loading = true;
        this.deviceTypesOptions = this.getDeviceTypes.rows.filter(item => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }

      if (query !== '' && type === 'os') {
        this.loading = true;
        this.osesOptions = this.getOses.rows.filter(item => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }
      
      if(query !== '' && type === 'language'){
        this.loading = true;
        this.languagesOptions = this.getLanguages.filter(item => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }

      if (query !== '' && type === 'appsList') {
        this.loading = true;
        this.fetchAppsList({ query }).then((res) => {
          if (res.rows.length) {
            this.appsListOptions = res.rows.map((appL) => {
              return {
                value: appL.name, 
                label: appL.name,
                id: appL.pool_id,
              };
            });
          }
        });
        this.appsListOptions = this.bundlePools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.appsListOptions = [];
      }

      if (query !== '' && type === 'ipsList') {
        this.loading = true;
        this.fetchIpsList({ query }).then((res) => {
          if (res.rows.length) {
            this.ipListOptions = res.rows.map((appL) => {
              return {
                value: appL.name,
                label: appL.name,
                id: appL.pool_id,
              };
            });
          }
        });
        this.ipListOptions = this.ipPools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.ipListOptions = [];
      }

      if (query !== '' && type === 'forbiddenIpsList') {
        this.loading = true;
        this.fetchIpsList({ query }).then((res) => {
          if (res.rows.length) {
            this.forbiddenIpsListOptions = res.rows.map((appL) => {
              return {
                value: appL.name,
                label: appL.name,
                id: appL.pool_id,
              };
            });
          }
        });
        this.forbiddenIpsListOptions = this.ipPools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.forbiddenIpsListOptions = [];
      }

      if (query !== '' && type === 'domainsList') {
        this.loading = true;
        this.fetchDomainsList({ query }).then((res) => {
          if (res.rows.length) {
            this.domainsListOptions = res.rows.map((appL) => {
              return {
                value: appL.name,
                label: appL.name,
                id: appL.pool_id,
              };
            });
          }
        });
        this.domainsListOptions = this.domainPools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.domainsListOptions = [];
      }

      if (query !== '' && type === 'forbiddenAppsList') {
        this.loading = true;
        this.fetchAppsList({ query }).then((res) => {
          if (res.rows.length) {
            this.forbiddenAppsListOptions = res.rows.map((appL) => {
              return {
                value: appL.name,
                label: appL.name,
                id: appL.pool_id,
              };
            });
          }
        });
        this.forbiddenAppsListOptions = this.bundlePools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.forbiddenAppsListOptions = [];
      }

      if (query !== '' && type === 'forbiddenDomainsList') {
        this.loading = true;
        this.fetchDomainsList({ query }).then((res) => {
          if (res.rows.length) {
            this.forbiddenDomainsListOptions = res.rows.map((appL) => {
              return {
                value: appL.name,
                label: appL.name,
                id: appL.pool_id,
              };
            });
          }
        });
        this.forbiddenDomainsListOptions = this.domainPools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.forbiddenDomainsListOptions = [];
      }
      
      if (query !== '' && type === 'macPools') {
        this.loading = true;
        this.fetchMacAddressList(query).then((res) => {
          if (res.rows.length) {
            this.macPoolsOptions = res.rows.map((macL) => {
              return {
                value: macL.name,
                label: macL.name,
                id: macL.pool_id,
              };
            });
          }
        });
        this.macPoolsOptions = this.macPools.filter(item => {
          if (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.value;
          }
        });
      } else {
        this.macPoolsOptions = [];
      }

      if (!type) {
        this.loading = true;
        this.sspsOptions = this.ssps.filter(item => {
          if (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return item.name;
          }
        });
      }
      this.loading = false;
    },

    selectCheckerRow (index) {
      this.value ++;
      const boxes = Object.assign(this.checkBoxValues);
      this.checkBoxValues = [];
      const valueToSet = boxes[index][0] !== undefined ? !boxes[index][0]: false;
      if (boxes[index] !== undefined) {
        for (let i = 0; i < boxes[index].length; i++) {
          boxes[index][i] = valueToSet;
        }
      }
      this.checkBoxValues = boxes;
    },

    handleSelect (value, type) {
      this.form[type] = value;
    },

    selectCheckerColumn (index) {
      this.value ++;
      const boxes = Object.assign(this.checkBoxValues);
      this.checkBoxValues = [];
      const valueToSet = boxes[0] !== undefined ? !(boxes[0][index]): false;
      for (let i = 0; i < boxes.length; i++) {
        boxes[i][index] = valueToSet;
      }
      this.checkBoxValues = boxes;
    },

    openMessage (error = [{ msg: 'Something went wrong' }]) {
      this.$messageBox.alert(
        `${
          error.map((item) => {
            return ' ' + item.msg + ' '
            ;})
        }
        `, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    async submitUpdate () {
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          this.encodeCheckers();
          this.loading = true;

          const endDate = this.form.limitDateEnd === null ? null : moment(this.form.limitDateEnd).format('YYYY-MM-DD');

          const data = {
            ...this.form,
            campaignId: parseInt(this.$route.params.id),
            id: parseInt(this.form.id),
            limitDateStart: moment(this.form.limitDateStart).format('YYYY-MM-DD'),
            limitDateEnd: endDate,
          };

          data.limitPrice = data.limitPrice || 0;
          data.limitImpsAll = data.limitImpsAll || 0;
          data.limitImpsDaily = data.limitImpsDaily || 0;
          data.limitClicksAll = data.limitClicksAll || 0;
          data.limitClicksDaily = data.limitClicksDaily || 0;
          data.limitBudgetAll = data.limitBudgetAll || 0;
          data.limitBudgetDaily = data.limitBudgetDaily || 0;
          data.limitFqImpsCount = data.limitFqImpsCount || 0;
          data.limitFqImpsInterval = data.limitFqImpsInterval || 0;
          data.limitFqClicksCount = data.limitFqClicksCount || 0;
          data.limitFqClicksInterval = data.limitFqClicksInterval || 0;
          data.limitUniqImps = data.limitUniqImps || 0;
          data.limitUniqClicks = data.limitUniqClicks || 0;
          data.limitDateEnd = data.limitDateEnd || null;
          data.rewarded = +data.rewarded;
          data.interstitial = +data.interstitial;
          data.limitAge = (data.limitAge && data.limitAge.length) ? data.limitAge.map(name => this.limitAgeCheckboxes.find(item => item.name === name).id) : null;
          data.limitIncome = (data.limitIncome && data.limitIncome.length) ? data.limitIncome.map(name => this.limitIncomeCheckboxes.find(item => item.name === name).id) : null;


          data.ssps = data.ssps || [];

          const response = await this.update({
            params: {
              campaign_id: parseInt(this.$route.params.id),
              id: this.formData.id,
            },
            data,
          });
          if (response && response.data && response.data.errors) {
            this.openMessage(response.data.errors);
          } else {
            this.$emit('newPage', this.tableData.currentPage);
            this.$emit('close');
          }

          this.loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
    },

    async submitCreate () {
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          
          const endDate = this.form.limitDateEnd === null ? null : moment(this.form.limitDateEnd).format('YYYY-MM-DD');

          this.loading = true;
          this.encodeCheckers();
          const data = {
            ...this.form,
            limitDateStart: moment(this.form.limitDateStart).format('YYYY-MM-DD'),
            limitDateEnd: endDate,
          };
          data.limitPrice = data.limitPrice || 0;
          data.limitImpsAll = data.limitImpsAll || 0;
          data.limitImpsDaily = data.limitImpsDaily || 0;
          data.limitClicksAll = data.limitClicksAll || 0;
          data.limitClicksDaily = data.limitClicksDaily || 0;
          data.limitBudgetAll = data.limitBudgetAll || 0;
          data.limitBudgetDaily = data.limitBudgetDaily || 0;
          data.limitFqImpsCount = data.limitFqImpsCount || 0;
          data.limitFqImpsInterval = data.limitFqImpsInterval || 0;
          data.limitFqClicksCount = data.limitFqClicksCount || 0;
          data.limitFqClicksInterval = data.limitFqClicksInterval || 0;
          data.limitUniqImps = data.limitUniqImps || 0;
          data.limitUniqClicks = data.limitUniqClicks || 0;
          data.rewarded = +data.rewarded;
          data.interstitial = +data.interstitial;
          data.limitAge = (data.limitAge && data.limitAge.length) ? data.limitAge.map(name => this.limitAgeCheckboxes.find(item => item.name === name).id) : null;
          data.limitIncome = (data.limitIncome && data.limitIncome.length) ? data.limitIncome.map(name => this.limitIncomeCheckboxes.find(item => item.name === name).id) : null;

          data.ssps = data.ssps || [];

          delete data.imps;
          delete data.startEnd;
          delete data.budget;
          delete data.clicks;
          const response = await this.create({
            params: { campaign_id: parseInt(this.$route.params.id) },
            data: {
              ...data,
              campaignId: parseInt(this.$route.params.id),
            },
          });

          if (response && response.data && response.data.errors) {
            this.openMessage(response.data.errors);
          }

          if (response.status === 200) {
            this.$emit('close');
          }

          this.$emit('newPage', this.tableData.currentPage);
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-form-item__label,
.el-select {
  width: 100%;
}
.checkbox-group-width {
  width: 35px;
  line-height: 15px;
  text-align: center;
}
.clickable {
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}

.el-dialog {
  .el-form {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;
    padding-right: 15px;

    .superGeoItem {
      margin: 5px;
      padding: 5px 5px 20px 5px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);

      .el-form-item {
        margin-bottom: 15px
      }

      .close-icon {
        cursor: pointer;
        position: relative;
        top: 5px;
        right: 5px;
        left: 93%;
        &:hover {
          color: #409eff;
          border-radius: 50%;
          background-color: #cbcbcb;
        }
      }
    }

    .el-checkbox-group {
      max-height: 200px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .el-checkbox {
        height: 30px;
      }
    }

    .category-tags {
      max-height: 150px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 6px;
        background-color: #e7eef4;
      }
      &::-webkit-scrollbar-thumb {
        background: #409eff;
        border-radius: 6px;
      }
    }

    ::-webkit-scrollbar {
      width: 5px;
      border-radius: 6px;
      background-color: #e7eef4;
    }

    ::-webkit-scrollbar-thumb {
      background: #409eff;
      border-radius: 6px;
    }
    .el-col {
      margin-right: 5px;
    }
  }

  .el-autocomplete {
    width: 220px;
  }
  & .red {
    color: red !important;
  }
  .update-button-item {
    text-align: center;

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}

.oneBundle {
  margin: 15px 0;
  border: 1px solid grey;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 3px;
  .el-input {
    margin-top: 10px;
  }
  .el-textarea {
    margin: 10px 0;
  }
}

.el-checkbox {
  position: relative;
  margin: 10px 0;
  .el-input {
    width: 100px !important;
    position: absolute;
    left: auto;
    right: 20px;
    bottom: 1px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.line {
  color: #fff;
}
</style>
