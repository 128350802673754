<template>
  <div class="macAddressList">
    <el-form
      label-position="top"
      class="dspCreative-from"
      :inline="true"
      :v-loading="loading"
    >
      <el-form-item
        label="Text"
      >
        <el-input
          v-model="query.searchText"
          placeholder="External Id, Code, URL"
          type="text"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="submitSearchForm"
        >
          Search
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="resetSearchForm"
        >
          Reset
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          class="btns"
          type="primary"
          @click="openDialog(null)"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
    <Table
      :table-data="tableDataMac"
      :column="tableColumnsMac"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.pool_id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-edit"
              @click.native="openDialog(scope.row)"
            >
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="removeMacAddressList(scope.row.pool_id)"
            >
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <MacAddressListModal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="openModal=false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_MAC_ADDRESS_LIST_DELETE,
  ACTION_MAC_ADDRESS_LIST_GET,
  GETTER_MAC_ADDRESS_LIST_TABLE_COLUMNS,
  GETTER_MAC_ADDRESS_LIST_TABLE_DATA,
} from '@/const';
import Table from '../../../components/Table.vue';
import MacAddressListModal from '@/views/Settings/_MacAddressList/MacAddressListModal';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  components: {
    MacAddressListModal,
    Table,
  },
  data () {
    return {
      loading: false,
      openModal: false,
      formData: {},
      query: {
        searchText: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      tableDataMac: GETTER_MAC_ADDRESS_LIST_TABLE_DATA, 
      tableColumnsMac: GETTER_MAC_ADDRESS_LIST_TABLE_COLUMNS,
    }),
  },

  async created () {
    this.loading = true;
    await this.getMacAddressList();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getMacAddressList: ACTION_MAC_ADDRESS_LIST_GET,
      deleteMacAddressList: ACTION_MAC_ADDRESS_LIST_DELETE,
    }),

    async submitSearchForm () {
      this.loading = true;
      await this.getNewPage();
      this.loading = false;
    },

    async getNewPage () {
      this.loading = true;
      await this.getMacAddressList({
        query: this.query.searchText || null,
      });
      this.loading = false;
    },

    async resetSearchForm () {
      this.loading = true;
      this.query.searchText = null;
      await this.getNewPage();
      this.loading = false;
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    openDialog (row) {
      this.openModal = true;
      this.formData = row;
    },

    async removeMacAddressList (id) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        this.loading = true;
        const res = await this.deleteMacAddressList({
          params: { id },
        });
        this.loading = false;

        if (!res.data.errors) {
          await this.getNewPage();
        } else {
          this.openMessage();
        }
      }).catch(() => {});
    },

    openMessage () {
      this.$messageBox.alert(
        'Something went wrong', 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.pool_id].visible
          ? this.$refs['dropdown' + row.pool_id].hide()
          : this.$refs['dropdown' + row.pool_id].show();
      }
    },
  },
};
</script>

<style lang="scss">
.macAddressList {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: right;

  .dspCreative-from {
    position: absolute;
    top: 20px;
    right: 0;
    text-align: left;
    margin-bottom: 40px;

    & .el-form-item__label {
      line-height: 0;
    }

    .btns {
      margin-top: 20px;
    }
  }

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>