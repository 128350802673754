import {
  ACTION_ROOT_REQUEST,
  MUTATION_IP_ADDRESSES_LIST_SET,
  MUTATION_IP_ADDRESSES_LIST_SETONE,
  URL_IP_ADDRESSES_LIST_DELETE_UPDATE,
  URL_IP_ADDRESSES_LIST_GET_CREATE,
} from '@/const';
const state = {
  tableData: {},
  oneIpAddressesList: null,
  categories: [],
};

const getters = {
  getTableColumns (state, getters, rootState, rootGetters) {
    const userRole = rootGetters['auth/userRole'];
    const adminColumns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'pool_id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: 'agency.name',
        label: 'Agency',
        width: 150,
      },
    ];
    const clientColumns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'pool_id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
    ];
    return ['administrator', 'manager'].includes(userRole) ? adminColumns : clientColumns;
  },

  getTableData (state) {
    return state.tableData;
  },

  getOneIpAddressesList (state) {
    return state.oneIpAddressesList;
  },
};

const mutations = {

  setIpAddressesList: (state, response) => {
    state.tableData = {
      rows: response.rows,
    };
  },
  
  setOneIpAddressesList (state, response) {
    state.oneIpAddressesList = response;
  },
};

const actions = {
  fetchIpAddressesList: async ({ dispatch }, query ) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_IP_ADDRESSES_LIST_GET_CREATE,
      commitName: MUTATION_IP_ADDRESSES_LIST_SET,
    }, { root: true });
    return res.data;
  },

  fetchOneIpAddressesList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_IP_ADDRESSES_LIST_DELETE_UPDATE,
      commitName: MUTATION_IP_ADDRESSES_LIST_SETONE,
    }, { root: true });
    return res;
  },

  updateIpAddressesList: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_IP_ADDRESSES_LIST_DELETE_UPDATE,
    }, { root: true });
    return res;
  },

  createIpAddressesList: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_IP_ADDRESSES_LIST_GET_CREATE,
    }, { root: true });
    return res;
  },

  deleteIpAddressesList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_IP_ADDRESSES_LIST_DELETE_UPDATE,
    }, { root: true });
    return res;
  },

  getRole: ({ rootGetters }) => rootGetters['auth/userRole'],
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
