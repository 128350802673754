<template>
  <el-dialog
    title="Creating a transaction"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      style="position: inherit!important;"
    >
      <el-form-item
        label="Publisher"
        prop="publisherId"
      >
        <el-autocomplete
          v-model="form.publisher"
          class="inline-input"
          :fetch-suggestions="querySearchPublisher"
          @select="(item) => (meta.publisherId = item.id)"
        />
      </el-form-item>
      <el-form-item
        label="Agency"
        prop="agency"
      >
        <el-autocomplete
          v-model="form.agency"
          class="inline-input"
          :fetch-suggestions="querySearchAgency"
          @select="(item) => (meta.agencyId = item.id)"
        />
      </el-form-item>
      <el-form-item
        label="Advertisers"
        prop="advertiser"
      >
        <el-autocomplete
          v-model="form.advertiser"
          class="inline-input"
          :fetch-suggestions="querySearchAdvertiser"
          @select="(item) => (meta.advertiserId = item.id)"
        />
      </el-form-item>
      <el-form-item
        label="Operation"
        prop="operation"
      >
        <el-select
          v-model="form.operation"
          placeholder="Select"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Amount"
        prop="amount"
      >
        <el-input
          v-model="form.amount"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Description"
        prop="description"
      >
        <el-input
          v-model="form.description"
          name="description"
          type="textarea"
          autosize
          :rows="3"
        />
      </el-form-item>
      <el-form-item
        class="create-button-item"
      >
        <el-button
          type="primary"
          @click="createNewTransaction"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_BILLING_TRANSACTION_CREATE,
  ACTION_PUBLISHERS_GET,
  ACTION_BILLING_TRANSACTION_GET,
  GETTER_PUBLISHERS_DATA,
  ACTION_AGENCIES_GET,
  GETTER_AGENCIES_DATA,
  ACTION_ADVERTISERS_GET,
  GETTER_ADVERTISERS_DATA,
} from '@/const';

export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      loading: false,
      publisher: '',
      meta: {
        publisherId: null,
        agencyId: null,
        advertiserId: null,
      },
      form: {
        publisher: null,
        agency: null,
        advertiser: null,
        amount: null,
        description: '',
        operation: '',
      },
      options: [{ label: 'credit', value: 'credit' }, { label: 'debit', value: 'debit' }],
      rules: {
        description: [
          { required: true, message: 'Fill in the field' },
        ],
        amount: [
          { required: true, message: 'Fill in the field' },
        ],
        operation: [
          { required: true, message: 'Fill in the field' },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_DATA,
      getAgenciesData: GETTER_AGENCIES_DATA,
      getAdvertisersData: GETTER_ADVERTISERS_DATA,
    }),
  },

  watch: {
    ['form.publisher'] (newValue) { this.validateSelect(newValue, 'publisherId'); },
    ['form.agency'] (newValue) { this.validateSelect(newValue, 'agencyId'); },
    ['form.advertiser'] (newValue) { this.validateSelect(newValue, 'advertiserId'); },
  },

  async created () {
    this.loading = true;
    await Promise.allSettled([
      this.fetchAllPublishers({ query: { limit: 0 } }),
      this.fetchAllAgencies({ query: { limit: 0 } }),
      this.fetchAllAdvertisers({ query: { limit: 0 } }),
    ]);
    this.loading = false;
  },

  methods: {
    ...mapActions({
      createTransaction: ACTION_BILLING_TRANSACTION_CREATE,
      fetchTransactions: ACTION_BILLING_TRANSACTION_GET,
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
      fetchAllAgencies: ACTION_AGENCIES_GET,
      fetchAllAdvertisers: ACTION_ADVERTISERS_GET,
    }),

    validateSelect (updatedValue, metaKey) {
      this.meta[metaKey] = updatedValue.trim() ? this.meta[metaKey] : null;
    },

    mapOptions (options) {
      return options.map(option => ({ id: option.id, value: option.name }));
    },

    filterOptions (options, queryString) {
      const createFilter = queryString => link => (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      return queryString ? options.filter(createFilter(queryString)) : options;
    },

    querySearchPublisher (queryString, cb) {
      cb(this.filterOptions(this.mapOptions(this.getPublishersData.rows), queryString));
    },
    querySearchAgency (queryString, cb) {
      cb(this.filterOptions(this.mapOptions(this.getAgenciesData.rows), queryString));
    },
    querySearchAdvertiser (queryString, cb) {
      cb(this.filterOptions(this.mapOptions(this.getAdvertisersData.rows), queryString));
    },

    showMessage (error = [{ msg: 'Something went wrong' }]) {
      const message = error.map((item) => ' ' + item.msg + ' ').toString();
      const title = 'Error';
      const options = { confirmButtonText: 'Ok' };

      this.$messageBox.alert(message, title, options);
    },

    async createNewTransaction () {
      this.$refs.form.validate(async (valid) => {
        const isOneFilled = (fields) => fields.filter(Boolean).length === 1;
        const { publisherId, agencyId, advertiserId } = this.meta;

        if (valid && isOneFilled([publisherId, agencyId, advertiserId])) {
          this.loading = true;
          const data = {
            publisherId: this.meta.publisherId,
            agencyId: this.meta.agencyId,
            advertiserId: this.meta.advertiserId,
            description: this.form.description,
            operation: this.form.operation,
            amount: this.form.amount,
          };
          const response = await this.createTransaction({ data });
          if (response.data.errors) {
            this.showMessage(response.data.errors);
          }
          await this.fetchTransactions({ query: { page: this.$route.query.page || 1 } });
          if (response.status === 200) {
            this.$emit('close');
            this.$emit('updateBalance');
          }
          this.loading = false;
        } else {
          this.$messageBox.alert('Fill required fields and  only one of them: Publisher, Agency, Advertiser');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 450px;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
    }

    &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
    }

    & .el-autocomplete, .el-input, .el-date-picker, .el-select {
    max-width: 440px !important;
    width: 100% !important;
    }
  }

  .create-button-item {
    text-align: end;

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
.el-dialog{
  max-width: 500px;
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
