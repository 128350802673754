import {
  ACTION_ROOT_REQUEST,
  URL_CAMPAIGNS_CREATE,
  MUTATION_CAMPAIGNS_SET,
  URL_CAMPAIGNS_UPDATE,
  URL_CAMPAIGNS_GET_REPORT,
  URL_CAMPAIGNS_CHANGE_STATUS,
  MUTATION_CAMPAIGNS_SET_REPORT,
  MUTATION_CAMPAINGS_SETONE,
  URL_CAMPAIGNS_GET_REPORTS_LIST, MUTATION_CAMPAIGNS_SET_REPORTS_LIST,
} from '@/const';

const state = {
  tableData: {},
  reportsData: {},
  reportsList: {},
  oneCampaing: null,
};


const getters = {
  getTableColumns (state, getters, rootState, rootGetters) {
    const userRole = rootGetters['auth/userRole'];
    let agencyAdvertiser = 'agencyAdvertiser';
    let agencyAdvertiserLabel = 'Agency/advertiser';

    if (userRole === 'advertiser') {
      agencyAdvertiser = 'agency.name';
      agencyAdvertiserLabel = 'Agency';
    } else if (userRole === 'agency') {
      agencyAdvertiser = 'advertiser.name';
      agencyAdvertiserLabel = 'Advertiser';
    }

    const adminColumns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 70,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'level',
        label: 'Level',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: agencyAdvertiser,
        label: agencyAdvertiserLabel,
        width: 150,
      },
      {
        prop: 'limitDate',
        label: 'Start/End',
        width: 150,
      },
      {
        prop: 'limitImps',
        label: 'Imps',
        width: 150,
      },
      {
        prop: 'limitClicks',
        label: 'Clicks',
        width: 150,
      },
      {
        prop: 'limitBudget',
        label: 'Budget',
        width: 150,
      },
    ];

    const userColumns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 70,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: agencyAdvertiser,
        label: agencyAdvertiserLabel,
        width: 150,
      },
      {
        prop: 'limitDate',
        label: 'Start/End',
        width: 150,
      },
      {
        prop: 'limitImps',
        label: 'Imps',
        width: 150,
      },
      {
        prop: 'limitClicks',
        label: 'Clicks',
        width: 150,
      },
      {
        prop: 'limitBudget',
        label: 'Budget',
        width: 150,
      },
    ];
    return ['administrator', 'manager'].includes(userRole) ? adminColumns : userColumns;
  },

  getTableData (state) {
    return state.tableData;
  },

  getOne (state) {
    return state.oneCampaing;
  },

  campaignsReport (state) {
    return state.reportsData;
  },

  campaignsReportsList (state) {
    return state.reportsList;
  },
};

const mutations = {
  setCampaigns: (state, response) => {
    state.tableData = response;
    if (response.rows) {
      state.tableData.rows = response.rows.map((item) => {
        return {
          ...item,
          agencyAdvertiser: `${(item.agency && item.agency.name) || ''} / ${(item.advertiser && item.advertiser.name) || ''}`,
          limitDate: `${ item.limitDateStart || ''} / ${ item.limitDateEnd || '' }`,
          limitImps: `${item.imps || 0} / ${item.impsToday || 0}`,
          limitClicks: `${item.clicks || 0} / ${item.clicksToday || 0}`,
          limitBudget: `${item.budget || 0} / ${item.budgetToday || 0}`,
        };
      });
    }
  },

  setOneCampaing: (state, response) => {
    state.oneCampaing = response;
  },

  setCampaingsReport: (state, response) => {
    state.reportsData = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.reportsData.rows = tableData;
    state.reportsData.excelData = excelData;
  },

  setCampaignsReportList: (state, response) => {
    state.reportsList = response;
  },

};
const actions = {
  getCampaigns: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_CAMPAIGNS_CREATE,
      commitName: MUTATION_CAMPAIGNS_SET,
    }, { root: true });
    return res;
  },

  updateCampaign: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_CAMPAIGNS_UPDATE,
    }, { root: true });
    return res;
  },

  getCampaign: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_CAMPAIGNS_UPDATE,
      commitName: MUTATION_CAMPAINGS_SETONE,
    }, { root: true });
    return res;
  },

  createCampaign: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_CAMPAIGNS_CREATE,
    }, { root: true });
    return res;
  },

  deleteCampaign: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_CAMPAIGNS_UPDATE,
    }, { root: true });
    return res;
  },

  changeCampaignStatus: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_CAMPAIGNS_CHANGE_STATUS,
    }, { root: true });
    return res;
  },

  getCampaignsReport: async ({ dispatch }, { params, query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      query,
      url: URL_CAMPAIGNS_GET_REPORT,
      commitName: MUTATION_CAMPAIGNS_SET_REPORT,
    }, { root: true });
    return res;
  },

  getCampaignsReportsList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_CAMPAIGNS_GET_REPORTS_LIST,
      commitName: MUTATION_CAMPAIGNS_SET_REPORTS_LIST,
    }, { root: true });
    return res;
  },
  getRole: ({ rootGetters }) => rootGetters['auth/userRole'],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
