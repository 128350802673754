<template>
  <div class="advertisers">
    <el-button
      class="advertisers-add-btn"
      type="primary"
      @click="openDialog(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="getAdvertisersData"
      :column="filteredColumns"
      :cellstyle="cellStyle"
      :loading="loading"
      @cellStatus="cellUser"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="showUsersListButton"
              @click.native="routeTo(scope.row.id)"
            >
              Viewing a list of users
            </el-dropdown-item>
            <el-dropdown-item
              :divided="showUsersListButton"
              icon="el-icon-edit"
              @click.native="openDialog(scope.row.id)"
            > 
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deleteAdvertise(scope.row.id)"
            > 
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <AdvertisersModal
      v-if="dialogVisible"
      :id="id"
      :dialog-visible="dialogVisible"
      @close="close"
    />
  </div>
</template>

<script>

import {
  GETTER_ADVERTISERS_DATA,
  GETTER_ADVERTISERS_TABLE,
  ACTION_ADVERTISERS_REMOVE,
  ACTION_ADVERTISERS_GET,
  GETTER_AUTH_USER_MANAGEMENT,
  GETTER_AUTH_USER_ROLE,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import AdvertisersModal from './AdvertisersModal.vue';
import Table from '@/components/Table.vue';

export default {
  name: 'Advertisers',
  components: {
    AdvertisersModal,
    Table,
  },
  data () {
    return {
      dialogVisible: false,
      id: null,
      ownerData: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getAdvertisersData: GETTER_ADVERTISERS_DATA,
      getAdvertisersColumns: GETTER_ADVERTISERS_TABLE,
      userManagement: GETTER_AUTH_USER_MANAGEMENT,
      userRole: GETTER_AUTH_USER_ROLE,
    }),

    filteredColumns () {
      if (this.userRole === 'agency' && ['limited', 'full'].includes(this.userManagement)) {
        return this.getAdvertisersColumns.filter(({ prop }) => ['id', 'name', 'options', 'balance'].includes(prop));
      }
      return this.getAdvertisersColumns;
    },
    showUsersListButton () {
      return this.userRole === 'agency' ? !['full', 'limited'].includes(this.userManagement) : true;
    },

    cellStyle () {
      return {
        columnProperty: 'userCount',
        styles: 'cursor: pointer; color: #409EFF',
      };
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      fetchAllAdvertisers: ACTION_ADVERTISERS_GET,
      removeAdvertiser: ACTION_ADVERTISERS_REMOVE,
    }),

    close () {
      this.dialogVisible = false;
    },

    openDialog (id) {
      this.id = id;
      this.dialogVisible = true;
    },

    async getNewPage (page = 1) {
      this.loading = true;
      const response = await this.fetchAllAdvertisers({
        query: { page },
      });
      response.rows.forEach(item => {
        this.ownerData = item.owner;
      });
      this.loading = false;
    },

    mouseOver (row, column, cell) {
      if (column.property === 'userCount' || column.property === 'options') {
        cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
      }
    },

    mouseOut (row, column, cell) {
      if (column.property === 'userCount' || column.property === 'options') {
        cell.style = 'background-color: none; color: #409EFF';
      }
    },

    async deleteAdvertise (id) {
      const { currentPage, rows } = this.getAdvertisersData;
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        await this.removeAdvertiser({
          params: { id },
        });
        if (rows.length - 1) {
          this.getNewPage(currentPage);
        } else {
          this.getNewPage(currentPage - 1);
        }
      });
    },

    cellUser (row, col) {
      if (col.property === 'userCount') {
        this.routeTo(row.id);
      }
      if (col.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    routeTo (advertisersId) {
      this.$router.push({ name: 'AdvertisersUsers', params: { id: advertisersId } });
    },
  },
 
};

</script>

<style lang="scss">
.advertisers {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>