import {
  ACTION_ROOT_REQUEST,
  URL_APPS_GET_CREATE,
  MUTATION_APPS_SET,
  URL_APPS_UPDATE,
  URL_APPS_STATUS_PAUSE,
  URL_APPS_CHANGE_ACTIVATE,
  URL_APPS_CHANGE_BLOCK,
  URL_APPS_GET_CATEGORIES,
  MUTATION_APPS_SET_CATEGORIES,
  MUTATION_APPS_SET_REPORT,
  URL_APPS_GET_REPORT,
  MUTATION_APPS_SETONE,
  URL_APPS_GET_REPORT_LIST,
  MUTATION_APPS_SET_REPORTS_LIST,
} from '@/const';
const state = {
  tableData: {},
  app: null,
  categories: [],
  reportsData: {},
  reportsList: {},
};

const getters = {
  getTableColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 35,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 70,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: 'bundle',
        label: 'Bundle',
        width: 100,
      },
      {
        prop: 'category.name',
        label: 'Category',
        width: 150,
      },
      {
        prop: 'publisher.name',
        label: 'Publisher',
        width: 150,
      },
      {
        prop: 'bidfloor',
        label: 'Min. price',
        width: 150,
      },
      {
        prop: 'dsp',
        label: 'DSP',
        width: 150,
      },
    ];
    return columns;
  },

  getOneApp (state) {
    return state.app;
  },

  getTableData (state) {
    return state.tableData;
  },

  appsReport (state) {
    return state.reportsData;
  },

  categories (state) {
    return state.categories;
  },

  reportsList (state) {
    return state.reportsList;
  },
};

const mutations = {

  setApps: (state, response) => {
    state.tableData = response;
  },

  setApp: (state, app) => {
    state.app = app;
  },

  setAppsReport: (state, response) => {
    state.reportsData = response;
    const { rows, header } = response;
    let tableData = [];
    let excelData = [];
    rows.forEach((item) => {
      let excelItem = {};
      let tableItem = {};
      item.forEach((value, index) => {
        tableItem[header[index].key] = value;
        excelItem[header[index].name] = value;
      });
      tableData.push(tableItem);
      excelData.push(excelItem);
    });
    state.reportsData.rows = tableData;
    state.reportsData.excelData = excelData;
  },

  setCategories (state, response) {
    response.rows.forEach((item) => {
      state.categories[item.id] = item;
    });
    // state.categories = response.rows;
  },

  setAppsReportsList (state, response) {
    state.reportsList = response;
  },
};

const actions = {
  getApps: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_APPS_GET_CREATE,
      commitName: MUTATION_APPS_SET,
    }, { root: true });
    return res.data;
  },

  getCategories: async ({ dispatch }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_APPS_GET_CATEGORIES,
      commitName: MUTATION_APPS_SET_CATEGORIES,
    }, { root: true });
  },

  fetchOneApp: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_APPS_UPDATE,
      commitName: MUTATION_APPS_SETONE,
    }, { root: true });
    return res;
  },

  updateApp: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_APPS_UPDATE,
    }, { root: true });
    return res;
  },

  createApp: async ({ dispatch }, { data, query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      query,
      url: URL_APPS_GET_CREATE,
    }, { root: true });
    return res;
  },

  deleteApp: async ({ dispatch }, { params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_APPS_UPDATE,
    }, { root: true });
  },

  changeStatusPause: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_STATUS_PAUSE,
    }, { root: true });
    return res;
  },

  changeStatusActivate: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_CHANGE_ACTIVATE,
    }, { root: true });
    return res;
  },

  changeStatusBlock: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_CHANGE_BLOCK,
    }, { root: true });
    return res;
  },

  getAppsReport: async ({ dispatch }, { params, query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      params,
      url: URL_APPS_GET_REPORT,
      commitName: MUTATION_APPS_SET_REPORT,
    }, { root: true });
    return res;
  },

  getAppsReportsList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_APPS_GET_REPORT_LIST,
      commitName: MUTATION_APPS_SET_REPORTS_LIST,
    }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
