import {
  ACTION_ROOT_REQUEST,
  URL_APPS_STATUS_PAUSE,
  URL_APPS_CHANGE_ACTIVATE,
  URL_APPS_CHANGE_BLOCK,
  MUTATION_APPS_SET_REPORT,
  URL_APPS_GET_REPORT,
  URL_APPS_GET_REPORT_LIST,
  MUTATION_APPS_SET_REPORTS_LIST,
  MUTATION_APPS_LIST_SET,
  URL_APPS_LIST_GET_CREATE,
  URL_APPS_LIST_DELETE_UPDATE, MUTATION_APPS_LIST_SETONE,
} from '@/const';
const state = {
  tableData: {},
  oneAppList: null,
  categories: [],
  reportsData: {},
  reportsList: {},
};

const getters = {
  getTableColumns (state, getters, rootState, rootGetters) {
    const userRole = rootGetters['auth/userRole'];
    const adminColumns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'pool_id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
      {
        prop: 'agency.name',
        label: 'Agency',
        width: 150,
      },
    ];
    const clientColumns = [
      {
        prop: 'options',
        label: '',
        width: 20,
        buttons: true,
      },
      {
        prop: 'pool_id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Name',
        width: 150,
      },
    ];
    return ['administrator', 'manager'].includes(userRole) ? adminColumns : clientColumns;
  },

  getTableData (state) {
    return state.tableData;
  },

  getOneAppList (state) {
    return state.oneAppList;
  },
};

const mutations = {

  setAppsList: (state, response) => {
    state.tableData = {
      rows: response.rows,
    };
  },

  setOneAppsList (state, response) {
    state.oneAppList = response;
  },
};

const actions = {
  getAppsList: async ({ dispatch }, query  ) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_APPS_LIST_GET_CREATE,
      commitName: MUTATION_APPS_LIST_SET,
    }, { root: true });
    return res.data;
  },

  getOneAppsList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_APPS_LIST_DELETE_UPDATE,
      commitName: MUTATION_APPS_LIST_SETONE,
    }, { root: true });
    return res;
  },

  updateAppList: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_APPS_LIST_DELETE_UPDATE,
    }, { root: true });
    return res;
  },

  createAppList: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_APPS_LIST_GET_CREATE,
    }, { root: true });
    return res;
  },

  deleteAppList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_APPS_LIST_DELETE_UPDATE,
    }, { root: true });
    return res;
  },

  changeStatusPause: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_STATUS_PAUSE,
    }, { root: true });
    return res;
  },

  changeStatusActivate: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_CHANGE_ACTIVATE,
    }, { root: true });
    return res;
  },

  changeStatusBlock: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_CHANGE_BLOCK,
    }, { root: true });
    return res;
  },

  getAppsReport: async ({ dispatch }, { params, query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      params,
      url: URL_APPS_GET_REPORT,
      commitName: MUTATION_APPS_SET_REPORT,
    }, { root: true });
    return res;
  },

  getAppsReportsList: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_APPS_GET_REPORT_LIST,
      commitName: MUTATION_APPS_SET_REPORTS_LIST,
    }, { root: true });
    return res;
  },

  getRole: ({ rootGetters }) => rootGetters['auth/userRole'],
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
