//URL's
export const BASE_URL = 'https://api.mobidriven.com';

export const URL_AUTH_LOGIN = '/users/signin';
export const URL_AUTH_REGISTER = '/users/signup';
export const URL_AUTH_CONFIRM = '/users/confirm-email';
export const URL_AUTH_RESTORE = '/users/restore';
export const URL_GET_CURRENT_USER = '/users/me';
export const URL_AUTH_SIGHOUT = 'users/signout';
export const URL_AUTH_EDIT = '/users/:id';

export const URL_CAMPAIGNS_UPDATE = '/campaigns/:id';
export const URL_CAMPAIGNS_CREATE = '/campaigns';
export const URL_CAMPAIGNS_CHANGE_STATUS = '/campaigns/:id/:status';
export const URL_CAMPAIGNS_GET_REPORT = '/campaigns/:id/report/:reportName';
export const URL_CAMPAIGNS_GET_REPORTS_LIST = '/campaigns/:id/reports';

export const URL_APPS_GET_CREATE = '/apps';
export const URL_APPS_UPDATE = '/apps/:id';
export const URL_APPS_GET_CATEGORIES = '/categories';
export const URL_APPS_STATUS_PAUSE = '/apps/:id/pause';
export const URL_APPS_CHANGE_ACTIVATE = '/apps/:id/activate';
export const URL_APPS_CHANGE_BLOCK = '/apps/:id/block';
export const URL_APPS_GET_REPORT = '/apps/:id/report/:reportName';
export const URL_APPS_GET_REPORT_LIST = '/apps/:id/reports';
export const URL_APPS_CREATE = '/apps';

//-----------------------------------
export const URL_APPS_LIST_GET_CREATE = '/bundle_pools';
export const URL_APPS_LIST_DELETE_UPDATE = '/bundle_pools/:id';
// DomainsList
export const URL_DOMAINS_LIST_GET_CREATE = '/domain_pools';
export const URL_DOMAINS_LIST_DELETE_UPDATE = '/domain_pools/:id';
// IPList
export const URL_IP_ADDRESSES_LIST_GET_CREATE = '/ip_pools';
export const URL_IP_ADDRESSES_LIST_DELETE_UPDATE = '/ip_pools/:id';
//mac_pools?query=<query>
export const URL_MAC_ADDRESS_LIST_GET_CREATE = '/mac_pools';
export const URL_MAC_ADDRESS_LIST_DELETE_UPDATE = '/mac_pools/:id';
//-----------------------------------

export const URL_CREATIVE_GET = '/campaigns/:campaign_id/creatives';
export const URL_CREATIVE_UPDATE ='/campaigns/:id/creatives/:creativeId';
export const URL_CREATIVE_DELETE = '/campaigns/:id/creatives/:creativeId';
export const URL_CREATIVE_CREATE = '/campaigns/:campaign_id/creatives';
export const URL_CREATIVE_PAUSE = '/campaigns/:id/creatives/:creativeId/pause';
export const URL_CREATIVE_ACTIVATE = '/campaigns/:id/creatives/:creativeId/activate';
export const URL_CREATIVE_BLOCK = '/campaigns/:id/creatives/:creativeId/block';
export const URL_CREATIVE_ALL_FILES = '/campaigns/:id/creatives/:creativeId/files';
export const URL_CREATIVE_CREATE_FILE = '/files';
export const URL_CREATIVE_DELETE_FILE = '/files/:id';
export const URL_CREATIVE_REPORTS = '/campaigns/:campaignId/creatives/:id/report/:reportName';
export const URL_CREATIVE_REPORTS_LIST = '/campaigns/:campaignId/creatives/:id/reports';
export const URL_CREATIVE_FORMATS = '/formats';

export const URL_STRATEGIES_GET = '/campaigns/:campaign_id/line_items';
export const URL_STRATEGY_GET = '/campaigns/:campaign_id/line_items/:id';
export const URL_STRATEGY_CREATE = '/campaigns/:campaign_id/line_items';
export const URL_STRATEGIES_UPDATE = '/campaigns/:campaign_id/line_items/:id';
export const URL_STRATEGY_DELETE = '/campaigns/:campaign_id/line_items/:id';
export const URL_STRATEGY_PAUSE = '/campaigns/:campaign_id/line_items/:id/pause';
export const URL_STRATEGY_BLOCK = '/campaigns/:campaign_id/line_items/:id/block';
export const URL_STRATEGY_ACTIVATE = '/campaigns/:campaign_id/line_items/:id/activate';
export const URL_STRATEGY_REPORT_GET = '/campaigns/:campaignId/line_items/:id/report/:reportName';
export const URL_STRATEGY_REPORTS_LIST_GET = '/campaigns/:campaignId/line_items/:id/reports';
export const URL_STRATEGY_APPS_GET = '/apps-for-targetings?query=';
export const URL_STRATEGY_COPY = '/campaigns/:campaign_id/line_items/:id/copy';

export const URL_SYSTEM_REPORTS_LIST_GET = '/reports';
export const URL_SYSTEM_REPORT_GET = '/report/:reportName';

export const URL_BILLING_TRANSACTION_CREATE_GET = '/transactions';
export const URL_BILLING_TRANSACTION_TRANSFER_CREATE = 'transactions/transfer';
export const URL_BILLING_ORDERS_CREATE_GET = '/orders';
export const URL_BILLING_ORDERS_DELETE = '/orders/:id';
export const URL_BILLING_ORDERS_ACCEPT = '/orders/:id/accept';
export const URL_BILLING_ORDERS_REJECT = '/orders/:id/reject';
export const URL_BILLING_ORDERS_DONE = '/orders/:id/done';

export const URL_AGENCIES_GET_POST = '/agencies';
export const URL_AGENCIES_DELETE_PUT = '/agencies/:id';
export const URL_AGENCIES_USERS_GET_POST = '/agencies/:id/users';
export const URL_AGENCIES_USERS_DELETE = '/agencies/:agency_id/users/:id';

export const URL_USERS_GET_UPDATE = '/users/:id';
export const URL_USERS_GET_CURRENT = '/users/account/:id';
export const URL_USERS_DELETE_CURRENT = '/users/:id';
export const URL_USERS_GET_CREATE = '/users';
export const URL_USERS_BLOCK = '/users/:id/activate';
export const URL_USERS_ACTIVATE = '/users/:id/block';

export const URL_ADVERTISERS_GET_POST = '/advertisers';
export const URL_ADVERTISERS_DELETE_PUT = '/advertisers/:id';
export const URL_ADVERTISERS_USERS_GET_POST = '/advertisers/:id/users';
export const URL_ADVERTISERS_USERS_DELETE = '/advertisers/:advertisers_id/users/:id';

export const URL_PUBLISHERS_GET_POST = '/publishers';
export const URL_PUBLISHERS_DELETE_PUT = '/publishers/:id';
export const URL_PUBLISHERS_USERS_GET_POST = '/publishers/:id/users';
export const URL_PUBLISHERS_USERS_DELETE = '/publishers/:publishers_id/users/:id';

export const URL_STRATEGY_GEO_GET = '/geo?query=';

export const URL_STRATEGY_DEVICE_TYPES = '/device-types';
export const URL_STRATEGY_OSES = '/oses';
export const URL_STRATEGY_LANGUAGES = '/languages';
export const URL_DSP_GET = '/dsps';
export const URL_DSP_REPORTS_GET = '/dsps/:id/report/:reportName';
export const URL_DSP_REPORTS_LIST_GET = '/dsps/:id/reports';
export const URL_DSP_CREATE = '/dsps';
export const URL_DSP_UPDATE = '/dsps/:id';
export const URL_DSP_DELETE = '/dsps/:id';
export const URL_DSP_BLOCK = '/dsps/:id/block';
export const URL_DSP_ACTIVATE = '/dsps/:id/activate';
export const URL_DSP_CURRENCIES_GET = '/currencies';

export const URL_DSPCREATIVE_GET = '/dsp-creatives';
export const URL_DSPCREATIVE_BLOCK = '/dsp-creatives/:id/block';
export const URL_DSPCREATIVE_ACTIVE = '/dsp-creatives/:id/activate';
export const URL_DSPCREATIVE_MODERATE = '/dsp-creatives/:id/moderate';
export const URL_DSPCREATIVE_UNMODERATE = '/dsp-creatives/:id/unmoderate';
export const URL_DSPCREATIVE_DELETE = '/dsp-creatives/:id';
export const URL_DSPCREATIVE_UPDATE = '/dsp-creatives/:id';

export const URL_SSPS_GET = '/ssps';
export const URL_SSP_GET = '/ssps/:id';
export const URL_SSP_BLOCK = '/ssps/:id/block';
export const URL_SSP_ACTIVE = '/ssps/:id/activate';
export const URL_SSP_DELETE = '/ssps/:id';
export const URL_SSP_HANDLERS_GET = '/ssps-handlers';
export const URL_SSP_REPORTS_GET = '/ssps/:id/report/:reportName';
export const URL_SSP_REPORTS_LIST_GET = '/ssps/:id/reports';
export const URL_SSP_CREATE = '/ssps';
export const URL_SSP_UPDATE = '/ssps/:id';

//GETTERS
export const GETTER_AUTH_CURRENT_USER = 'auth/currentUser';
export const GETTER_AUTH_USER_MANAGEMENT = 'auth/management';
export const GETTER_AUTH_USER_ACCESS = 'auth/getUserAccess';
export const GETTER_AUTH_USER_ROLE = 'auth/userRole';
export const GETTER_AUTH_USER_BALANCE = 'auth/balance';

export const GETTER_COMPANY_TABLE_COLUMNS = 'campaigns/getTableColumns';
export const GETTER_CAMPAINGS_GET_ONE = 'campaigns/getOne';
export const GETTER_COMPANY_TABLE_DATA = 'campaigns/getTableData';
export const GETTER_COMPANY_REPORT = 'campaigns/campaignsReport';
export const GETTER_COMPANY_REPORTS_LIST = 'campaigns/campaignsReportsList';

export const GETTER_APP_TABLE_COLUMNS = 'apps/getTableColumns';
export const GETTER_APP_TABLE_DATA = 'apps/getTableData';
export const GETTER_APP_GET_ONE = 'apps/getOneApp';
export const GETTER_APP_REPORT = 'apps/appsReport';
export const GETTER_APP_REPORTS_LIST = 'apps/reportsList';
export const GETTER_APP_TABLE_CATEGORIES = 'apps/categories';

export const GETTER_APPS_LIST_TABLE_DATA = 'appsList/getTableData';
export const GETTER_APP_LIST_GET_ONE = 'appsList/getOneAppList';
export const GETTER_APPS_LIST_TABLE_COLUMNS = 'appsList/getTableColumns';

export const GETTER_DOMAINS_LIST_TABLE_DATA = 'domainsList/getTableData';
export const GETTER_DOMAIN_LIST_GET_ONE = 'domainsList/getOneDomainList';
export const GETTER_DOMAINS_LIST_TABLE_COLUMNS = 'domainsList/getTableColumns';

export const GETTER_IP_ADDRESSES_LIST_TABLE_DATA = 'ipAddressesList/getTableData';
export const GETTER_IP_ADDRESSES_LIST_GET_ONE = 'ipAddressesList/getOneIpAddressesList';
export const GETTER_IP_ADDRESSES_LIST_TABLE_COLUMNS = 'ipAddressesList/getTableColumns';

export const GETTER_MAC_ADDRESS_LIST_TABLE_DATA = 'macAddressList/getTableDataMac';
export const GETTER_MAC_ADDRESS_LIST_GET_ONE = 'macAddressList/getOneMacAddressList';
export const GETTER_MAC_ADDRESS_LIST_TABLE_COLUMNS = 'macAddressList/getTableColumnsMac';
//------------------

export const GETTER_CREATIVE_CURRENT = 'creative/allCurrentCreative';
export const GETTER_CREATIVE_TABLE = 'creative/creativeTable';
export const GETTER_CREATIVE_ALL_FILES = 'creative/allFiles';
export const GETTER_CREATIVE_ALL_REPORTS = 'creative/allReports';
export const GETTER_CREATIVE_REPORTS_LIST = 'creative/reportsList';
export const GETTER_CREATIVE_ALL_FORMATS = 'creative/getFormats';

export const GETTER_STRATEGY_CURRENT = 'strategy/getCurrentStrategies';
export const GETTER_STRATEGY_ONE = 'strategy/getStrategy';
export const GETTER_STRATEGY_TABLE_COLUMNS = 'strategy/getColumns';
export const GETTER_STRATEGY_GEO = 'strategy/getGeo';
export const GETTER_STRATEGY_DEVICE_TYPES = 'strategy/getDeviceTypes';
export const GETTER_STRATEGY_OSES = 'strategy/getOses';
export const GETTER_STRATEGY_LANGUAGES = 'strategy/getLanguages';
export const GETTER_STRATEGY_APPS = 'strategy/getApps';
export const GETTER_STRATEGY_REPORT = 'strategy/getCurrentReport';
export const GETTER_STRATEGY_REPORTS_LIST = 'strategy/getReportsList';

export const GETTER_SYSTEM_REPORT = 'systemReports/getCurrentReport';
export const GETTER_SYSTEM_REPORTS_LIST = 'systemReports/getReportsList';

export const GETTER_BILLING_TRANSACTIONS_COLUMN = 'billings/transactionsColumn';
export const GETTER_BILLING_TRANSACTIONS_DATA = 'billings/transactionsData';

export const GETTER_BILLING_ORDERS_COLUMN = 'billings/ordersColumns';
export const GETTER_BILLING_ORDERS_DATA = 'billings/ordersData';

export const GETTER_AGENCIES_DATA = 'settingsAgencies/getAgenciesData';
export const GETTER_AGENCIES_TABLE = 'settingsAgencies/getAgenciesColumns';
export const GETTER_AGENCIES_USERS_DATA = 'settingsAgencies/getAgenciesUser';
export const GETTER_AGENCIES_USERS_TABLE = 'settingsAgencies/getAgenciesUsersColumns';

export const GETTER_ADVERTISERS_DATA = 'advertisers/getAdvertisersData';
export const GETTER_ADVERTISERS_TABLE = 'advertisers/getAdvertisersColumns';
export const GETTER_ADVERTISERS_USERS_DATA = 'advertisers/getAdvertisersUser';
export const GETTER_ADVERTISERS_USERS_TABLE = 'advertisers/getAdvertisersUsersColumns';

export const GETTER_PUBLISHERS_DATA = 'publishers/getPublishersData';
export const GETTER_PUBLISHERS_TABLE = 'publishers/getPublishersColumns';
export const GETTER_PUBLISHERS_USERS_DATA = 'publishers/getPublishersUser';
export const GETTER_PUBLISHERS_USERS_TABLE = 'publishers/getPublishersUsersColumns';

export const GETTER_USERS_DATA = 'users/getUsersData';
export const GETTER_USERS_TABLE = 'users/getUsersColumns';

export const GETTER_DSP_DATA = 'dsp/getDspData';
export const GETTER_DSP_ONE = 'dsp/getOneDsp';
export const GETTER_DSP_CURRENCIES = 'dsp/getCurrencies';
export const GETTER_DSP_COLUMNS = 'dsp/getDspColumn';
export const GETTER_DSP_REPORTS = 'dsp/getDspReports';
export const GETTER_DSP_REPORTS_LIST = 'dsp/getDspReportsList';

export const GETTER_DSPCREATIVE_GETDSPCREATIVE = 'dspCreative/getDspCreative';
export const GETTER_DSPCREATIVE_DSPTABLE = 'dspCreative/DspTable';
export const GETTER_DSPCREATIVE_FILTERED_TABLE = 'dspCreative/getFilteredDspCreativeTable';

export const GETTER_SSP_GETSSPS = 'ssp/getSsps';
export const GETTER_SSP_SSPTABLE = 'ssp/getSspTable';
export const GETTER_SSP_ONE = 'ssp/getOneSsp';
export const GETTER_SSP_HANDLERS = 'ssp/getSspHandlers';
export const GETTER_SSP_REPORTS = 'ssp/getSspReports';
export const GETTER_SSP_REPORTS_LIST = 'ssp/getSspReportsList';

//MUTATIONS
export const MUTATION_AUTH_SET_USER = 'auth/setCurrentUser';
export const MUTATION_AUTH_LOGIN = 'auth/login';
export const MUTATION_AUTH_SIGNOUT = 'auth/logout';
export const MUTATION_AUTH_CHECKED = 'auth/switchCheck';
export const MUTATION_AUTH_SET_BALANCE = 'auth/setBalance';

export const MUTATION_CAMPAIGNS_SET = 'campaigns/setCampaigns';
export const MUTATION_CAMPAINGS_SETONE = 'campaigns/setOneCampaing';
export const MUTATION_CAMPAIGNS_SET_REPORT = 'campaigns/setCampaingsReport';
export const MUTATION_CAMPAIGNS_SET_REPORTS_LIST = 'campaigns/setCampaignsReportList';

export const MUTATION_APPS_SET = 'apps/setApps';
export const MUTATION_APPS_SETONE = 'apps/setApp';
export const MUTATION_APPS_SET_REPORT = 'apps/setAppsReport';
export const MUTATION_APPS_SET_REPORTS_LIST = 'apps/setAppsReportsList';
export const MUTATION_APPS_SET_CATEGORIES = 'apps/setCategories';

export const MUTATION_APPS_LIST_SET = 'appsList/setAppsList';
export const MUTATION_APPS_LIST_SETONE = 'appsList/setOneAppsList';
// DOMAINS LIST
export const MUTATION_DOMAINS_LIST_SET = 'domainsList/setDomainsList';
export const MUTATION_DOMAINS_LIST_SETONE = 'domainsList/setOneDomainsList';
// IP ADDRESSES LIST
export const MUTATION_IP_ADDRESSES_LIST_SET = 'ipAddressesList/setIpAddressesList';
export const MUTATION_IP_ADDRESSES_LIST_SETONE = 'ipAddressesList/setOneIpAddressesList';

export const MUTATION_MAC_ADDRESS_LIST_SETONE = 'macAddressList/setOneMacAddressList';
export const MUTATION_MAC_ADDRESS_LIST_SET = 'macAddressList/setMacAddressList';
//---------------
export const MUTATION_CREATIVE_SET = 'creative/setAllCreatives';
export const MUTATION_CREATIVE_SET_FILES = 'creative/setFiles';
export const MUTATION_CREATIVE_SET_REPORTS = 'creative/setAllReports';
export const MUTATION_CREATIVE_SET_REPORTS_LIST = 'creative/setReportsList';
export const MUTATION_CREATIVE_SET_FORMATS = 'creative/setFormats';

export const MUTATION_STRATEGY_SET_CURRENT = 'strategy/setCurrentStrategies';
export const MUTATION_STRATEGY_SETONE = 'strategy/setOne';
export const MUTATION_STRATEGY_SET_REPORTS = 'strategy/setCurrentStrategyReports';
export const MUTATION_STRATEGY_SET_REPORTS_LIST = 'strategy/setStrategyReportsList';
export const MUTATION_STRATEGY_SET_GEO = 'strategy/setGeo';
export const MUTATION_STRATEGY_SET_DEVICE_TYPES = 'strategy/setDeviceTypes';
export const MUTATION_STRATEGY_SET_OSES = 'strategy/setOses';
export const MUTATION_STRATEGY_SET_LANGUAGES = 'strategy/setLanguages';
export const MUTATION_STRATEGY_APPS_SET = 'strategy/setApps';

export const MUTATION_SYSTEM_CURRENT_REPORT_SET = 'systemReports/setCurrentReport';
export const MUTATION_SYSTEM_REPORTS_LIST_SET = 'systemReports/setSystemReportsList';

export const MUTATION_BILLING_TRANSACTIONS_SET = 'billings/setTransactions';

export const MUTATION_BILLING_ORDERS_SET = 'billings/setOrders';

export const MUTATION_AGENCIES_SET = 'settingsAgencies/setAgencies';
export const MUTATION_AGENCIES_USERS_SET = 'settingsAgencies/setAgenciesUser';

export const MUTATION_ADVERTISERS_SET = 'advertisers/setAdvertisers';
export const MUTATION_ADVERTISERS_USERS_SET = 'advertisers/setAdvertisersUser';

export const MUTATION_PUBLISHERS_SET = 'publishers/setPublishers';
export const MUTATION_PUBLISHERS_USERS_SET = 'publishers/setPublishersUser';

export const MUTATION_USERS_SET = 'users/setUsers';

export const MUTATION_DSP_SET = 'dsp/setDspData';
export const MUTATION_DSP_SET_ONE = 'dsp/setOneDsp';
export const MUTATION_DSP_SET_REPORTS = 'dsp/setDspReport';
export const MUTATION_DSP_SET_REPORTS_LIST = 'dsp/setDspReportsList';
export const MUTATION_DSP_CURRENCIES_SET = 'dsp/setCurrencies';
export const MUTATION_DSPCREATIVE_SET = 'dspCreative/setDspCreative';
export const MUTATION_FILTERED_DSPCREATIVE_SET = 'dspCreative/getFilteredDspCreatives';

export const MUTATION_SSP_SET = 'ssp/setSsps';
export const MUTATION_SSP_ONE_SET = 'ssp/setOneSsp';
export const MUTATION_SSP_HANDLERS_SET = 'ssp/setSspHandlers';
export const MUTATION_SSP_REPORTS_SET = 'ssp/setSspReport';
export const MUTATION_SSP_REPORTS_LIST_SET = 'ssp/setReportsList';

//ACTIONS
export const ACTION_ROOT_REQUEST = 'http';

export const ACTION_AUTH_LOGIN = 'auth/loginData';
export const ACTION_AUTH_EMAIL ='auth/sendEmail';
export const ACTION_AUTH_NEWPASS = 'auth/sendNewPassword';
export const ACTION_AUTH_REGISTER = 'auth/register';
export const ACTION_AUTH_VERIFY = 'auth/verify';
export const ACTION_AUTH_CURRENT_USER = 'auth/getCurrentUser';
export const ACTION_AUTH_SIGHOUT = 'auth/signOut';
export const ACTION_AUTH_EDIT = 'auth/edit';

export const ACTION_CAMPAIGNS_GET = 'campaigns/getCampaigns';
export const ACTION_CAMPAIGN_GET = 'campaigns/getCampaign';
export const ACTION_CAMPAIGNS_UPDATE = 'campaigns/updateCampaign';
export const ACTION_CAMPAIGNS_CREATE = 'campaigns/createCampaign';
export const ACTION_CAMPAIGNS_DELETE = 'campaigns/deleteCampaign';
export const ACTION_CAMPAIGNS_CHANGE_STATUS = 'campaigns/changeCampaignStatus';
export const ACTION_CAMPAIGNS_GET_REPORT = 'campaigns/getCampaignsReport';
export const ACTION_CAMPAIGNS_GET_REPORT_LIST = 'campaigns/getCampaignsReportsList';

export const ACTION_APPS_GET = 'apps/getApps';
export const ACTION_APPS_GET_APP = 'apps/fetchOneApp';
export const ACTION_APPS_CREATE = 'apps/createApp';
export const ACTION_APPS_UPDATE = 'apps/updateApp';
export const ACTION_APPS_ONE = 'apps/fetchOneApp';
export const ACTION_APPS_DELETE = 'apps/deleteApp';
export const ACTION_APPS_STATUS_PAUSE = 'apps/changeStatusPause';
export const ACTION_APPS_CHANGE_ACTIVATE = 'apps/changeStatusActivate';
export const ACTION_APPS_CHANGE_BLOCK = 'apps/changeStatusBlock';
export const ACTION_APPS_GET_REPORT = 'apps/getAppsReport';
export const ACTION_APPS_GET_REPORTS_LIST = 'apps/getAppsReportsList';
export const ACTION_APPS_GET_CATEGORIES = 'apps/getCategories';

export const ACTION_APPS_LIST_GET = 'appsList/getAppsList';
export const ACTION_ONE_APPS_LIST_GET = 'appsList/getOneAppsList';
export const ACTION_APPS_LIST_CREATE = 'appsList/createAppList';
export const ACTION_APPS_LIST_UPDATE = 'appsList/updateAppList';
export const ACTION_APPS_LIST_DELETE = 'appsList/deleteAppList';

export const ACTION_DOMAINS_LIST_GET = 'domainsList/fetchDomainsList';
export const ACTION_ONE_DOMAINS_LIST_GET = 'domainsList/fetchOneDomainList';
export const ACTION_DOMAINS_LIST_CREATE = 'domainsList/createDomainList';
export const ACTION_DOMAINS_LIST_UPDATE = 'domainsList/updateDomainList';
export const ACTION_DOMAINS_LIST_DELETE = 'domainsList/deleteDomainList';

export const ACTION_IP_ADDRESSES_LIST_GET = 'ipAddressesList/fetchIpAddressesList';
export const ACTION_ONE_IP_ADDRESSES_LIST_GET = 'ipAddressesList/fetchOneIpAddressesList';
export const ACTION_IP_ADDRESSES_LIST_CREATE = 'ipAddressesList/createIpAddressesList';
export const ACTION_IP_ADDRESSES_LIST_UPDATE = 'ipAddressesList/updateIpAddressesList';
export const ACTION_IP_ADDRESSES_LIST_DELETE = 'ipAddressesList/deleteIpAddressesList';

//-----------------------------------
export const ACTION_MAC_ADDRESS_LIST_GET = 'macAddressList/getMacAddressList';
export const ACTION_ONE__MAC_ADDRESS_LIST_GET = 'macAddressList/getOneMacAddressList';
export const ACTION_MAC_ADDRESS_LIST_CREATE = 'macAddressList/createMacAddressList';
export const ACTION_MAC_ADDRESS_LIST_UPDATE = 'macAddressList/updateMacAddressList';
export const ACTION_MAC_ADDRESS_LIST_DELETE = 'macAddressList/deleteMacAddressList';
//-----------------------------------
export const ACTION_CREATIVES_GET = 'creative/allCreatives';
export const ACTION_CREATIVE_CURRENT_GET = 'creative/currentCreative';
export const ACTION_CREATIVE_UPDATE = 'creative/updateCreative';
export const ACTION_CREATIVE_DELETE = 'creative/deleteCreative';
export const ACTION_CREATIVE_CREATE = 'creative/createCreative';
export const ACTION_CREATIVE_STATUS_PAUSE = 'creative/changeStatusToPause';
export const ACTION_CREATIVE_STATUS_BLOCK = 'creative/changeStatusToBlock';
export const ACTION_CREATIVE_STATUS_ACTIVE = 'creative/changeStatusToActive';
export const ACTION_CREATIVE_GET_FILES = 'creative/allCreativeFiles';
export const ACTION_CREATIVE_DELETE_FILES = 'creative/deleteFiles';
export const ACTION_CREATIVE_CREATE_FILES = 'creative/createFiles';
export const ACTION_CREATIVE_GET_REPORTS = 'creative/allCreativeReports';
export const ACTION_CREATIVE_GET_REPORTS_LIST = 'creative/getCreativesReportsList';
export const ACTION_FORMATS_GET = 'creative/getFormats';

export const ACTION_STRATEGY_GET = 'strategy/fetchStrategies';
export const ACTION_STRATEGY_GET_NAME = 'strategy/getStrategy';
export const ACTION_STRATEGY_UPDATE = 'strategy/updateStrategy';
export const ACTION_STRATEGY_CREATE = 'strategy/createStrategy';
export const ACTION_STRATEGY_DELETE = 'strategy/deleteStrategy';
export const ACTION_STRATEGY_BLOCK = 'strategy/blockStrategy';
export const ACTION_STRATEGY_ACTIVATE = 'strategy/activateStrategy';
export const ACTION_STRATEGY_PAUSE = 'strategy/pauseStrategy';
export const ACTION_STRATEGY_REPORT_GET = 'strategy/fetchReports';
export const ACTION_STRATEGY_REPORTS_LIST_GET = 'strategy/fetchReportsList';
export const ACTION_STRATEGY_GEO_GET = 'strategy/getGeo';
export const ACTION_STRATEGY_DEVICE_TYPES_GET = 'strategy/fetchDeviceTypes';
export const ACTION_STRATEGY_OSES_GET = 'strategy/fetchOses';
export const ACTION_STRATEGY_LANGUAGES_GET = 'strategy/fetchLanguages';
export const ACTION_STRATEGY_APPS_GET = 'strategy/fetchApps';
export const ACTION_STRATEGY_COPY = 'strategy/copyStrategy';

export const ACTION_SYSTEM_CURRENT_REPORT_GET = 'systemReports/fetchCurrentReport';
export const ACTION_SYSTEM_REPORTS_LIST_GET = 'systemReports/fetchReportsList';

export const ACTION_BILLING_TRANSACTION_GET = 'billings/getTransactions';
export const ACTION_BILLING_TRANSACTION_CREATE = 'billings/createTransaction';
export const ACTION_BILLING_TRANSACTION_TRANSFER_CREATE = 'billings/createTransfer';
export const ACTION_BILLING_ORDERS_GET = 'billings/getOrders';
export const ACTION_BILLING_ORDER_CREATE = 'billings/createOrder';
export const ACTION_BILLING_ORDER_DELETE = 'billings/deleteOrder';
export const ACTION_BILLING_ORDER_ACCEPT = 'billings/orderAccept';
export const ACTION_BILLING_ORDER_REJECT = 'billings/orderReject';
export const ACTION_BILLING_ORDER_DONE = 'billings/orderDone';

export const ACTION_AGENCIES_GET = 'settingsAgencies/fetchAllAgencies';
export const ACTION_AGENCIES_GET_AGENCY = 'settingsAgencies/fetchAgency';
export const ACTION_AGENCIES_REMOVE = 'settingsAgencies/removeAgency';
export const ACTION_AGENCIES_UPDATE = 'settingsAgencies/updateAgency';
export const ACTION_AGENCIES_CREATE = 'settingsAgencies/createAgency';
export const ACTION_AGENCIES_USERS_GET = 'settingsAgencies/fetchAllUsers';
export const ACTION_AGENCIES_USERS_POST = 'settingsAgencies/addNewAgenciesUsers';
export const ACTION_AGENCIES_USERS_DELETE = 'settingsAgencies/removeAgenciesUsers';

export const ACTION_ADVERTISERS_GET = 'advertisers/fetchAllAdvertisers';
export const ACTION_ADVERTISER_GET = 'advertisers/fetchAdvertiser';
export const ACTION_ADVERTISERS_REMOVE = 'advertisers/removeAdvertiser';
export const ACTION_ADVERTISERS_UPDATE = 'advertisers/updateAdvertiser';
export const ACTION_ADVERTISERS_CREATE = 'advertisers/createAdvertiser';
export const ACTION_ADVERTISERS_USERS_GET = 'advertisers/fetchAllUsers';
export const ACTION_ADVERTISERS_USERS_POST = 'advertisers/addNewAdvertisersUsers';
export const ACTION_ADVERTISERS_USERS_DELETE = 'advertisers/removeAdvertisersUsers';

export const ACTION_PUBLISHERS_GET = 'publishers/fetchAllPublishers';
export const ACTION_PUBLISHER_GET = 'publishers/fetchPublisher';
export const ACTION_PUBLISHERS_REMOVE = 'publishers/removePublishers';
export const ACTION_PUBLISHERS_UPDATE = 'publishers/updatePublishers';
export const ACTION_PUBLISHERS_CREATE = 'publishers/createPublishers';
export const ACTION_PUBLISHERS_USERS_GET = 'publishers/fetchAllUsers';
export const ACTION_PUBLISHERS_USERS_POST = 'publishers/addNewPublishersUsers';
export const ACTION_PUBLISHERS_USERS_DELETE = 'publishers/removePublishersUsers';

export const ACTION_USERS_GET_USERS = 'users/getAllUsers';
export const ACTION_USERS_GET_USER = 'users/getUser';
export const ACTION_USERS_CREATE = 'users/createUser';
export const ACTION_USERS_UPDATE = 'users/updateUser';
export const ACTION_USERS_DELETE = 'users/deleteUser';
export const ACTION_USERS_ACTIVATE = 'users/blockUser';
export const ACTION_USERS_BLOCK = 'users/activateUser';

export const ACTION_DSP_GET = 'dsp/fethcDspData';
export const ACTION_DSP_CURRENCIES_GET = 'dsp/getCurrencies';
export const ACTION_DSP_REPORTS_GET = 'dsp/fetchDspReports';
export const ACTION_DSP_REPORTS_LIST_GET = 'dsp/fetchDspReportsList';
export const ACTION_DSP_GET_ONE = 'dsp/fetchOneDsp';
export const ACTION_DSP_CREATE = 'dsp/createDsp';
export const ACTION_DSP_UPDATE = 'dsp/updateDsp';
export const ACTION_DSP_DELETE = 'dsp/deleteDsp';
export const ACTION_DSP_ACTIVATE = 'dsp/activateDsp';
export const ACTION_DSP_BLOCK = 'dsp/blockDsp';

export const ACTION_DSPCREATIVE_FETCH = 'dspCreative/fetchDspCreative';
export const ACTION_DSPCREATIVE_BLOCK = 'dspCreative/blockDspCreative';
export const ACTION_DSPCREATIVE_ACTIVATE = 'dspCreative/activeDspCreative';
export const ACTION_DSPCREATIVE_MODERATE = 'dspCreative/moderateDspCreative';
export const ACTION_DSPCREATIVE_UNMODERATE = 'dspCreative/unModerateDspCreative';
export const ACTION_DSPCREATIVE_DELETE = 'dspCreative/deleteDspCreative';
export const ACTION_DSPCREATIVE_UPDATE = 'dspCreative/updateDspCreative';

export const ACTION_SSPS_FETCH = 'ssp/fetchSsps';
export const ACTION_SSPONE_FETCH = 'ssp/fetchSsp';
export const ACTION_SSP_BLOCK = 'ssp/blockSsp';
export const ACTION_SSP_ACTIVATE = 'ssp/activeSsp';
export const ACTION_SSP_CREATE = 'ssp/createSsp';
export const ACTION_SSP_UPDATE = 'ssp/updateSsp';
export const ACTION_SSP_DELETE = 'ssp/deleteSsp';
export const ACTION_SSP_HANDLERS_GET = 'ssp/fetchSspHandlers';
export const ACTION_SSP_REPORTS_GET = 'ssp/fetchSspReports';
export const ACTION_SSP_REPORTS_LIST_GET = 'ssp/fetchSspReportsList';
export const ACTION_SSP_GET_SSP = 'ssp/getSspById';
