<template>
  <el-container>
    <el-dialog
      :title="id ? 'Updating publisher' : 'Creating publisher'"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form
        ref="modalForm"
        v-loading="loading"
        style="position: inherit!important;"
        :model="modalForm"
        :rules="rules"
        name="modalForm"
        class="demo-modalForm"
      >
        <el-form-item
          label="Name"
          class="demo-item"
          prop="name"
        >
          <el-input
            v-model="modalForm.name"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          label="Owner"
          prop="owner"
        >
          <el-autocomplete
            v-model="modalForm.owner"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
          >
            <i
              slot="suffix"
              class="el-icon-edit el-input__icon"
            />
            <template slot-scope="{ item }">
              <div class="value">
                {{ item.value }}
              </div>
              <span class="link">{{ item.link }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          class="item-flex"
        >
          <el-button
            v-if="id"
            type="primary"
            @click="submitForm('update')"
          >
            Save
          </el-button>
          <el-button
            v-else
            type="primary"
            @click="submitForm('create')"
          >
            Create
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import {
  GETTER_PUBLISHERS_DATA,
  ACTION_PUBLISHERS_UPDATE,
  ACTION_PUBLISHERS_CREATE,
  ACTION_USERS_GET_USERS,
  ACTION_PUBLISHERS_GET,
  ACTION_PUBLISHERS_USERS_GET,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AgenciesModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      modalForm: {
        name: '',
        ownerId: null,
        owner: null,
      },
      users: [],
      currentPage: null,
      rules: {
        owner: [
          { required: true, message: 'Fill in the field' },
        ],
        name: [
          { required: true, message: 'Fill in the field' },

        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_DATA,
    }),
  },

  async created () {
    this.loading = true;
    let users = [];
    if (this.id) {
      const res = await this.fetchPublishersUsers({
        params: { id: this.id },
      });
      users = res.data.rows;

      this.getPublishersData.rows.forEach(item => {
        const { id, name, owner } = item;
        if (this.id === id) {
          this.modalForm.name = name;
          this.modalForm.owner = owner.email;
          this.modalForm.ownerId = owner.id;
        }
      });
    } else {
      const res = await this.getAllUsers({
        query: { limit: 0 },
      });
      users = res.data.rows;
    }

    this.users = users.map(user => {
      return {
        id: user.id,
        value: `${user.name} | ${user.email}`,
        email: user.email,
      };
    });
    this.loading = false;
  },

  methods: {
    ...mapActions({
      updatePublishers: ACTION_PUBLISHERS_UPDATE,
      createPublishers: ACTION_PUBLISHERS_CREATE,
      getAllUsers: ACTION_USERS_GET_USERS,
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
      fetchPublishersUsers: ACTION_PUBLISHERS_USERS_GET,
    }),

    // TODO
    querySearch (queryString, cb) {
      const users = this.users;
      const results = queryString ? users.filter(this.createFilter(queryString)) : users;
      cb(results);
    },

    createFilter (queryString) {
      return (user) => {
        return (
          user.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
          user.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelect (item) {
      this.modalForm.ownerId = item.id;
    },

    handleOpen (error) {
      this.$messageBox.alert(
        `${error.msg}`, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    async submitForm (action) {
      this.$refs.modalForm.validate( async (valid) => {
        if (valid) {
          this.loading = true;
          switch (action) {
          case 'update':
            await this.updatePublishers({
              params: { id: this.id },
              data: this.modalForm,
            }).then(res => {
              if (res.data.errors) {
                this.handleOpen(res.data.errors[0]);
              }
              if (res.status === 200) {
                this.$emit('close');
              }
            });
            this.loading = false;
            this.$emit('newPage', this.currentPage);
            break;
          case 'create':
            if (!this.modalForm.name ) {
              this.$messageBox.alert(
                'Fill in the field'
              );
              this.loading = false;
            } else {
              await this.createPublishers({
                data: { name: this.modalForm.name, ownerId: this.modalForm.ownerId },
              }).then(res => {
                if (res.data.errors) {
                  this.handleOpen(res.data.errors[0]);
                }
                if (res.status === 200) {
                  this.$emit('close');
                }
              });
              this.loading = false;
              this.$emit('newPage', this.currentPage);
            }
            break;
          default:
            break;
          }
        }
      });

    },
  },
};
</script>