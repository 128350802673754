<template>
  <div class="dsp">
    <el-button
      type="primary"
      @click="handleEditAdd(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="dspData"
      :column="dspColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="() => $router.push({ name: 'DspReport', params: { id: scope.row.id } })"
            >
              Reports
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-edit"
              @click.native="handleEditAdd(scope.row)"
              divided
            >
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="handleDelete(scope.row.id)"
            >
              Delete
            </el-dropdown-item>
            <el-dropdown-item
              :icon="scope.row.status === 'active' ? 'el-icon-lock' : 'el-icon-unlock'"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ scope.row.status === 'active' ? 'Block' : 'Activate' }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <DspModal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="openModal=false"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DspModal from './DspModal';
import Table from '../../components/Table.vue';
import {
  GETTER_DSP_COLUMNS,
  GETTER_DSP_DATA,
  ACTION_DSP_GET,
  ACTION_DSP_BLOCK,
  ACTION_DSP_ACTIVATE,
  ACTION_DSP_DELETE,
} from '@/const';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  components: {
    Table,
    DspModal,
  },

  data () {
    return {
      openModal: false,
      loading: false,
      formData: null,
    };
  },

  computed: {
    ...mapGetters({
      dspData: GETTER_DSP_DATA,
      dspColumns: GETTER_DSP_COLUMNS,
    }),
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      fethcDspData: ACTION_DSP_GET,
      activateDsp: ACTION_DSP_ACTIVATE,
      blockDsp: ACTION_DSP_BLOCK,
      deleteDsp: ACTION_DSP_DELETE,
    }),

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    async getNewPage (page = 1) {
      this.loading = true;
      await this.fethcDspData({
        query: { page },
      });
      this.loading = false;
    },

    async handleChangeStatus (row) {
      this.loading = true;
      if (row.status === 'active'){
        await this.blockDsp({
          params: { id: row.id },
        });
      } else {
        await this.activateDsp({
          params: { id: row.id },
        });
      }
      await this.getNewPage();
      this.loading = false;
    },

    async handleDelete (id) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        this.loading = true;
        await this.deleteDsp({
          params: { id },
        });
        await this.getNewPage();
      });
      this.loading = false;
    },

    handleEditAdd (row) {
      this.formData = row;
      this.openModal = true;
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.dsp {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: right;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>
