<template>
  <el-aside style="background-color: white; width: 215px">
    <router-link
      to="/"
      tag="h3"
      style="padding-left:20px; cursor: pointer"
    >
      <span style="color: darkgreen; ">Green</span> Grey
    </router-link>
    <el-menu
      v-if="userAccess.length"
      ref="menu"
      :default-active="'/' + $route.path.split('/')[1]"
      @select="select"
    >
      <el-menu-item
        v-for="(item, index) of userAccess"
        :key="index"
        :index="item.route"
      >
        {{ item.title }}
      </el-menu-item>
      <el-submenu
        v-for="(item, index) of userAccessWithSubMenu"
        :key="'B' + index"
        :index="index + ''"
      >
        <template slot="title">
          <span>{{ item.title }}</span>
        </template>
        <el-menu-item
          v-for="(value, innerIndex) of item.subMenu"
          :key="innerIndex"
          :index="value.route"
        >
          {{ value.title }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters } from 'vuex';
import { GETTER_AUTH_USER_ACCESS } from '@/const';

export default {
  name: 'Sidebar',
  data () {
    return{
      isCollapse: false,
    };
  },

  computed: {
    ...mapGetters({
      getUserAccess: GETTER_AUTH_USER_ACCESS,
    }),

    userAccess () {
      return this.getUserAccess.filter(item => !item.subMenu);
    },

    userAccessWithSubMenu () {
      return this.getUserAccess.filter(item => Array.isArray(item.subMenu) && item.subMenu.length > 0);
    },

    metaParent () {
      if (this.$route.meta.parent === 'Billing') {
        return 0;
      }
      if (this.$route.meta.parent === 'Settings') {
        return 1;
      }
      return -1;
    },
  },

  watch: {
    metaParent (val) {
      if (val > -1) {
        this.$refs.menu.open(val);
      }
    },
  },

  methods: {
    select (path) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss">
.el-aside {
  height: inherit;
  min-height: 100vh;
  margin-top: -60px !important;
  background-color: #ffffff !important;
  box-shadow: 0 6px 16px rgba(28, 24, 54, 0.20), 0 0 6px rgba(26, 34, 59, 0.02);
  .el-menu {
    border: none;
    .active{
      color: #66b1ff;
    &:active{
      background-color: white !important;
    }
    }
  }
  &::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #409EFF;
    border-radius: 9em;
    box-shadow: inset 1px 1px 10px #eff5f2;
  }
}
</style>
