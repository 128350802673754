import {
  ACTION_ROOT_REQUEST,
  MUTATION_AUTH_LOGIN,
  MUTATION_AUTH_SIGNOUT,
  URL_AUTH_LOGIN,
  URL_AUTH_REGISTER,
  URL_AUTH_CONFIRM,
  URL_GET_CURRENT_USER,
  MUTATION_AUTH_SET_USER,
  URL_AUTH_SIGHOUT,
  URL_AUTH_RESTORE,
  URL_AUTH_EDIT,
} from '@/const';


const AGENCY = 'agency';
const ADMIN = 'administrator';
const MANAGER = 'manager';
const ADVERTISER = 'advertiser';
const PUBLISHER = 'publisher';

const state = {
  user: null,
  balance: null,
};

const getters = {

  currentUser (state) {
    return state.user;
  },

  balance (state) {
    return state.balance;
  },

  management (state) {
    return state.user.agencies.length ? state.user.agencies[0].management : '';
  },

  userRole (state) {
    switch (state.user.role) {
    case 'administrator':
      return 'administrator';
    case 'manager':
      return 'manager';
    case 'client':
      if (state.user.agencies.length) {
        return 'agency';
      } else if (state.user.advertisers.length) {
        return 'advertiser';
      } else {
        return 'publisher';
      }
    default:
      break;
    }
  },

  getUserAccess () {
    let access = [
      {
        title: 'Campaigns',
        route: '/campaigns',
        access: [ADMIN, MANAGER, ADVERTISER, AGENCY],
      },
      {
        title: 'Apps',
        route: '/apps',
        access: [ADMIN, MANAGER, PUBLISHER],
      },
      {
        title: 'SSP Management',
        route: '/ssp',
        access: [ADMIN, MANAGER],
      },
      {
        title: 'DSP Management',
        route: '/dsp',
        access: [ADMIN, MANAGER],
      },
      {
        title: 'DSP creatives ',
        route: '/dspcreative',
        access: [ADMIN, MANAGER],
      },
      {
        title: 'Reports',
        route: '/reports',
        access: [ADMIN, MANAGER, ADVERTISER, AGENCY, PUBLISHER],
      },
      {
        title: 'Billing',
        access: [ADMIN, MANAGER, PUBLISHER, AGENCY],
        subMenu: [
          {
            title: 'Transactions',
            route: '/transactions',
          },
          {
            title: 'Orders',
            route: '/orders',
            afterValidation: (user, role) => [ADMIN, MANAGER, PUBLISHER].includes(role),
          },
        ],
      },
      {
        title: 'Settings',
        access: [ADMIN, AGENCY],
        subMenu: [
          {
            title: 'Apps list',
            route: '/appsList',
          },
          {
            title: 'Ip-addresses list',
            route: '/ipAddressesList',
          },
          {
            title: 'Domains list',
            route: '/domainsList',
            afterValidation: (user, role) => [ADMIN].includes(role),
          },
          {
            title: 'Mac-addresses list',
            route: '/macAddressList',
          },
          {
            title: 'Users',
            route: '/users',
            afterValidation: (user, role) => [ADMIN].includes(role),
          },
          {
            title: 'Agencies',
            route: '/agencies',
            afterValidation: (user, role) => [ADMIN].includes(role),
          },
          {
            title: 'Advertisers',
            route: '/advertisers',
            afterValidation: (user, role) => {
              if (role !== ADMIN && user.agencies.length > 0) {
                const type = user.agencies[0].management;
                const allowed = ['limited', 'full'];
                return allowed.includes(type);
              }
              return true;

            },
          },
          {
            title: 'Publishers',
            route: '/publishers',
            afterValidation: (user, role) => [ADMIN].includes(role),
          },
        ],
      },
    ];
    if (state.user) {
      let role = state.user.role;
      if (role === 'client') {
        if (state.user.agencies.length) {
          role = AGENCY;
        } else if (state.user.advertisers.length) {
          role = ADVERTISER;
        } else if (state.user.publishers.length) {
          role = PUBLISHER;
        }
      }
      return  access
        .filter(item => item.access.includes(role))
        .map(route => {
          if (!route.subMenu) return route;
          const filteredSubMenu = route.subMenu.filter(sub => 'afterValidation' in sub ? sub.afterValidation(state.user, role) : true);
          return { ...route, subMenu: filteredSubMenu };
        });
    }
    return [];
  },
};

const mutations = {
  login (state, response ) {
    const token = response.token;
    if (token) {
      localStorage.setItem('token', token);
    }
  },

  setBalance (state, response) {
    state.balance = response;
  },

  switchCheck (state, booly) {
    state.user.checked = booly;
  },

  setCurrentUser (state, user) {
    state.user = {
      ...user,
      checked: false,
    };
  },

  logout (state) {
    if (state.user) {
      state.user = null;
      localStorage.removeItem('token');
    }
  },

  edit (state, data) {
    state.user.name = data.name;
    if (data.password) {
      state.user.password = data.password;
    }
  },
};

const actions = {
  loginData: async ({ dispatch }, { data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      url: URL_AUTH_LOGIN,
      data,
      commitName: MUTATION_AUTH_LOGIN,
    }, { root: true });
    return response;
  },

  sendEmail: async ({ dispatch }, { query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_AUTH_RESTORE,
    }, { root: true });
    return response;
  },

  sendNewPassword: async ({ dispatch }, { data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_AUTH_RESTORE,
    }, { root: true });
    if (response.data.errors) {
      return response.data;
    }
    return response;
  },

  register: async (ctx, { data, notify }) => {
    const res = await ctx.dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_AUTH_REGISTER,
      commitName: MUTATION_AUTH_LOGIN,
    }, { root: true });
    if (res.status === 200) {
      notify('success');
    } else if (res.data.errors) {
      return res.data.errors;
    }
  },

  verify: async (ctx, { query }) => {
    const res = await ctx.dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_AUTH_CONFIRM,
    }, { root: true });
    return res;
  },

  getCurrentUser: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_GET_CURRENT_USER,
      commitName: MUTATION_AUTH_SET_USER,
    }, { root: true });
    return res;
  },

  signOut: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      url: URL_AUTH_SIGHOUT,
      commitName: MUTATION_AUTH_SIGNOUT,
    }, { root: true });
    return res;
  },

  edit: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_AUTH_EDIT,
    }, { root: true });
    return res.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
