<template>
  <el-form
    id="vast-video-form"
    ref="form"
    v-loading="loading"
    class="demo-modalForm"
    style="max-height: 90%; margin: 0 auto; width: 100% !important;"
    label-position="top"
    :model="modalForm"
    :disabled="userRole === 'advertiser'"
    :rules="rules"
  >
    <el-form-item
      label="Name"
      class="demo-item"
      prop="name"
    >
      <el-input
        v-model="modalForm.name"
        type="text"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item
      prop="categoryId"
      label="Users Interests"
    >
      <el-tree
        ref="tree"
        :props="treePropsCategory"
        :data="catData"
        show-checkbox
        node-key="id"
        :expand-on-click-node="true"
        highlight-current
        @check="handleNodeClick"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item label="width">
      <el-input
        v-model.number="modalForm.width"
        type="number"
      />
    </el-form-item>
    <el-form-item label="height">
      <el-input
        v-model.number="modalForm.height"
        type="number"
      />
    </el-form-item>
    <el-form-item
      label="Code"
      prop="code"
    >
      <el-input
        ref="code"
        v-model="modalForm.code"
        name="impression"
        type="textarea"
        size="100"
        :rows="20"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item
      class="item-flex"
    >
      <el-button
        v-if="creativeId"
        style="margin-bottom: 20px"
        type="primary"
        @click="() => submitForm('update')"
      >
        Save
      </el-button>
      <el-button
        v-else
        style="margin-bottom: 20px"
        type="primary"
        @click="() => submitForm('create')"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CREATIVE_CURRENT_GET,
  GETTER_APP_TABLE_CATEGORIES,
} from '@/const';

export default {
  name: 'BannerCode',
  props: {
    creativeId: {
      type: Number,
      default: null,
    },
    currentCreative: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    }, 
    rules: {
      type: Object,
      default: () => {},
    },
    userRole: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      modalForm: {
        name: '',
        categoryId: null,
        width: 0,
        height: 0,
        code: null,
      },
      selectedCategory: 'Users Interests',
      treePropsCategory: {
        label: 'name',
        children: 'children',
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: GETTER_APP_TABLE_CATEGORIES,
    }),
    catData () {
      const catData = [{
        name: this.selectedCategory,
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
      }
      for (const [key, value] of Object.entries(this.categories)) {
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },
  },
  created () {
    if (this.creativeId) {
      this.modalForm = this.currentCreative;

      const form = this.currentCreative;

      if (form) {
        if (form.category) {
          this.selectedCategory = form.category.name;
          this.handleNodeClick(form.category);
        }

        this.modalForm = {
          ...form,
          categoryId: form.category ? form.category.id : null,
        };

        delete this.modalForm.category;
        delete this.modalForm.format;
        delete this.modalForm.campaign;
      }
    }
  },
  methods: {
    ...mapActions({
      getCurrentCreative: ACTION_CREATIVE_CURRENT_GET,
    }),
    submitForm (action) {
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          const data = {
            ...this.modalForm,
            code: this.modalForm.code,
          };
          this.$emit('submitForm', { action, commonData: { ...data } });
        } else {
          this.$messageBox.alert('Fill in all required fields!');
        }
      });
    },
    handleNodeClick (data) {
      this.$nextTick(() => {
        if (data.id) {
          this.$refs.tree.setCheckedKeys([]);
          this.selectedCategory = data.name;
          this.$refs.tree.setCheckedKeys([data.id]);
          this.modalForm.categoryId = data.id;
        }
      });
    },
  },
};
</script>