<template>
  <el-container>
    <el-table
      v-if="tableData"
      ref="container"
      v-loading="loading"
      :data="tableData.rows"
      :show-header="true"
      stripe
      :cell-style="cellStyle"
      empty-text="No data"
      style="width: 100%"
      @cell-mouse-enter="(row, column, cell, event) => $emit('mouseOver', row, column, cell, event)"
      @cell-mouse-leave="(row, column, cell, event) => $emit('mouseOut', row, column, cell, event)"
      @cell-click="(row, col, cell, event) => $emit('cellStatus', row, col, cell, event)"
    >
      <el-table-column
        v-for="(item, index) of column"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.width"
        align="center"
      >
        <template
          v-if="item.buttons"
          v-slot="scope"
        >
          <div
            v-if="scope.column.property === 'status'"
            class="status-btn"
            :style="statusColor(scope.row.status)"
          />
          <el-button
            v-else-if="scope.column.property === 'files'"
            icon="el-icon-files"
            @click="showModal(scope.row)"
          />
          <el-icon
            v-else-if="scope.column.property === 'moderated' || scope.column.property === 'token'"
            :class="showCheckIcon(scope) ? 'el-icon-check' : ''"
          />
          <slot
            v-else
            :scope="scope"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="showPagination"
      class="report-table-pagination"
      background
      layout="prev, pager, next"
      :total="totalPage"
      @current-change="getNewPage"
    />
  </el-container>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Object,
      default: null,
    },

    column: {
      type: Array,
      default: null,
    },

    cellstyle: {
      type: Object,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      path: '',
      routeName: '',
    };
  },

  computed: {
    totalPage () {
      const { count, limitPerPage } = this.tableData;
      return Math.ceil(count/limitPerPage) * 10;
    },

    showPagination () {
      return this.tableData && this.tableData.rows && this.tableData.rows.length && this.tableData.count > this.tableData.limitPerPage;
    },

    statusColor () {
      return (color) => {
        switch (color) {
        case 'new':
          return 'background-color: gray';
        case 'active':
          return 'background-color: green';
        case 'paused':
          return 'background-color: yellow';
        case 'blocked':
          return 'background-color: red';
        default:
          return;
        }
      };
    },
  },

  methods: {
    cellStyle ({ row, column }) {
      if (this.cellstyle) {
        const { styles, columnProperty } = this.cellstyle;
        if (column.property === columnProperty) {
          if (typeof styles === 'object') {
            return styles[row[columnProperty]];
          }
          return styles;
        }
      }
    },

    showCheckIcon (scope) {
      if (scope.column.property === 'moderated') {
        return !!scope.row.moderated;
      } else if (scope.column.property === 'token') {
        return !!scope.row.token;
      }
    },

    getNewPage (page) {
      this.$emit('newPage', page);
    },
    showModal (row) {
      this.$emit('showModal', row);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  max-height: calc(100vh - 150px);
  position: absolute;
  right: 0;
  top: 86px;
  left: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;

  .el-breadcrumb {
    & .el-breadcrumb__item {
      font-size: 15px;
    }
  }

  .el-pagination {
    margin-top: 20px;
    text-align: center;
  }

  .status-btn {
    margin: 0 auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  .el-table {
    overflow: auto;
    border: 1px solid #EBEEF5;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 6px;
      background-color: #E7EEF4;;
    }

    &::-webkit-scrollbar-thumb {
      background: #409EFF;
      border-radius: 6px;
    }

    &::before {
      height: 0;
    }
  }
}
</style>
