<template>
  <el-dialog
    title="Transfer transaction"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      style="position: inherit!important;"
    >
      <el-form-item
        label="Direction"
        prop="direction"
      >
        <el-select
          v-model="form.direction"
          placeholder="Direction"
        >
          <el-option
            v-for="item in directionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Advertisers"
        prop="advertiser"
      >
        <el-autocomplete
          v-model="form.advertiser"
          class="inline-input"
          placeholder="Select Advertiser"
          :fetch-suggestions="querySearchAdvertiser"
          @select="(item) => (meta['advertiserId'] = item.id)"
        />
      </el-form-item>
      <el-form-item
        label="Amount"
        prop="amount"
      >
        <el-input
          v-model="form.amount"
          placeholder="Type Number"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Description"
        prop="description"
      >
        <el-input
          v-model="form.description"
          placeholder="Type Description"
          type="text"
        />
      </el-form-item>
      <el-form-item
        class="create-button-item"
      >
        <el-button
          type="primary"
          @click="createTransfer"
        >
          Transfer
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_ADVERTISERS_GET,
  ACTION_BILLING_TRANSACTION_GET,
  GETTER_ADVERTISERS_DATA,
  ACTION_BILLING_TRANSACTION_TRANSFER_CREATE,
  GETTER_AUTH_CURRENT_USER,
} from '@/const';

const TO = 'to_the_advertiser';
const FROM = 'from_the_advertiser';
export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    loading: false,
    meta: {
      advertiserId: null,
    },
    form: {
      direction: null,
      advertiser: null,
      amount: null,
      description: '',
    },
    directionOptions: [
      { label: 'To the advertiser', value: TO },
      { label: 'From the advertiser', value: FROM },
    ],
    rules: {
      direction: [ { required: true, message: 'required' } ],
      advertiserId: [ { required: true, message: 'required' } ],
      amount: [ { required: true, message: 'required' } ],
    },
  }),
  computed: {
    ...mapGetters({
      getAdvertisersData: GETTER_ADVERTISERS_DATA,
      getUserData: GETTER_AUTH_CURRENT_USER,
    }),
    agencyId () {
      const agency = this.getUserData.agencies[0];
      return agency ? agency.id : undefined;
    },
  },

  watch: {
    ['form.advertiser'] (newValue) {
      if (!newValue.trim()) {
        this.meta.advertiserId = null;
      }
    },
  },
  async created () {
    this.loading = true;
    await Promise.allSettled([
      this.fetchAllAdvertisers({ query: { limit: 0 } }),
    ]);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      postTransfer: ACTION_BILLING_TRANSACTION_TRANSFER_CREATE,
      fetchAllAdvertisers: ACTION_ADVERTISERS_GET,
      getTransactions: ACTION_BILLING_TRANSACTION_GET,
    }),

    mapOptions (options) {
      return options.map(option => ({ id: option.id, value: option.name }));
    },

    filterOptions (options, queryString) {
      const createFilter = queryString => link => (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      return queryString ? options.filter(createFilter(queryString)) : options;
    },
    querySearchAdvertiser (queryString, cb) {
      cb(this.filterOptions(this.mapOptions(this.getAdvertisersData.rows), queryString));
    },

    showErrorMessage (error = [{ msg: 'Something went wrong' }]) {
      const message = error.map((item) => ' ' + item.msg + ' ').toString();
      const title = 'Error';
      const options = { confirmButtonText: 'Ok' };

      this.$messageBox.alert(message, title, options);
    },

    async createTransfer () {
      this.$refs.form.validate( async (valid) => {
        if (!valid) await this.$messageBox.alert('Fill required fields');
        else {
          this.loading = true;

          // Если Direction === To the advertiser
          // Запрос = { from: { agency_id:0 }, to: { advertiser_id:0 }, ...}
          // Если Direction === From the advertiser
          // Запрос = { from: { advertiser_id:0 }, to: { agency_id:0 }, ...}

          const fromKey = this.form.direction === TO ? 'agency_id' : 'advertiser_id';
          const fromValue = this.form.direction === TO ? this.agencyId : this.meta.advertiserId;
          const toKey = this.form.direction === FROM ? 'agency_id' : 'advertiser_id';
          const toValue = this.form.direction === FROM ? this.agencyId : this.meta.advertiserId;

          const data = {
            from: {
              [fromKey]: fromValue,
            },
            to: {
              [toKey]: toValue,
            },
            amount: Number(this.form.amount),
            description: this.form.description,
          };
          const response = await this.postTransfer({ data });
          if (response.data.errors) {
            this.showErrorMessage(response.data.errors);
          }
          if (response.status === 200) {
            this.$emit('close');
            this.$emit('updateBalance');
          }
          await this.getTransactions({
            query: { page: this.$route.query.page || 1 },
          });
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 450px;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
  }

  &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
  }

  & .el-autocomplete, .el-input, .el-date-picker, .el-select {
    max-width: 440px !important;
    width: 100% !important;
  }
}

.create-button-item {
  text-align: end;

  .el-form-item__content {
    margin-left: 0 !important;
  }
}
.el-dialog{
  max-width: 500px;
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>