<template>
  <el-container> 
    <el-dialog
      title="Adding user"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form
        v-loading="loading"
        style="position: inherit!important;"
        name="user" 
        class="demo-modalForm"
      >
        <el-form-item
          label="Users mail"
        >
          <el-input 
            v-model="users" 
            type="textarea"
            :rows="3"
            placeholder="Enter user mail"
          />
        </el-form-item>
        <el-form-item
          class="item-flex"
        >
          <el-button
            type="primary"
            @click.prevent="addUsers"
          >
            Add
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTION_AGENCIES_USERS_POST, ACTION_AGENCIES_USERS_DELETE, ACTION_AGENCIES_USERS_GET, GETTER_AGENCIES_USERS_DATA } from '@/const';

export default {
  name: 'AgenciesUsersModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      users: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      getAgenciesUser: GETTER_AGENCIES_USERS_DATA,
    }),
  },

  methods: {
    ...mapActions({
      addNewAgenciesUsers: ACTION_AGENCIES_USERS_POST,
      removeAgenciesUsers: ACTION_AGENCIES_USERS_DELETE,
      fetchAllUsers: ACTION_AGENCIES_USERS_GET,
    }),

    openMessage (
      message = 'Something went wrong',
      type = 'Error'
    ) {
      this.$messageBox.alert(
        message, type, {
          confirmButtonText: 'Ok',
        });
    },
    
    async addUsers () {
      this.loading = true;
      if (!this.users) {
        this.$messageBox.alert( 
          'Fill in all the fields'
        );
      } else {
        const response = await this.addNewAgenciesUsers ({
          params: { id: this.$route.params.id },
          data: { emails: this.users.split('\n') },
        });
        if (response.success) {
          this.openMessage(response.success, '');
        }
        if (response.errors && response.errors.length) {
          let errorUsers = [];
          let count = 0;
          response.errors.forEach(element => {
            this.getAgenciesUser.rows.forEach(item => {
              if (item.email === element.field) {
                count++;
                return;
              }
            });
            if (!count) {
              errorUsers.push(element.field);
            }
          });
          this.users = errorUsers.join('\n');
          let message = `Users (${ errorUsers.join(', ')}) were not found`;
          this.openMessage(message);
        } else {
          this.$emit('close');
        }
        await this.fetchAllUsers({
          params: this.$route.params,
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style>

</style>