<template>
  <div class="compaings">
    <el-select
      v-if="isNotAdvOrPub"
      v-model="selectedAdvertiser"
      class="advertiser-select"
      autocomplete="on"
      filterable
      clearable
      name="Advertiser"
      placeholder="Select Advertiser"
      @change="getNewPage()"
    >
      <el-option
        v-for="advertiserOption in advertisers.rows"
        :key="advertiserOption.id"
        :value="advertiserOption.id"
        :label="advertiserOption.name"
      />
    </el-select>
    <el-button
      v-if="validateUser"
      class="campaigns-add-btn"
      type="primary"
      @click="handleEditAdd(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="tableData"
      :column="tableColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo('report', scope.row.id)"
            >
              Reports
            </el-dropdown-item>
            <el-dropdown-item
              v-if="validateUser"
              @click.native="routeTo('creative', scope.row.id)"
            >
              Creatives
            </el-dropdown-item>
            <el-dropdown-item
              v-if="validateUser"
              @click.native="routeTo('strategy', scope.row.id)"
            >
              Strategies
            </el-dropdown-item>
            <el-dropdown-item
              v-if="validateUser"
              divided
              :icon="userRole !== 'advertiser' ? 'el-icon-edit': ''"
              @click.native="handleEditAdd(scope.row)"
            >
              {{ userRole === 'advertiser'? 'View' :'Edit' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="validateUser"
              icon="el-icon-delete"
              @click.native="handleDelete(scope.row.id)"
            >
              Delete
            </el-dropdown-item>
            <el-dropdown-item
              v-if="['administrator', 'manager'].includes(currentUser.role) || validateUser"
              :disabled="userRole === 'agency' && scope.row.status === 'blocked'"
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <Modal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="closeModal"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>
import Modal from '../Campaigns/Modal.vue';
import Table from '../../components/Table.vue';
import {
  GETTER_COMPANY_TABLE_COLUMNS,
  GETTER_COMPANY_TABLE_DATA,
  ACTION_CAMPAIGNS_GET,
  ACTION_CAMPAIGNS_DELETE,
  GETTER_AUTH_CURRENT_USER,
  ACTION_CAMPAIGNS_CHANGE_STATUS,
  GETTER_AUTH_USER_ROLE,
  GETTER_AUTH_USER_MANAGEMENT,
  GETTER_ADVERTISERS_DATA,
  ACTION_ADVERTISERS_GET,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';
export default {
  components: {
    Modal,
    Table,
  },
  data () {
    return {
      selectedAdvertiser: null,
      openModal: false,
      formData: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      tableColumns: GETTER_COMPANY_TABLE_COLUMNS,
      tableData: GETTER_COMPANY_TABLE_DATA,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
      userManagement: GETTER_AUTH_USER_MANAGEMENT,
      advertisers: GETTER_ADVERTISERS_DATA,
    }),

    validateUser () {
      let show = true;
      if (this.userRole === 'advertiser') show = false;
      if (this.userRole === 'agency' && !['full', 'limited'].includes(this.userManagement)) show = false;
      return show;
    },

    isAdvOrAgency () {
      return this.userRole === 'agency' || this.userRole === 'advertiser';
    },
    isNotAdvOrPub () {
      return this.userRole !== 'advertiser' && this.userRole !== 'publisher';
    },
    getStatusClass () {
      return (item) => {
        const isAgent = this.currentUser.role === 'client';
        switch (item.status) {
        case 'blocked':
          return 'el-icon-lock';
        case 'pause':
          if (isAgent) {
            return 'el-icon-video-play';
          }
          return 'el-icon-unlock';
        case 'activate':
          if (isAgent) {
            return 'el-icon-video-pause';
          }
          return 'el-icon-unlock';
        default:
          return 'el-icon-unlock';
        }
      };
    },
    getStatusType () {
      return (item) => {
        switch (item.status) {
        case 'new':
          return 'Turn on';
        case 'paused':
          return 'Turn on';
        case 'active':
          if (['administrator', 'manager'].includes(this.currentUser.role)) {
            return 'Block';
          }
          return 'Turn off';
        case 'blocked':
          return 'Unlock';
        default:
          break;
        }
      };
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      getCampaigns: ACTION_CAMPAIGNS_GET,
      deleteCampaign: ACTION_CAMPAIGNS_DELETE,
      changeStatus: ACTION_CAMPAIGNS_CHANGE_STATUS,
      getAdvertisers: ACTION_ADVERTISERS_GET,
    }),


    routeTo (routeURL, id) {
      switch (routeURL) {
      case 'creative':
        this.$router.push({ name: 'CampaignsCreative', params: { id } });
        break;
      case 'strategy':
        this.$router.push({ name: 'CampaignsStrategy', params: { id } });
        break;
      case 'report':
        this.$router.push({ name: 'CampaignsReport', params: { id } });
        break;
      default:
        break;
      }
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    async handleDelete (id) {
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        await this.deleteCampaign({
          params: { id },
        });
        // TODO поставть проверки успешности
        await this.getNewPage(this.tableData.currentPage);

      });
    },

    handleEditAdd (row) {
      this.openModal = true;
      this.formData = row;
    },

    async getNewPage (page = 1) {
      this.loading = true;
      const campaignQuery = {
        page,
      };
      if(this.selectedAdvertiser){
        campaignQuery.advertiser_id = this.selectedAdvertiser;
      }
      await this.getCampaigns({
        query: campaignQuery,
      });
      if(this.isNotAdvOrPub){
        await this.getAdvertisers({
          query: { page: -1,limit: 'All' },
        });
      }
      this.loading = false;
    },

    closeModal () {
      this.openModal = false;
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    async handleChangeStatus (row) {
      const statusMap = {
        ['new']: 'activate',
        ['paused']: 'activate',
        ['active']: ['administrator', 'manager'].includes(this.currentUser.role) ? 'block' : 'pause',
        ['blocked']: 'pause',
      };
      const status = statusMap[row.status];

      if (status) {
        await this.changeStatus({
          params: { id: row.id, status },
        });
        this.getNewPage(this.tableData.currentPage);
      }
    },
  },
};
</script>

<style lang="scss">
.compaings {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: right;

  &-table {
    height: inherit;

    .el-table {
      border: 1px solid #EBEEF5;
      width: 100%;
    }
  }
  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
.advertiser-select{
  margin-right:20px;
}
</style>
