<template>
  <div class="users">
    <el-button
      class="users-add-btn"
      type="primary"
      @click="openDialog(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="tableData"
      :column="getUsersColumns"
      :loading="loading"
      @newPage="getNewPage"
      @cellStatus="cellClick"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
    >
      <template
        v-slot="{ scope }"
      >
        <el-popover
          v-if="scope.column.property === 'company'"
          trigger="click"
          style="cursor: pointer;"
          placement="top"
        >
          <p>{{ scope.row.company }}</p>
          <div
            slot="reference"
            class="name-wrapper"
          >
            <el-tag
              class="el-icon-tickets"
              size="medium"
            />
          </div>
        </el-popover>
        <el-dropdown
          v-else
          :ref="'dropdown' + scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="scope.row.status !== 'active'"
              icon="el-icon-unlock"
              @click.native="activateUserBtn(scope.row.id)"
            > 
              Activate
            </el-dropdown-item>
            <el-dropdown-item
              v-if="scope.row.status === 'active'"
              icon="el-icon-lock"
              @click.native="blockUserBtn(scope.row.id)"
            > 
              Block
            </el-dropdown-item>
            <el-dropdown-item
              divided
              icon="el-icon-edit"
              @click.native="openDialog(scope.row.id)"
            > 
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="removeUser(scope.row.id)"
            > 
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <UserModal
      v-if="openModal"
      :open-modal="openModal"
      :user-id="userId"
      :current-page="currentPage"
      @close="openModal=false"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>

import UserModal from './UserModal';
import {
  GETTER_USERS_DATA,
  GETTER_USERS_TABLE,
  ACTION_USERS_GET_USERS,
  ACTION_USERS_DELETE,
  ACTION_USERS_BLOCK,
  ACTION_USERS_ACTIVATE,
  GETTER_AUTH_CURRENT_USER,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  components: {
    UserModal,
    Table,
  },
  data () {
    return {
      openModal: false,
      userId: null,
      currentPage: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getUsersData: GETTER_USERS_DATA,
      getUsersColumns: GETTER_USERS_TABLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),

    tableData () {
      const data = { ...this.getUsersData };
      if (data.rows) {
        data.rows = data.rows.filter(item => {
          return item.id !== this.currentUser.id;
        });
      }
      return data;
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      getAllUsers: ACTION_USERS_GET_USERS,
      deleteUser: ACTION_USERS_DELETE,
      activateUser: ACTION_USERS_ACTIVATE,
      blockUser: ACTION_USERS_BLOCK,
    }),

    async removeUser (id) {
      const { currentPage, rows } = this.getUsersData;
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {         
        await this.deleteUser({
          params: { id },
        });
        if (rows.length - 1) {
          this.getNewPage(currentPage);
        } else {
          this.getNewPage(currentPage - 1);
        }
      });
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    async activateUserBtn (id) {
      const response = await this.activateUser({
        params: { id },
      });
      if (response.data.activated) {
        this.getNewPage(this.currentPage);
      }
    },

    async blockUserBtn (id) {
      const response = await this.blockUser({
        params: { id },
      });
      if (response.data.blocked) {
        this.getNewPage(this.currentPage);
      }
    },

    async getNewPage (page = 1) {
      this.loading = true;
      this.currentPage = page;
      await this.getAllUsers({
        query: { page },
      });
      this.loading = false;
    },

    mouseOver (row, column, cell) {
      if (column.property === 'company' ) {
        this.creatable = true;
      }
      changeOptionsBGMouseOver(row, column, cell);
    },

    mouseOut (row, column, cell) {
      if (column.property === 'company' ) {
        this.creatable = false;
      }
      changeOptionsBGMouseOut(row, column, cell);
    },

    openDialog (id) {
      this.userId = id;
      this.openModal = true;
    },
  },
};

</script>

<style lang="scss">
.users {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
.el-popover {
  max-width: 400px;
  max-height: 200px;
  right: 50px;
  overflow: auto;
  white-space: pre-wrap;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
  }

  &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
  }
}
</style>