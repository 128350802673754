<template>
  <div class="creative">
    <el-button
      v-if="userRole !== 'advertiser'"
      class="creative-add-btn"
      type="primary"
      @click="openDialog(null)"
    >
      Create
    </el-button>
    <Table 
      :table-data="allCurrentCreative"
      :column="creativeTable"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id "
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo(scope.row.id)"
            > 
              Reports
            </el-dropdown-item>
            <el-dropdown-item
              divided
              :icon="userRole !== 'advertiser' ? 'el-icon-edit': ''"
              @click.native="openDialog(scope.row)"
            >
              {{ userRole === 'advertiser'? 'View' :'Edit' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="userRole !== 'advertiser'"
              icon="el-icon-delete"
              @click.native="deleteCreativeRow(scope.row)"
            >
              Delete
            </el-dropdown-item>
            <el-dropdown-item
              v-if="canChangeStatus || currentUser.agency"
              :disabled="userManagement === 'view'"
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <CreativeModal
      v-if="dialogVisible"
      :creative-id="id"
      :dialog-visible="dialogVisible"
      :current-page="page"
      :data="modalData"
      @close="close"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import
{
  GETTER_CREATIVE_CURRENT,
  GETTER_CREATIVE_TABLE,
  GETTER_AUTH_CURRENT_USER,
  ACTION_CREATIVES_GET,
  ACTION_CREATIVE_DELETE,
  ACTION_CREATIVE_STATUS_PAUSE,
  ACTION_CREATIVE_STATUS_BLOCK,
  ACTION_CREATIVE_STATUS_ACTIVE,
  GETTER_AUTH_USER_ROLE,
  GETTER_AUTH_USER_MANAGEMENT,
} from '@/const';
import CreativeModal from './CreativeModal';
import Table from '../../components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  name: 'Creative',
  components: {
    CreativeModal,
    Table,
  },
  data () {
    return {
      dialogVisible: false,
      id: null,
      page: null,
      loading: false,
      modalData: null,
    };
  },
  computed: {
    ...mapGetters ({
      allCurrentCreative: GETTER_CREATIVE_CURRENT,
      creativeTable: GETTER_CREATIVE_TABLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
      userManagement: GETTER_AUTH_USER_MANAGEMENT,
    }),

    getStatusType () {
      return (item) => {
        switch (item.status) {
        case 'new':
          return 'Turn on';
        case 'paused':
          return 'Turn on';
        case 'active':
          if (['administrator', 'manager'].includes(this.currentUser.role)) {
            return 'Block';
          }
          return 'Turn off';
        case 'blocked':
          return 'Unlock';
        default:
          break;
        }
      };
    },

    getStatusClass () {
      return (item) => {
        const isAgent = this.currentUser.role === 'client';
        switch (item.status) {
        case 'blocked':
          return 'el-icon-unlock';
        case 'new': 
          return 'el-icon-unlock';
        case 'paused':
          if (isAgent) {
            return 'el-icon-video-play';
          }
          return 'el-icon-unlock';
        case 'activate':
          if (isAgent) {
            return 'el-icon-video-pause';
          }
          return 'el-icon-unlock';
        default:
          return 'el-icon-lock';
        }
      };
    },

    canChangeStatus () {
      return ['administrator', 'manager', 'client'].includes(this.currentUser.role);
    },
  },
  created () {
    this.getNewPage();
  },
  methods: {
    ...mapActions({
      allCreatives: ACTION_CREATIVES_GET,
      deleteCreative: ACTION_CREATIVE_DELETE,
      changeStatusToPause: ACTION_CREATIVE_STATUS_PAUSE,
      changeStatusToBlock: ACTION_CREATIVE_STATUS_BLOCK,
      changeStatusToActive: ACTION_CREATIVE_STATUS_ACTIVE,
    }),

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    openDialog (row) {
      this.id = row && row.id;
      this.modalData = row;
      this.dialogVisible = true;
    },

    close () {
      this.dialogVisible = false;
    },

    async creative () {
      await this.allCreatives({
        params: { campaign_id: this.$route.params.id },
        query: { page: this.page },
      });
    },

    async getNewPage ( page = 1 ) {
      this.page = page;
      this.loading = true;
      await this.allCreatives({
        params: { campaign_id: this.$route.params.id },
        query: { page },
      });
      this.loading = false;
    },

    async deleteCreativeRow (creative) { 
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        const { status } = await  this.deleteCreative({
          params: {
            id: this.$route.params.id,
            creativeId: creative.id,
          },
        });
        if (status === 200) {
          await this.allCreatives({
            params: { campaign_id: this.$route.params.id },
            query: { page: this.page },
          });
        }
      });
    },

    async handleChangeStatus (row) {
      if (this.canChangeStatus) {
        switch (row.status) {
        case 'blocked' || 'new':
          this.changeStatusToPause({
            params: { id: this.$route.params.id ,creativeId: row.id },
          }).then(response => {
            if (response.data.paused) {
              this.creative();
            }
          });
          break;
        case 'paused': 
          this.changeStatusToActive({
            params: { id: this.$route.params.id ,creativeId: row.id },
          }).then(response => {
            if (response.data.activated) {
              this.creative();
            }
          });
          break;
        case 'active':
          if (this.userRole === 'agency') {
            this.changeStatusToPause({
              params: { id: this.$route.params.id ,creativeId: row.id },
            }).then(response => {
              if (response.data.paused) {
                this.creative();
              }
            });
          } else {
            this.changeStatusToBlock({
              params: { id: this.$route.params.id ,creativeId: row.id },
            }).then(response => {
              if (response.data.blocked) {
                this.creative();
              }
            });

          }
          break;
        default:
          this.changeStatusToActive({
            params: { id: this.$route.params.id ,creativeId: row.id },
          }).then(response => {
            if (response.data.activated) {
              this.creative();
            }
          });
          break;
        }
      }
    },

    routeTo (creativeId) {
      this.$router.push({ name: 'CreativeReport', params: { id: this.$route.params.id, creative_id: creativeId } });
    },
  },
};
</script>

<style lang="scss">
.creative {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>