<template>
  <div>Now home page is empty</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GETTER_AUTH_USER_ROLE, GETTER_AUTH_CURRENT_USER, MUTATION_AUTH_CHECKED } from '@/const';

export default {
  name: 'HomePage',
  computed: {
    ...mapGetters({
      userRole: GETTER_AUTH_USER_ROLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),
  },

  methods: {
    ...mapMutations({
      switchCheck: MUTATION_AUTH_CHECKED,
    }),
  },
};
</script>