<template>
  <el-form
    id="vast-video-form"
    ref="form"
    v-loading="loading"
    class="demo-modalForm"
    style="max-height: 90%; margin: 0 auto; width: 100% !important;"
    label-position="top"
    :model="modalForm"
    :disabled="userRole === 'advertiser'"
    :rules="rules"
  >
    <el-form-item
      label="Name"
      class="demo-item"
      prop="name"
    >
      <el-input
        v-model="modalForm.name"
        type="text"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item
      prop="categoryId"
    >
      <el-tree
        ref="tree"
        :props="treePropsCategory"
        :data="catData"
        show-checkbox
        node-key="id"
        :expand-on-click-node="true"
        highlight-current
        @check="handleNodeClick"
      />
    </el-form-item>
    <el-form-item
      label="Landing URL"
      prop="landingUrl"
    >
      <el-input
        v-model="modalForm.landingUrl"
        type="text"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item
      class="column"
      label="File"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        :file-list="selectedFiles"
        :on-change="(file) => handlePreview(file)"
        :on-preview="openFile"
        action=""
        :before-remove="(file) => beforeRemove(file)"
        :auto-upload="false"
        multiple
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
        >
          Select a zip-file
        </el-button>
      </el-upload>
    </el-form-item>
    <el-form-item label="width">
      <el-input
        v-model.number="modalForm.width"
        type="number"
      />
    </el-form-item>
    <el-form-item label="height">
      <el-input
        v-model.number="modalForm.height"
        type="number"
      />
    </el-form-item>
    <el-form-item
      class="fonts"
      label="Pixels"
    />
    <el-form-item
    label="Impression tracking links"
    >
    <el-input
        v-model="modalForm.params.impTracking"
        type="textarea"
        autosize
        autocomplete="off"
    />
    </el-form-item>
    <el-form-item
    label="Referral clicks links"
    >
    <el-input
        v-model="modalForm.params.clickTracking"
        type="textarea"
        autosize
        autocomplete="off"
    />
    </el-form-item>
    <el-form-item
      class="item-flex"
    >
      <el-button
        v-if="creativeId"
        type="primary"
        @click="() => submitForm('update')"
      >
        Save
      </el-button>
      <el-button
        v-else
        type="primary"
        @click="() => submitForm('create')"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CREATIVE_CREATE_FILES,
  ACTION_CREATIVE_CURRENT_GET,
  GETTER_APP_TABLE_CATEGORIES,
} from '@/const';

export default {
  name: 'HTML5Overlay',
  props: {
    creativeId: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    userRole: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      modalForm: {
        name: '',
        categoryId: null,
        files: [],
        landingUrl: '',
        params: {
          impTracking: '',
          clickTracking: '',
        },
      },
      selectedFiles: [],
      selectedCategory: 'Users Interests',
      treePropsCategory: {
        label: 'name',
        children: 'children',
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: GETTER_APP_TABLE_CATEGORIES,
    }),
    catData () {
      const catData = [{
        name: this.selectedCategory,
        children: [],
        disabled: true,
      }];
      let category = [];
      for (const [key, value] of Object.entries(this.categories)) {
        if (!value.parent) {
          category.push({
            name: value.name,
            children: [],
            id: parseInt(key),
          });
        }
      }
      for (const [key, value] of Object.entries(this.categories)) {
        if (value.parent) {
          const index = category.findIndex(item => item.id === value.parent.id);
          if (index > -1) {
            category[index].children.push(
              {
                id: parseInt(key),
                name: value.name,
              }
            );
          }
        }
      }
      catData[0].children = category;
      return catData;
    },
  },
  async created () {
    if (this.creativeId) {
      const response = await this.getCurrentCreative({
        params: { id: this.$route.params.id, creativeId: this.creativeId },
      });
      this.modalForm = response.data;
      const form = response.data;

      delete form.budget;
      delete form.budgetToday;
      delete form.clicks;
      delete form.clicksToday;
      delete form.created;
      delete form.imps;
      delete form.impsToday;
      delete form.status;
      delete form.updated;
      delete form.lineItems;
      delete form.id;

      const file = Object.values(form.files)[0];

      this.setFileData({
        fileId: file.id,
        fileUrl: file.url,
        fileData: [{
          name: file.name,
          url: file.url,
          id: file.id,
        }],
      });

      if (form.params.impTracking) {
        this.modalForm.params.impTracking = form.params.impTracking.join('\n');
      }
      if (form.params.clickTracking) {
        this.modalForm.params.clickTracking = form.params.clickTracking.join('\n');
      }


      if (form) {

        if (form.category) {
          this.handleNodeClick(form.category);
        }

        this.modalForm = {
          ...form,
          categoryId: form.category ? form.category.id : null,
          files: Object.values(form.files).map(file => file.id),
        };

        delete this.modalForm.category;
        delete this.modalForm.format;
        delete this.modalForm.campaign;
      }
    }
  },
  methods: {
    ...mapActions({
      getCurrentCreative: ACTION_CREATIVE_CURRENT_GET,
      createFiles: ACTION_CREATIVE_CREATE_FILES,
    }),
    submitForm (action) {
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          this.$emit('submitForm', {
            action,
            commonData: { ...this.modalForm, params: {
              impTracking: this.modalForm.params.impTracking.split('\n').filter(i => !!i),
              clickTracking: this.modalForm.params.clickTracking.split('\n').filter(i => !!i),
            } },
          });
        } else {
          this.$messageBox.alert(
            'Fill in all required fields!'
          );
        }
      });
    },

    handleNodeClick (data) {
      this.$nextTick(() => {
        if (data.id) {
          this.$refs.tree.setCheckedKeys([]);
          this.selectedCategory = data.name;
          this.$refs.tree.setCheckedKeys([data.id]);
          this.modalForm.categoryId = data.id;
        }
      });
    },

    setFileData ({ fileId, fileData }) {
      if (!fileId) {
        this.$emit('pushFileForDelete', this.selectedFiles[0].id);
      }
      this.selectedFiles = fileData || [];
    },

    async handlePreview (file) {
      this.$emit('setLoad', true);
      let formData = new FormData();

      formData.append('file', file.raw);
      formData.append('name', file.name);

      const response = await this.createFiles({
        data: formData,
      });

      if (!response.data.errors) {
        const fileId = response.data.id;
        this.modalForm.files = [parseInt(fileId)];
        this.setFileData({
          fileId: parseInt(fileId),
          fileUrl: response.data.url,
          fileData: [{
            name: file.name,
            url: response.data.url,
            id: fileId,
          }],
        });
      } else {
        this.$messageBox.alert(
          'Invalid file!'
        );
      }
      this.$emit('setLoad', false);
    },
    openFile ({ url }) {
      if (this.creativeId) {
        window.open(url);
      }
    },
    beforeRemove (file) {
      return this.$messageBox.confirm(`Delete file: ${ file.name } ?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then( async () => {
        if (this.creativeId) {
          this.$emit('pushFileForDelete', file.id);
        }
      });
    },
  },
};
</script>