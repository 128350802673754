<template>
  <div class="sistem-report">
    <el-form
      :inline="true"
      :model="form"
      label-position="top"
      class="sistem-report-form"
    >
      <el-form-item 
        label="Select report"
      >
        <el-select
          v-model="form.report"
          placeholder="Select"
        >
          <el-option
            v-for="item in filter.reports"
            :key="item.name"
            :label="item.title"
            :value="item.name"
            @click.native="selectedItem(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Select date range">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="Before"
          start-placeholder="Start date"
          end-placeholder="End date"
        />
      </el-form-item>
      <el-form-item class="apply-btn">
        <el-button
          :disabled="!form.report"
          type="primary"
          @click="onSubmit"
        >
          Apply
        </el-button>
      </el-form-item>
    </el-form>
    <ReportTable
      :report-data="getCurrentReport"
      @goToPage="getNewPage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import ReportTable from '@/components/ReportTable';
import { mapActions, mapGetters } from 'vuex';
import {
  GETTER_SYSTEM_REPORT,
  ACTION_SYSTEM_CURRENT_REPORT_GET,
  ACTION_SYSTEM_REPORTS_LIST_GET,
  GETTER_SYSTEM_REPORTS_LIST,
} from '@/const';
import { getReportQuery } from '@/utils';

export default {
  name: 'SystemReports',
  components: {
    ReportTable,
  },
  data () {
    return {
      data: null,
      filter: {
        date: moment(),
        reports: [],
      },
      form: {
        date: [moment().subtract(1, 'month').format('YYYY, MM, DD'), moment().format('YYYY, MM, DD')],
        report: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentReport: GETTER_SYSTEM_REPORT,
      reportsList: GETTER_SYSTEM_REPORTS_LIST,
    }),
  },
  async created () {
    await this.fetchReportsList();
    this.filter.reports = this.reportsList.rows;
    this.form.report = this.reportsList.rows[0].name;

    await this.fetchCurrentReport({
      params: {
        reportName: this.reportsList.rows[0].name,
      },
      query: getReportQuery(this),
    });
  },
  methods: {
    ...mapActions({
      fetchCurrentReport: ACTION_SYSTEM_CURRENT_REPORT_GET,
      fetchReportsList: ACTION_SYSTEM_REPORTS_LIST_GET,
    }),

    async onSubmit () {
      await this.fetchCurrentReport({
        params: {
          reportName: this.form.report,
        },
        query: getReportQuery(this),
      });
    },

    async getNewPage (page) {
      await this.fetchCurrentReport({
        params: {
          reportName: this.form.report,
        },
        query: getReportQuery(this, page),
      });
    },

    selectedItem (item) {
      this.form.report = item.name;
    },
  },
};
</script>

<style lang="scss">
.sistem-report {
 width: 100%;
 position: relative;
 padding-top: 50px;

 .sistem-report-form{
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    top: 50px;
    position: absolute;
    right: 200px;
    z-index: 2;
 }

  &-form {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: 41px;
    right: 200px;
    z-index: 2;

    .el-form-item {
      margin-right: 10px;
      text-align: left;

      &__label{
        line-height: 0;
      }
    }
  }
}
</style>