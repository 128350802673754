<template>
  <div class="apps">
    <el-button
      class="apps-add-btn"
      type="primary"
      @click="handleEditAdd(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="data"
      :column="table"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo('report', scope.row.id)"
            >
              Reports
            </el-dropdown-item>
            <el-dropdown-item
              divided
              icon="el-icon-edit"
              @click.native="handleEditAdd(scope.row)"
            >
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="handleDelete(scope.row.id)"
            >
              Remove
            </el-dropdown-item>
            <el-dropdown-item
              v-if="['administrator', 'manager'].includes(currentUser.role) "
              :disabled="currentUser.role === 'client'&& scope.row.status === 'blocked'"
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <Modal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      :current-page="currentPage"
      @close="openModal=false"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>
import Modal from '../Apps/Modal.vue';
import {
  GETTER_APP_TABLE_COLUMNS,
  GETTER_APP_TABLE_DATA,
  ACTION_APPS_GET,
  GETTER_AUTH_CURRENT_USER,
  ACTION_APPS_STATUS_PAUSE,
  ACTION_APPS_CHANGE_ACTIVATE,
  ACTION_APPS_DELETE,
  ACTION_APPS_CHANGE_BLOCK,
  ACTION_PUBLISHERS_GET,
  ACTION_APPS_GET_CATEGORIES,
  GETTER_AUTH_USER_ROLE,
} from '@/const';
import { mapGetters, mapActions } from 'vuex';
import Table from '../../components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  components: {
    Modal,
    Table,
  },
  data () {
    return {
      openModal: false,
      formData: {},
      table: null,
      loading: false,
      currentPage: null,
    };
  },
  computed: {
    ...mapGetters({
      tableColumns: GETTER_APP_TABLE_COLUMNS,
      tableData: GETTER_APP_TABLE_DATA,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
    }),
    data () {
      const rows = this.tableData.rows || [];
      return {
        ...this.tableData,
        rows: rows.map(item => {
          item.dsp = item.dsps.reduce((result, i) => {
            result.push(i.name);
            return result;
          }, []).join(', ');
          return item;
        }),
      };
    },

    tableTitle () {
      return () => {
        const primaryAccs = ['administrator', 'manager'];

        if (this.userRole === 'publisher') {
          this.table = this.tableColumns.filter(col => col.label !== 'Publisher');
        } else if (primaryAccs.includes(this.userRole)) {
          this.table = this.tableColumns;
        }
      };
    },

    getStatusClass () {
      return (item) => {
        const isAgent = this.currentUser.role === 'client';
        switch (item.status) {
        case 'blocked':
          return 'el-icon-lock';
        case 'pause':
          if (isAgent) {
            return 'el-icon-video-play';
          }
          return 'el-icon-unlock';
        case 'activate':
          if (isAgent) {
            return 'el-icon-video-pause';
          }
          return 'el-icon-unlock';
        default:
          return 'el-icon-unlock';
        }
      };
    },

    getStatusType () {
      return (item) => {
        const primaryAccs = ['administrator', 'manager'];
        switch (item.status) {
        case 'new':
          return 'Turn on';
        case 'paused':
          return 'Turn on';
        case 'active':
          if (primaryAccs.includes(this.userRole)) {
            return 'Block';
          }
          return 'Turn off';
        case 'blocked':
          return 'Unlock';
        default:
          break;
        }
      };
    },
  },

  async created () {
    this.tableTitle();
    this.getNewPage();
    await this.fetchAllPublishers({
      query: { limit: 0 },
    });
    await this.getCategories();
  },

  methods: {
    ...mapActions({
      getApps: ACTION_APPS_GET,
      pauseStatus: ACTION_APPS_STATUS_PAUSE,
      activateStatus: ACTION_APPS_CHANGE_ACTIVATE,
      blockStatus: ACTION_APPS_CHANGE_BLOCK,
      deleteApp: ACTION_APPS_DELETE,
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
      getCategories: ACTION_APPS_GET_CATEGORIES,
    }),

    async getNewPage (page = 1) {
      this.loading = true;
      await this.getApps({
        query: { page },
      });
      this.loading = false;
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    routeTo (routeURL, id) {
      switch (routeURL) {
      case 'report':
        this.$router.push({ name: 'AppsReport', params: { id } });
        break;
      default:
        break;
      }
    },

    handleEditAdd (row) {
      this.openModal = true;
      this.formData = row;
    },
    async handleChangeStatus (row) {
      if (['administrator', 'manager'].includes(this.currentUser.role)) {
        if (row.status === 'blocked') {
          await this.pauseStatus({
            params: { id: row.id },
          });
        } else if (row.status === 'new' || row.status === 'paused') {
          await this.activateStatus({
            params: { id: row.id },
          });
        } else {
          await this.blockStatus({
            params: { id: row.id },
          });
        }
      } else {
        if (row.status === 'paused' || row.status === 'new') {
          await this.activateStatus({
            params: { id: row.id },
          });
        }
        if (row.status === 'active') {
          await this.activateStatus({
            params: { id: row.id },
          });
        }
      }
      this.getNewPage(this.tableData.currentPage);
    },

    handleDelete (id) {
      const { currentPage, rows } = this.tableData;
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        await this.deleteApp({
          params: { id },
        });
        if (rows.length - 1) {
          this.getNewPage(currentPage);
        } else {
          this.getNewPage(currentPage - 1);
        }
      }).catch(() => {});
    },
  },
};

</script>

<style lang="scss">
.apps {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: right;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>
