<template>
  <div class="restore-block">
    <h3 class="restore-title">
      New password
    </h3>
    <div
      v-loading="load"
      element-loading-background="transparent"
    >
      <el-form 
        :model="restore"
        status-icon 
      >
        <el-form-item>
          <FormInput 
            :label="'Password'"
            :type="'password'"
            :name="'firstPass'"
            :is-error="this.$v.restore.pass.$error"
            :is-password="true"
            :error-msg="errorMinLength"
            @onBlur="onBlur"
          />
        </el-form-item>
        <el-form-item>
          <FormInput 
            :label="'Repeat new password'"
            :type="'password'"
            :name="'secondPass'"
            :is-error="this.$v.restore.checkPass.$error"
            :is-password="true"
            :error-msg="errorSame"
            @onBlur="onBlur"
          />
        </el-form-item>
        <el-form-item class="btn-center">
          <el-button 
            type="primary"
            @click="submitForm"
          >
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>  
</template>

<script>
import { ACTION_AUTH_NEWPASS } from '@/const';
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import FormInput from '@/components/inputs/formInput.vue';

export default {
  components: {
    FormInput,
  },
  mixins: [validationMixin],
  data () {
    return {
      restore: {
        pass: '',
        checkPass: '',
      },
      errorMinLength: '',
      errorSame: '',
      newPassword: '',
      load: false,
    };
  },
  validations: {
    restore: {
      pass: {
        required,
      },
      checkPass: {
        sameAsPassword: sameAs('pass'),
      },
    },
  },

  mounted () {
    this.$v.$reset();
  },

  methods: {
    ...mapActions({
      sendNewPassword: ACTION_AUTH_NEWPASS,
    }),

    onBlur (input) {
      if (input.name === 'firstPass') {
        this.restore.pass = input.val;
      } else if (input.name === 'secondPass') {
        this.restore.checkPass = input.val;
      }
    },

    async submitForm () {
      this.$v.$touch();
      
      if (this.$v.restore.pass.$error) {
        this.errorMinLength = 'Password at least 5 characters';
      } else if (this.$v.restore.checkPass.$error) {
        this.errorSame = 'Passwords must match';
      }

      if (!this.$v.$invalid) {
        this.newPassword = this.restore.checkPass;
        const response = await this.sendNewPassword({
          data: { password: this.newPassword, key: this.$route.query.key },
        });

        if (response.errors) {
          response.errors.forEach(item => {
            this.errorMsg = item.msg;
          });
        }

        this.load = true;
        // TODO
        const text = !response.errors ? 'Your password has been successfully saved, you can sign in' : '';

        this.$messageBox.alert(
          !response.errors ? text : this.errorMsg || 'Try again', '', {
            type: !response.errors ? 'success' : 'error',
            confirmButtonText: 'OK',
            callback: () => {
              this.restore.pass = this.restore.checkPass = '';
              this.$v.$reset();
              this.load = false;
              this.$router.push('/login');
            },
          });
      }
    },
  },
};

</script>

<style lang="scss">
.restore-block {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(28, 24, 54, 0.12), 0 0 6px rgba(26, 34, 59, 0.04);

  & .restore-title {
    text-align: center;
    letter-spacing: 2px;
    color: #606266;
    text-transform: uppercase;
  }

  & .btn-center {
    display: flex;
    justify-content: center;
  }

}
  .el-message-box__headerbtn {
    display: none;
  }
</style>