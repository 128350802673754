<template>
  <div class="basic">
    <Header
      v-if="currentUser"
      :state-arrow="$store.state.sidebarIsOpen"
    />
    <div class="content">
      <transition
        name="list"
      >
        <Sidebar
          v-show="$store.state.sidebarIsOpen && currentUser"
          class="sidebarAnimation"
        />
      </transition>
      <el-breadcrumb
        separator="/"
      >
        <el-breadcrumb-item
          v-for="(item) of routes"
          :key="item"
          :to="pushUrl(item)"
        >
          {{ names[item] }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <router-view
        v-if="currentUser"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from '../views/Sidebar';
import Header from '../views/Header';
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_AUTH_CURRENT_USER,
  GETTER_AUTH_CURRENT_USER,
  ACTION_PUBLISHER_GET,
  GETTER_CREATIVE_CURRENT,
  ACTION_AGENCIES_GET_AGENCY,
  ACTION_ADVERTISER_GET,
  GETTER_STRATEGY_CURRENT,
  GETTER_AUTH_USER_ROLE,
  ACTION_CAMPAIGN_GET,
  ACTION_CREATIVE_CURRENT_GET,
  ACTION_STRATEGY_GET_NAME,
  ACTION_APPS_GET_APP,
  ACTION_SSP_GET_SSP,
  GETTER_AUTH_USER_ACCESS,
} from '@/const';
import { names } from '@/const/breadCrumbs';

export default {
  components: {
    Sidebar,
    Header,
  },
  data () {
    return {
      isOpen: true,
      routes: [],
      routeName: '',
      names: null,
    };
  },

  computed: {
    ...mapGetters({
      getUserAccess: GETTER_AUTH_USER_ACCESS,
      currentUser: GETTER_AUTH_CURRENT_USER,
      allCurrentCreative: GETTER_CREATIVE_CURRENT,
      tableData: GETTER_STRATEGY_CURRENT,
      userRole: GETTER_AUTH_USER_ROLE,
    }),
  },

  watch: {
    $route () {
      this.getRoute();
    },
  },

  async created () {
    this.names = { ...names };
    await this.getCurrentUser();

    const path = this.getUserAccess[0].route;
    if (this.$router.currentRoute.path === '/') {
      await this.$router.push(path);
    }

    this.getRoute();
  },

  methods: {
    ...mapActions({
      getCurrentUser: ACTION_AUTH_CURRENT_USER,
      campaigns: ACTION_CAMPAIGN_GET,
      getCreative: ACTION_CREATIVE_CURRENT_GET,
      getStrategy: ACTION_STRATEGY_GET_NAME,
      publishers: ACTION_PUBLISHER_GET,
      advertisers: ACTION_ADVERTISER_GET,
      agencies: ACTION_AGENCIES_GET_AGENCY,
      apps: ACTION_APPS_GET_APP,
      ssp: ACTION_SSP_GET_SSP,
    }),

    pushUrl (item) {
      const index = this.$route.path.indexOf(item) + item.length;
      return this.$route.path.slice(0, index) === this.$route.path ? null : { path: this.$route.path.slice(0, index) };
    },

    async getRoute () {
      const { path, meta, params } = this.$route;
      const routePath = path.split('/');
      this.routes = routePath.filter((item) => {
        if (names[item]) {
          return item;
        }
      });

      if (params.id && meta.routeParent) {
        const { data } = await this[meta.routeParent]({
          params: { id: params.id },
        });
        this.names[meta.routeParent] = `${names[meta.routeParent]}: ${data.name}`;
      } else {
        this.names = { ...names };
      }

      // TODO
      if (params.creative_id) {
        const { data } = await this.getCreative({
          params: { id: params.id, creativeId: params.creative_id },
        });
        this.names.creative = `Creative: ${data.name}`;
      } else {
        this.names.creative = names.creative;
      }

      if (params.strategy_id) {
        const { data } = await this.getStrategy({
          params: { campaign_id: params.id, id: params.strategy_id },
        });
        this.names.strategy = `Strategy: ${data.name}`;
      } else {
        this.names.strategy = names.strategy;
      }
    },
  },
};
</script>

<style lang="scss">
.basic {
  width: 100%;
  .content {
    display: flex;
    position: relative;

    .el-breadcrumb {
      z-index: 2;
      position: absolute;
      left: 300px;
      top: 35px;
    }
  }

  .list-enter-active, .list-leave-active {
    transition: all 0.4s;
  }

  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(-300px) ;
  }

}
</style>
