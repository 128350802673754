<template>
  <el-form
    v-loading="loading"
    :model="form"
    class="demo-modalForm"
    :disabled="userRole === 'advertiser'"
    style="height: 100%; width: 100% !important; margin: 0 auto; max-width: 100%"
  >
    <el-form-item
      label="Format"
      prop="formatId"
      class="demo-item"
    >
      <el-select
        v-model="form.formatId"
        @change="changeFormat"
      >
        <el-option
          v-for="item in formatOptions"
          :key="item.value"
          :label="item.value"
          :value="item.id"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'Format',
  props: {
    formatOptions: {
      type: Array,
      default: () => [],
    },
    userRole: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        formatId: null,
      },
    };
  },
  methods: {
    changeFormat (id) {
      this.$emit('changeFormatId', id);
    },
  },
};

</script>