import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import campaigns from './modules/campaigns';
import creative from './modules/creative';
import strategy from './modules/strategy';
import systemReports from './modules/systemReports';
import settingsAgencies from './modules/settingsAgencies';
import advertisers from './modules/advertisers';
import publishers from './modules/publishers';
import billings from './modules/billings';
import users from './modules/users';
import apps from './modules/apps';
import dsp from './modules/dsp';
import dspCreative from './modules/dspCreative';
import ssp from './modules/ssp';
import axios from 'axios';
import { getUrl } from '@/utils';
import router from '@/router';
import { VueInstance } from '@/main';

import { BASE_URL } from '@/const';
import appsList from '@/store/modules/appsList';
import domainsList from '@/store/modules/domainsList';
import ipAddressesList from '@/store/modules/ipAddressesList';
import macAddressList from '@/store/modules/macAddressList';

const api = axios.create({
  baseURL: BASE_URL,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebarIsOpen: true,
  },
  mutations: {},
  actions: {
    http: async (
      { commit },
      { url, method, params = {}, data = {}, commitName, query = {} }
    ) => {
      url = getUrl(url, params);
      const token = localStorage.getItem('token') || '';
      let response = null;
      try {
        switch (method) {
        case 'GET':
          response = await api({
            method: 'GET',
            url,
            data,
            params: query,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'POST':
          response = await api({
            method: 'POST',
            url,
            data,
            params: query,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'PUT':
          response = await api({
            method: 'PUT',
            url,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'DELETE':
          response = await api({
            method: 'DELETE',
            url,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'PATCH':
          response = await api({
            method: 'PATCH',
            url,
            data,
            params: query,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        default:
          break;
        }
      } catch (e) {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          router.push('/login').catch(() => {
          });
        }
        response = e.response;
        // TODO check on 401 error
      }
      if (
        response &&
        response.data &&
        response.data.errors &&
        response.data.errors.length
      ) {
        let text = '';
        if (response.status === 500) {
          text = 'Server error';
        }
        if (text) {
          VueInstance.$notify.error({
            title: 'Error',
            message: text,
            dangerouslyUseHTMLString: true,
            position: 'top-left',
          });
        }
      }
      if (commitName && response) {
        commit(commitName, response.data);
      }
      return response;
    },
  },
  modules: {
    auth,
    campaigns,
    creative,
    strategy,
    systemReports,
    billings,
    apps,
    settingsAgencies,
    users,
    advertisers,
    publishers,
    dsp,
    dspCreative,
    ssp,
    appsList,
    macAddressList,
    domainsList,
    ipAddressesList,
  },
});
