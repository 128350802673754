<template>
  <div class="agencies">
    <el-button
      class="agencies-add-btn"
      type="primary"
      @click="openDialog(null)"
    >
      Create
    </el-button>
    <Table
      :table-data="getAgenciesData"
      :column="getAgenciesColumns"
      :cellstyle="cellStyle"
      :loading="loading"
      @newPage="getNewPage"
      @cellStatus="cellUser"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo(scope.row.id)"
            >
              Viewing a list of users
            </el-dropdown-item>
            <el-dropdown-item
              divided
              icon="el-icon-edit"
              @click.native="openDialog(scope.row.id)"
            > 
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deleteAgency(scope.row.id)"
            > 
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <AgenciesModal
      v-if="dialogVisible"
      :agency-id="id"
      :dialog-visible="dialogVisible"
      @close="close"
    />
  </div>
</template>

<script>

import { GETTER_AGENCIES_DATA, GETTER_AGENCIES_TABLE, ACTION_AGENCIES_REMOVE, ACTION_AGENCIES_GET } from '@/const';
import { mapActions, mapGetters } from 'vuex';
import AgenciesModal from './AgenciesModal.vue';
import Table from '@/components/Table.vue';

export default {
  name: 'Agencies',
  components: {
    AgenciesModal,
    Table,
  },
  data () {
    return {
      dialogVisible: false,
      id: null,
      ownerData: null,
      currentPage: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getAgenciesData: GETTER_AGENCIES_DATA,
      getAgenciesColumns: GETTER_AGENCIES_TABLE,
    }),
    
    cellStyle () {
      return{
        columnProperty: 'userCount',
        styles: 'cursor: pointer; color: #409EFF',
      };
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      fetchAllAgencies: ACTION_AGENCIES_GET,
      removeAgency: ACTION_AGENCIES_REMOVE,
    }),

    async getNewPage (page = 1) {
      this.loading = true;
      this.currentPage = page;
      const response = await this.fetchAllAgencies({
        query: { page },
      });
      if (response.rows.length) {
        response.rows.forEach(item => {
          this.ownerData = item.owner;
        });
      }
      this.loading = false;
    },

    

    close () {
      this.dialogVisible = false;
    },

    mouseOver (row, column, cell) {
      if (column.property === 'userCount' || column.property === 'options') {
        cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
      }
    },

    mouseOut (row, column, cell) {
      if (column.property === 'userCount' || column.property === 'options') {
        cell.style = 'background-color: none; color: #409EFF';
      }
    },

    openDialog (id) {
      this.id = id;
      this.dialogVisible = true;
    },

    async deleteAgency (id) {
      const { currentPage, rows } = this.getAgenciesData;
      this.$messageBox.confirm(
        'Do you really want to delete', '', {
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then(async () => {
        await this.removeAgency({
          params: { id },
        });
        if (rows.length - 1) {
          this.getNewPage(currentPage);
        } else {
          this.getNewPage(currentPage - 1);
        }
      });
    },

    cellUser (row, col) {
      if (col.property === 'userCount') {
        this.routeTo(row.id);
      }
      if (col.property === 'options') {
        if (col.property === 'options') {
          this.$refs['dropdown' + row.id].visible
            ? this.$refs['dropdown' + row.id].hide()
            : this.$refs['dropdown' + row.id].show();
        }
      }
    },

    routeTo (agencyId) {
      this.$router.push({ name: 'AgencyUsers', params: { id: agencyId } });
    },
  },
};

</script>

<style lang="scss">
.agencies {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>