<template>
  <el-container> 
    <el-dialog
      title="Adding user"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form
        v-loading="loading"
        style="position: inherit!important;"
        name="user" 
        class="demo-modalForm"
      >
        <el-form-item
          label="Users mail"
        >
          <el-input 
            v-model="users" 
            type="textarea"
            :rows="3"
            placeholder="Enter user mail"
          />
        </el-form-item>
        <el-form-item
          class="item-flex"
        >
          <el-button
            type="primary"
            @click.prevent="addUsers"
          >
            Add
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_PUBLISHERS_USERS_POST,
  GETTER_PUBLISHERS_USERS_DATA,
} from '@/const';

export default {
  name: 'AgenciesUsersModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      users: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      getPublishersUsersData: GETTER_PUBLISHERS_USERS_DATA,
    }),
  },

  methods: {
    ...mapActions({
      addNewPublishersUsers: ACTION_PUBLISHERS_USERS_POST,
    }),

    openMessage (
      message = 'Something went wrong',
      type = 'Error'
    ) {
      this.$messageBox.alert(
        message, type, {
          confirmButtonText: 'Ok',
        });
    },
    
    async addUsers () {
      const res = await this.addNewPublishersUsers ({
        params: { id: this.$route.params.id },
        data: { emails: this.users.split('\n') },
      });
      
      if (res.data.errors) {
        let errorUsers = [];
        let count = 0;
        res.data.errors.forEach(element => {
          this.getPublishersUsersData.rows.forEach(item => {
            if (item.email === element.field) {
              count++;
              return;
            }
          });
          if (!count) {
            errorUsers.push(element.field);
          }
        });
        this.users = errorUsers.join('\n');
        let message = `Users (${ errorUsers.join(', ')}) were not found`;
        this.openMessage(message);
      } else {
        this.$emit('close');
        this.openMessage('Users added successfully', '');
      }
    },
  },
};
</script>

<style>

</style>