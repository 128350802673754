<template>
  <div class="publishers">
    <el-button
      class="publishers-add-btn"
      type="primary"
      @click="openDialog"
    >
      Add user
    </el-button>
    <Table
      :table-data="usersData"
      :column="getPublishersColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id"
          trigger="click"
          :disabled="ownerId === scope.row.id"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deletePublisher(scope.row.id)"
            > 
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <PublishersUsersModal
      v-if="dialogVisible"
      :id="id"
      :dialog-visible="dialogVisible"
      @close="close"
    />
  </div>
</template>

<script>

import 
{ 
  ACTION_PUBLISHERS_USERS_GET, 
  GETTER_PUBLISHERS_USERS_DATA, 
  GETTER_PUBLISHERS_USERS_TABLE,
  ACTION_PUBLISHERS_USERS_DELETE,
  ACTION_PUBLISHER_GET,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import PublishersUsersModal from './PublishersUsersModal.vue';
import Table from '@/components/Table.vue';
import { changeOptionsBGMouseOut, changeOptionsBGMouseOver } from '@/utils';

export default {
  name: 'AgencyUsers',
  components: {
    PublishersUsersModal,
    Table,
  },

  data () {
    return {
      dialogVisible: false,
      id: null,
      loading: false,
      ownerId: null,
    };
  },

  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_USERS_DATA,
      getPublishersColumns: GETTER_PUBLISHERS_USERS_TABLE,
    }),

    usersData () {
      let data = { ...this.getPublishersData };
      data.rows = data.rows ? data.rows.filter(item => item.id !== this.ownerId) : [];
      return data;
    },
  },

  async created () {
    const { data } = await this.fetchPublisher({
      params: { id: parseInt(this.$route.params.id) },
    });
    if (data) {
      this.ownerId = data.owner.id;
    }
    await this.getNewPage();
  },

  methods: {
    ...mapActions({
      fetchAllUsers: ACTION_PUBLISHERS_USERS_GET,
      removePublishersUsers: ACTION_PUBLISHERS_USERS_DELETE,
      fetchPublisher: ACTION_PUBLISHER_GET,
    }),

    async getNewPage (page=1) {
      this.loading = true;
      await this.fetchAllUsers({
        query: { page },
        params: this.$route.params,
      });
      this.loading = false;
    },

    openDialog () {
      this.dialogVisible = true;
      this.id = parseInt(this.$route.params.id);
    },

    async deletePublisher (id) {
      await this.removePublishersUsers({
        params: {
          publishers_id: parseInt(this.$route.params.id),
          id,
        },
      });
      this.getNewPage();
    },

    mouseOver: changeOptionsBGMouseOver,

    mouseOut: changeOptionsBGMouseOut,

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    close () {
      this.dialogVisible = false;
      this.getNewPage();
    },
  },
};
</script>