<template>
  <div class="transactions">
    <el-form
      v-if="hasAccess"
      label-position="top"
      class="transactions-from"
      :inline="true"
      :v-loading="loading"
    >
      <el-form-item
        label="Operation Type"
        class="line"
      >
        <el-autocomplete
          v-model="form.operation"
          class="inline-input"
          placeholder="Choose an operation"
          :fetch-suggestions="querySearchOper"
          @select="(item) => handleSelect(item)"
        />
      </el-form-item>
      <el-form-item
        label="Date"
        class="line"
      >
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="Before"
          start-placeholder="Date start"
          end-placeholder="Date end"
          @change="handleTimeChange"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm"
        >
          Search
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="resetForm"
        >
          Reset
        </el-button>
      </el-form-item>
    </el-form>
    <el-button
      v-if="userRole === 'agency' && management !== 'view'"
      type="primary"
      @click="openTransferModal=true"
    >
      Transfer
    </el-button>
    <el-button
      v-if="(hasAccess && userRole !== 'agency')"
      type="primary"
      @click="openModal=true"
    >
      Create
    </el-button>
    <Table
      :column="column"
      :table-data="transactionsData"
      :loading="loading"
      :cellstyle="cellStyle"
      @newPage="getNewPage"
    />
    <TransactionModal
      v-if="openModal"
      :open-modal="openModal"
      @close="openModal=false"
      @updateBalance="fetchCurrentUser"
    />
    <TransferModal
      v-if="openTransferModal"
      :open-modal="openTransferModal"
      @close="openTransferModal=false"
      @updateBalance="fetchCurrentUser"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  GETTER_BILLING_TRANSACTIONS_DATA,
  ACTION_PUBLISHERS_GET,
  GETTER_BILLING_TRANSACTIONS_COLUMN,
  ACTION_BILLING_TRANSACTION_GET,
  GETTER_AUTH_USER_ROLE,
  GETTER_PUBLISHERS_DATA,
  GETTER_AUTH_USER_MANAGEMENT,
  GETTER_AUTH_CURRENT_USER,
  ACTION_AUTH_CURRENT_USER,
} from '@/const';

import TransactionModal from './TransactionModal.vue';
import TransferModal from './TransferModal.vue';
import Table from '../../components/Table.vue';
import moment from 'moment';

export default {
  components: {
    TransactionModal,
    TransferModal,
    Table,
  },

  data () {
    return {
      loading: false,
      openModal: false,
      openTransferModal: false,
      column: null,
      publisher: '',
      form: {
        operation: '',
        publisherId: null,
        date: null,
      },
      operationArr: [
        { value: 'debit' },
        { value: 'credit' },
      ],
      cellStyle: {
        columnProperty: 'operation',
        styles: {
          debit: 'color: green',
          credit: 'color: red',
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      transactionsData: GETTER_BILLING_TRANSACTIONS_DATA,
      transactionsColumn: GETTER_BILLING_TRANSACTIONS_COLUMN,
      getPublishersData: GETTER_PUBLISHERS_DATA,
      userRole: GETTER_AUTH_USER_ROLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
      management: GETTER_AUTH_USER_MANAGEMENT,
    }),

    hasAccess () {
      return this.userRole !== 'publisher';
    },

    tableTitle () {
      return () => {
        const primaryAccs = ['administrator', 'manager'];

        if (this.userRole === 'publisher') {
          this.column = this.transactionsColumn.filter(col => col.label !== 'Publisher');
        } else if (primaryAccs.includes(this.userRole)) {
          this.column = this.transactionsColumn;
        } else if (this.userRole === 'agency') {
          const allowedColumns = ['id', 'agency', 'advertiser', 'amount', 'description', 'created'];

          this.column = this.transactionsColumn.filter(col => allowedColumns.includes(col.prop));
        }
      };
    },

    publishers () {
      return this.getPublishersData.rows.map(item => {
        return {
          id: item.id,
          value: item.name,
        };
      });
    },
  },

  async created () {
    this.tableTitle();
    await this.getTransactions({
      query: { page: 1 },
    });
  },

  methods: {
    ...mapActions({
      getTransactions: ACTION_BILLING_TRANSACTION_GET,
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
      fetchCurrentUser: ACTION_AUTH_CURRENT_USER,
    }),

    querySearchPublisher (queryString, cb) {
      const publishers = this.publishers;
      const results = queryString ? publishers.filter(this.createFilter(queryString)) : publishers;
      cb(results);
    },

    handleTimeChange (item) {
      this.form.date  = item.map(time => moment(time).format('YYYY-MM-DD'));
    },

    querySearchOper (queryString, cb) {
      const operation = this.operationArr;
      const results = queryString ? operation.filter(this.createFilter(queryString)) : operation;
      cb(results);
    },

    async getNewPage (page=1) {
      await this.getTransactions({
        query: { page },
      });
    },

    async resetForm () {
      this.form.publisherId =
      this.publisher = this.form.operation =
      this.form.date = null;
    },

    handleSelect (item, type) {
      if (type === 'publisher') {
        this.form.publisherId = item.id;
      } else {
        this.form.operation = item.value;
      }
    },

    createFilter (queryString) {
      return (item) => {
        return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    async submitForm () {
      this.loading = true;
      let start;
      let end;
      if (this.form.date) {
        start = this.form.date[0];
        end = this.form.date[1];
      }

      const query = {
        'between[from]': start,
        'between[to]': end,
      };

      if (this.form.operation) {
        query.operation = this.form.operation;
      }

      if (this.publisher) {
        query.publisherId = this.form.publisherId;
      }

      const { status } = await this.getTransactions({
        query,
      });
      if (status === 200) {
        this.loading = false;
      }
      this.form.publisherId =
      this.publisher = this.form.operation =
      this.form.date = null;
    },
  },
};
</script>

<style lang="scss">
.transactions {
  width: 100%;
  position: relative;
  padding: 15px;
  padding-top: 50px;
  text-align: right;

  & .transactions-from {
    position: absolute;
    left: 15px;
    top: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;

    & .line {
      text-align: left;

      .el-form-item__label {
        line-height: 0 !important;
      }
    }

  }
}
</style>
