<template>
  <div class="campaign-report">
    <el-form
      :inline="true"
      :model="form"
      label-position="top"
      class="campaign-report-form"
    >
      <el-form-item label="Select report">
        <el-select
          v-model="form.report"
          placeholder="Select"
        >
          <el-option
            v-for="item in filter.reports"
            :key="item.name"
            :label="item.title"
            :value="item.name"
            @click.native="selectedItem(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Select date range">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="Before"
          start-placeholder="Start date"
          end-placeholder="End date"
        />
      </el-form-item>
      <el-form-item class="apply-btn">
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Apply
        </el-button>
      </el-form-item>
    </el-form>
    <ReportTable
      :report-data="creativeReport"
      @goToPage="getNewPage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_CREATIVE_GET_REPORTS,
  ACTION_CREATIVE_GET_REPORTS_LIST,
  GETTER_CREATIVE_ALL_REPORTS,
  GETTER_CREATIVE_REPORTS_LIST,
} from '@/const';
import ReportTable from '@/components/ReportTable';
import { getReportQuery } from '@/utils';
export default {
  components: {
    ReportTable,
  },
  data () {
    return {
      filter: {
        date: moment(),
        reports: [],
      },
      form: {
        date: [moment().subtract(1, 'month').format('YYYY, MM, DD'), moment().format('YYYY, MM, DD')],
        report: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      creativeReport: GETTER_CREATIVE_ALL_REPORTS,
      creativeReportsList: GETTER_CREATIVE_REPORTS_LIST,
    }),
  },

  async created () {
    await this.getCreativeReportsList({
      params: {
        campaignId: this.$route.params.id,
        id: this.$route.params.creative_id,
      },
    });

    this.filter.reports = this.creativeReportsList.rows;
    this.form.report = this.filter.reports[0].name;

    await this.allCreativeReports({
      params: {
        campaignId: this.$route.params.id,
        id: this.$route.params.creative_id,
        reportName: this.filter.reports[0].name,
      },
      query: getReportQuery(this),
    });
  },

  methods: {
    ...mapActions({
      allCreativeReports: ACTION_CREATIVE_GET_REPORTS,
      getCreativeReportsList: ACTION_CREATIVE_GET_REPORTS_LIST,
    }),
    async onSubmit () {
      await this.allCreativeReports({
        params: {
          campaignId: this.$route.params.id,
          id: this.$route.params.creative_id,
          reportName: this.form.report,
        },
        query: getReportQuery(this),
      });
    },

    async getNewPage (page) {
      await this.allCreativeReports({
        params: {
          campaignId: this.$route.params.id,
          id: this.$route.params.creative_id,
          reportName: this.form.report,
        },
        query: getReportQuery(this, page),
      });
    },

    selectedItem (item) {
      this.form.report = item.name;
    },
  },
};
</script>

<style lang="scss">
.campaign-report {
  width: 100%;

  &-form {
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    
    .apply-btn {
      margin-right: 0 !important;
    }
  }
}
</style>