import { 
  ACTION_ROOT_REQUEST, 
  URL_ADVERTISERS_GET_POST,
  URL_ADVERTISERS_DELETE_PUT,
  URL_ADVERTISERS_USERS_GET_POST,
  URL_ADVERTISERS_USERS_DELETE,
  MUTATION_ADVERTISERS_SET,
  MUTATION_ADVERTISERS_USERS_SET,
} from '@/const';

const state = {
  advertisersData: {},
  columns: [
    {
      prop: 'options',
      label: '',
      width: 40,
      buttons: true,
    },
    {
      prop: 'id',
      label: 'ID',
      width: 35,
    },
    {
      prop: 'name',
      label: 'Name',
      width: 100,
    },
    {
      prop: 'owner',
      label: 'Owner',
      width: 100,
    },
    {
      prop: 'agencyName',
      label: 'Agency',
      width: 100,
    },
    {
      prop: 'userCount',
      label: 'Users',
      width: 40,
    },
    {
      prop: 'balance',
      label: 'Balance',
      width: 100,
    },
  ],
  userColumns: [
    {
      prop: 'options',
      label: '',
      width: 40,
      buttons: true,
    },
    {
      prop: 'id',
      label: 'ID',
      width: 35,
    },
    {
      prop: 'email',
      label: 'Mail',
      width: 100,
    },
    {
      prop: 'name',
      label: 'Username',
      width: 100,
    },
  ],
  advertisersUsers: {
    rows: [],
    page: null,
    limitPerPage: null,
  },
};

const getters = {
  getAdvertisersData (state) {
    if (state.advertisersData && state.advertisersData.rows && state.advertisersData.rows.length) {
      state.advertisersData.rows = state.advertisersData.rows.map(item => {
        return {
          ...item,
          owner: 'owner' in item ? `${item.owner.name} | ${item.owner.email}` : '-',
          ownerId: 'owner' in item ?  item.owner.id : '-',
          agencyName: 'agency' in item ? item.agency.name : '-',
        };
      });
    }
    return state.advertisersData;
  },

  getAdvertisersUser (state) {
    return state.advertisersUsers;
  },

  getAdvertisersColumns (state) {
    return state.columns;
  },

  getAdvertisersUsersColumns (state) {
    return state.userColumns;
  },
};

const mutations = {
  setAdvertisers (state, data) {
    state.advertisersData = data;
  },
  
  setAdvertisersUser (state, data) {
    state.advertisersUsers.rows = Object.values(data.rows);
  },
};

const actions = {
  fetchAllAdvertisers: async ({ dispatch }, { query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_ADVERTISERS_GET_POST,
      commitName: MUTATION_ADVERTISERS_SET,
    }, { root: true });
    return response.data;
  },

  fetchAdvertiser: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_ADVERTISERS_DELETE_PUT,
    }, { root: true });
    return response;
  },

  fetchAllUsers: async ({ dispatch }, { params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_ADVERTISERS_USERS_GET_POST,
      commitName: MUTATION_ADVERTISERS_USERS_SET,
    }, { root: true });
  },

  removeAdvertiser: async ({ dispatch }, { params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_ADVERTISERS_DELETE_PUT,
    }, { root: true });
    return { status: 200 };
  },

  updateAdvertiser: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_ADVERTISERS_DELETE_PUT,
    }, { root: true });
    return res;
  },

  createAdvertiser: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_ADVERTISERS_GET_POST,
    }, { root: true });
    return res;
  },
  
  addNewAdvertisersUsers: async ({ dispatch }, { data, params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_ADVERTISERS_USERS_GET_POST,
    }, { root: true });
    return response;
  },

  removeAdvertisersUsers: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_ADVERTISERS_USERS_DELETE,
    }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};