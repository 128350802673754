<template>
  <el-dialog
    :title=" formData ? 'Update an application list' : 'Create an application list'"
    :visible="openModal"
    class="appListDialog"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="Loading"
      :rules="rules"
      :model="form"
      label-position="top"
      style="position: inherit!important;"
    >
      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="Apps list"
        prop="bundles"
      >
        <el-input
          class="textArea"
          v-model="form.bundles"
          type="textarea"
          :rows="20"
          placeholder="Enter bundles (one per line)"
        />
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData"
          type="primary"
          @click="submitUpdateApps"
        >
          Save
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="submitCreateApps"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_APPS_LIST_CREATE,
  ACTION_APPS_LIST_UPDATE,
  ACTION_APPS_LIST_GET,
  ACTION_ONE_APPS_LIST_GET,
  GETTER_APP_LIST_GET_ONE,
} from '@/const';

export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      Loading: false,
      appsListId: null,
      form: {
        name: '',
        bundles: null,
      },
      rules: {
        name: [
          { required: true, message: 'Fill in the field' },
        ],
        bundles: [
          { required: true, message: 'Fill in the field' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      getOneAppList: GETTER_APP_LIST_GET_ONE,
    }),
  },

  async created () {
    this.Loading = true;

    if (this.formData) {
      this.appsListId = this.formData.pool_id;
      await this.fetchOneAppList({
        params: {
          id: this.formData.pool_id,
        },
      });

      const form = this.getOneAppList;

      delete form.updated;
      delete form.created;
      delete form.pool_id;
      this.form = {
        ...form,
        bundles: form.bundles.join('\n'),
      };
    }
    this.Loading = false;
  },

  methods: {
    ...mapActions({
      updateAppList: ACTION_APPS_LIST_UPDATE,
      createAppsList: ACTION_APPS_LIST_CREATE,
      getAppsList: ACTION_APPS_LIST_GET,
      fetchOneAppList: ACTION_ONE_APPS_LIST_GET,
    }),

    openMessage (error = 'Something went wrong') {
      this.$messageBox.alert(
        error, 'Error', {
          confirmButtonText: 'Ok',
        });
    },

    async submitUpdateApps () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.Loading = true;
          const val = this.form.bundles.split('\n').filter((item) => {
            return item !== '';
          });
          const data = {
            ...this.form,
            bundles: val,
          };
          delete data.updated;
          delete data.created;

          const res = await this.updateAppList({
            params: { id: this.appsListId },
            data,
          });

          if (res && res.data && res.data.errors) {
            this.openMessage(res.data.errors[0].msg);
          }

          if (res.status === 200) {
            await this.getAppsList();
            this.$emit('close');
          }

          this.Loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });

    },

    async submitCreateApps () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.Loading = true;
          const val = this.form.bundles.split('\n').filter((item) => {
            return item !== '';
          });

          const data = {
            ...this.form,
            bundles: val,
          };

          const res = await this.createAppsList({
            data,
          });

          if (res && res.data && res.data.errors) {
            this.openMessage(res.data.errors[0].msg);
          }

          if (res.status === 200) {
            await this.getAppsList();
            this.$emit('close');
          }
          this.Loading = false;
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });
    },
  },
};

</script>

<style lang="scss">
.appListDialog {
  .el-form {
    height: 100% !important;
    overflow: hidden;
    padding-right: 15px;

    .el-input {
      max-width: 100% !important;
    }

    ::-webkit-scrollbar {
      width: 5px;
      border-radius: 6px;
      background-color: #E7EEF4;;
    }

    ::-webkit-scrollbar-thumb {
      background: #409EFF;
      border-radius: 6px;
    }
  }

  .el-dialog {
    max-width: 700px !important;
    width: 80% !important;

    .el-form {
      max-height: 800px;
    }
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
