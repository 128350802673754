<template>
  <el-dialog
    :title=" formData ? 'Updating SSP' : 'Creating SSP'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      style="position: inherit!important;"
    >
      <el-form-item
        label="Name of SSP"
        prop="name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="Endpoint"
        prop="endpoint"
      >
        <el-input
          v-model="form.endpoint"
        />
      </el-form-item>
      <el-form-item
        label="Token"
      >
        <el-input
          v-model="form.token"
        />
      </el-form-item>
      <el-form-item
        label="Maximum price for SSP"
        prop="limitPrice"
      >
        <el-input
          v-model="form.limitPrice"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="Banner return method"
      >
        <el-select v-model="form.admPlace">
          <el-option
            v-for="item in admPlaceOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Handler"
        prop="handler"
      >
        <el-select v-model="form.handler">
          <el-option
            v-for="item in sspHandlers"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="useInSelfService"
      >
        <el-checkbox
          v-model="form.useInSelfService"
          label="Use in self service"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.callNurl"
          label="Nurl"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.callBurl"
          label="Burl"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="form.callLoss"
          label="Loss"
        />
      </el-form-item>
      <el-form-item
        label="Imp Action"
      >
        <el-select v-model="form.impAction">
          <el-option
            v-for="item in impActionOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="DSP"
        style="margin: 0"
      />
      <el-form-item v-if="allDSP">
        <el-checkbox-group
          v-model="selectedDSP"
        >
          <el-checkbox
            v-for="item of allDSP"
            :key="item.id"
            class="checkbox-dsp"
            :label="item"
          >
            {{ item.name }}
            <el-input
              v-model="item.commission"
              type="number"
              placeholder="DSP - Min. price"
            />
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData"
          type="primary"
          @click="updateSsp"
        >
          Save
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="createSsp"
        >
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_SSP_UPDATE,
  ACTION_SSP_CREATE,
  GETTER_SSP_HANDLERS,
  ACTION_SSP_HANDLERS_GET,
  ACTION_SSPONE_FETCH,
  GETTER_SSP_ONE,
  ACTION_DSP_GET,
  GETTER_DSP_DATA,
} from '../../const';
export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      admPlaceOptions: [
        { value: 'adm' },
        { value: 'nurl' },
      ],
      sspHandlerOptions: [],
      impActionOptions: [
        { value: 'pixel' },
        { value: 'burl' },
        { value: 'start' },
      ],
      loading: false,
      form: {
        name: '',
        admPlace: null,
        impAction: null,
        handler: null,
        token: null,
        limitPrice: null,
        endpoint: '',
        callNurl: true,
        callBurl: false,
        callLoss: false,
        useInSelfService: false,
        dsps: null,
      },
      allDSP: null,
      selectedDSP: [],
      rules: {
        name: { required: true, message: 'Fill in the field' },
        endpoint: { required: true, message: 'Fill in the field' },
        limitPrice: { required: true, message: 'Fill in the field' },
        handler: { required: true, message: 'Fill in the field' },
      },
    };
  },

  computed: {
    ...mapGetters({
      sspHandlers: GETTER_SSP_HANDLERS, 
      getOneSsp: GETTER_SSP_ONE,
      getDspData: GETTER_DSP_DATA,
    }),
  },

  async created () {
    this.loading = true;
    await this.fetchDspData({ query: { limit: 0 } });
    this.allDSP = this.getDspData.rows;

    const dsp = this.form.dsps || [];
    if (dsp) {
      this.form.dsps = dsp.map(item => {
        return {
          id: item.id,
          commission: item.commission,
        };
      });

      const dspIdArray = dsp.map(item => item.id);
      const unselected = this.allDSP.filter(item => !dspIdArray.includes(item.id));
      this.selectedDSP = dsp;
      this.allDSP = [...dsp, ...unselected];
    }
    this.loading = false;
  },

  async mounted () {
    this.loading = true;
    await this.getSspHandlers();
    this.sspHandlerOptions = this.sspHandlers;
    if (this.formData) {
      await this.fetchSsp({
        params: { id: this.formData.id },
      });
      const updatedDsps = this.getOneSsp.dsps || [];
      this.allDSP = this.allDSP.map(dsp => {
        const updated = updatedDsps.find(upd => upd.id === dsp.id);
        return { ...dsp, commission: updated ? updated.commission : '' };
      });

      this.selectedDSP = this.allDSP.filter(dsp => {
        return updatedDsps.some(updated => updated.id === dsp.id);
      });
      this.form = { ...this.getOneSsp };
      this.form.callNurl = !!this.formData.callNurl;
      this.form.callBurl = !!this.formData.callBurl;
      this.form.callLoss = !!this.formData.callLoss;
      this.form.useInSelfService = !!this.formData.useInSelfService;
      delete this.form.updated;
      delete this.form.created;

    } else {
      this.form.admPlace = this.admPlaceOptions[0].value;
      this.form.handler = this.sspHandlerOptions[0].value;
      this.form.impAction = this.impActionOptions[0].value;
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      create: ACTION_SSP_CREATE,
      update: ACTION_SSP_UPDATE,
      getSspHandlers: ACTION_SSP_HANDLERS_GET,
      fetchSsp: ACTION_SSPONE_FETCH,
      fetchDspData: ACTION_DSP_GET,
    }),

    async createSsp () {
      const data = this.form;
      data.limitPrice = Number(data.limitPrice);
      data.callNurl = Number(data.callNurl);
      data.callBurl = Number(data.callBurl);
      data.callLoss = Number(data.callLoss);
      data.useInSelfService = Number(data.useInSelfService);
      data.dsps = this.selectedDSP.map(({ id, commission }) => ({ id, commission: Number(commission) }));
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          await this.create({ data });
          this.$emit('close');
          this.$emit('newPage');
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });

    },

    // querySearchByDspId (id, cb) {
    //   const results = id ? this.allDsps.filter(dsp => dsp.id) : this.allDsps;
    //   cb(results);
    // },

    async updateSsp () {
      this.$refs.form.validate( async (valid) => {
        if (valid) {
          const data = {
            ...this.form,
            limitPrice: Number(this.form.limitPrice),
            callNurl: Number(this.form.callNurl),
            callBurl: Number(this.form.callBurl),
            callLoss: Number(this.form.callLoss),
            useInSelfService: Number(this.form.useInSelfService),
            dsps: this.selectedDSP.map(({ id, commission }) => ({ id, commission: Number(commission) })),
          };
          await this.update({
            params: { id: this.formData.id },
            data,
          });
          this.$emit('close');
          this.$emit('newPage');
        } else {
          this.$messageBox.alert(
            'Fill in all required fields'
          );
        }
      });

    },
  },

};
</script>
